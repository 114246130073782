import React from 'react';
import { useEffect, useState } from 'react';
import Series from '../components/Series';
import { useDispatch, useSelector } from 'react-redux';
import { getAllSeries } from '../actions/SeriesAction';



const SeriesListScreen = () => {

    const getAllSeriesState = useSelector(state => state.getAllSeriesReducer);

    const { loading, series, error } = getAllSeriesState;

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getAllSeries());
    }, [])




    return (
        <div className="container-fluid">
            <div className="row">
                {loading ? (
                    <div className="spinner-border text-primary m-3" role="status">
                        
                    </div>
                ) : error ? (
                    <h1>好似有啲問題...</h1>
                ) : (
                    series.map(s => {
                        return <Series series={s} key={s._id}/>
                    })

                )}
            </div>
        </div>
    );
}

export default SeriesListScreen;
