import React from 'react';
import axios from 'axios';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { KeyboardCapslockSharp, Receipt } from '@mui/icons-material';
import { height } from '@mui/system';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import EditUserDialog from '../components/EditUserDialog';
import Breadcrumb from '../components/Breadcrumb';

const UserListScreen = () => {

    const user = JSON.parse(localStorage.getItem("user"));

    if (!user || (user.role.indexOf('admin'))) {
        window.location.href = "/login";
    }

    const [username, setUsername] = useState("");

    const [userRole, setUserRole] = useState("all");

    const [phone, setPhone] = useState("");

    const [searchBy, setSearchBy]=useState("username");

    const [isActive, setIsActive] = useState("all");

    const [clicked, setClicked] = useState(true);

    const [users, setUsers] = useState([]);

    const [open, setOpen] = React.useState(false);

    const [openDetail, setOpenDetail] = React.useState(false);

    const [selectedPreorder, setSelectedPreorder] = React.useState([]);

    const [userDetail, setUserDetail] = React.useState({});

    const [tableBody, setTableBody] = React.useState();

    const [orderCount, setOrderCount] = React.useState(0);

    const [orderBy, setOrderBy] = React.useState(0);

    

    // let selectedPreorder=[];

    useEffect(() => {
        renderTableBody(users)
    }, [users]);


    const onSearch = () => {
        // match.params.phone=null;
        setClicked(false)
        request(setKey())

    }

    const setKey = () => {
        var key = ""

        if (searchBy=="username" && username != "") {
            key += "username=" + username;
        }else if(searchBy=="phone" && phone != ""){
            key += "phone=" + phone;
        }

        if (userRole != "all") {
            if (key != "") {
                key += "&"
            }
            key += "role=" + userRole;
        }

        // console.log(key)
        return key;
    }

    const selected = (e) => {
        let array = selectedPreorder
        if (e.target.checked) {
            array.push(e.target.value)
        } else {
            var i = array.indexOf(e.target.value);
            array.splice(i, 1);
        }
        setSelectedPreorder(array);
        console.log(selectedPreorder);

    }

    const request = (key) => {
        axios.get('/api/user/getUser?' + key, {
            headers: {
                'Authorization': `Basic ${user.token}`
            }
        }).then(res => {
            // setLoading(false);
            console.log(res);
            if (res.data.users != null) {
                setUsers(res.data.users);
            }

        }).catch(err => {
            console.error(err);
            // setLoading(false);
            alert("好似有啲問題...");
        });
    }



    const formatDate = (date) => {
        let formattedDate = new Date(date)
        return formattedDate.toLocaleString()
    }



    const sortUsers = (value) => {
        if (value == orderBy) {
            setOrderCount(orderCount + 1);
        } else {
            setOrderCount(1);
        }
        let sorted = users.sort(function (a, b) {
            if (orderCount % 2 == 1) {
                return a[value] < b[value] ? 1 : -1;
            } else if (orderCount % 2 == 0) {
                return a[value] > b[value] ? 1 : -1;
            }

        });
        console.log(sorted)
        setUsers(sorted);
        setOrderBy(value)
        renderTableBody(users);
    }

    const renderTableBody = (pos) => {
        setTableBody(<tbody>
            {pos.map(user => {
                
                if(user.type=="bulkCard"){
                    return null;
                }
                index++;
                return <tr>
                    <th><input type="checkbox" id="_id" value={user._id} onChange={e => selected(e)} /></th>
                    <th scope="row">{index}</th>
                    <td>{user.username}</td>
                    <td>{user.name}</td>
                    <td>{user.role.map(r=>{
                        return r+" "
                    })}</td>
                    <td>{user.phone}</td>
                    <td>{user.email}</td>
                    <td>{user.isActive ? <div>Active</div> : <div>Inactive</div>}</td>
                    <td>{user.role.indexOf("admin")>=0? <button className="btn btn-danger" disabled style={{ width: "100%" }} value={user._id} onClick={e => handleClickOpenDetail("edit", user)} >Edit</button> : <button className="btn btn-danger" style={{ width: "100%" }} value={user._id} onClick={e => handleClickOpenDetail("edit", user)} >Edit</button>} </td>
                </tr>
            })}
        </tbody>)
    }



    const resetSearch = () => {
        window.location.href = "/admin/manageUser";
    }


    const handleClickOpenDetail = (mode, user) => {
        setOpenDetail(true);
        let req = {};
        if (mode == "edit") {
            req = { mode, user }
        }
        setUserDetail(req)
    };

    const handleCloseDetail = () => {
        setOpenDetail(false);
        request(setKey())
    };

    let editUserDialog = <div>
        <Dialog
            open={openDetail}
            onClose={handleCloseDetail}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth
            maxWidth={"md"}
        >
            <DialogTitle id="alert-dialog-title">
            </DialogTitle>
            <DialogContent>
                {console.log(userDetail)}
                <EditUserDialog req={userDetail} />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCloseDetail}>關閉</Button>
            </DialogActions>
        </Dialog>
    </div>

    var index = 0;

    return (
        <>
        <Breadcrumb />
        <div className="container-fluid">
            {editUserDialog}
            <h2>用戶管理</h2>
            <div className="card p-2 m-2">
                <div className="row">
                    <div className="col-md-3">
                        <div className="input-group" >
                        <div className="input-group-prepend" style={{marginTop: "0px"}}>
                                {/* <span className="input-group-text" id="basic-addon1">@</span> */}
                                <select className="form-select" style={{marginTop: "10px", height:"38px"}} aria-label="productType" value={searchBy} onChange={e => setSearchBy(e.target.value)}>
                                    <option value="name"> Username </option>
                                    <option value="phone"> 電話 </option>
                                </select>

                            </div>
                            {searchBy=="phone" ? 
                                <input className="form-control" style={{margin: "0px", height:"38px"}}  type="number" pattern="[0-9]*" inputmode="numeric" value={phone} placeholder="電話號碼" aria-label="Search" onChange={e=>setPhone(e.target.value)} onKeyPress={event => {
                                    if (event.key === 'Enter') {
                                      onSearch()
                                    }
                                  }}/>
                                : searchBy == "username" ?
                                <input className="form-control" style={{margin: "0px", height:"38px"}} type="text"  value={username} placeholder="名" aria-label="Search" onChange={e=>setUsername(e.target.value)} onKeyPress={event => {
                                    if (event.key === 'Enter') {
                                      onSearch()
                                    }
                                }} />
                                : null
                                // <input className="form-control" style={{margin: "0px", height:"38px"}} type="text"  value={include} placeholder="內容包含" aria-label="Search" onChange={e=>setInclude(e.target.value)} onKeyPress={event => {
                                //     if (event.key === 'Enter') {
                                //       onSearch()
                                //     }
                                // }} />
                            }
                        </div>
                    </div>
                    <div className="col-md-3">

                        <div className="p-2">
                            <select className="form-select" aria-label="User Role" value={userRole} onChange={e => setUserRole(e.target.value)}>
                                <option value="all"> 商品類型: 全部 </option>
                                <option value="manager">manager</option>
                                <option value="shopkeeper">shopkeeper</option>
                                <option value="seller">seller</option>
                                <option value="supplier">supplier</option>
                            </select>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="p-2">
                            <button className="btn btn-warning" style={{ width: "100%" }} onClick={e => resetSearch()}>重設</button>
                            {/* {
                                    productType==='all' && orderStatus==='all' && (phone === '' || name === '')? 
                                        <button className="btn btn-warning" style={{marginTop: "7px", width:"100%"}}  disabled>重設</button> 
                                    :
                                    <button className="btn btn-warning" style={{marginTop: "7px", width:"100%"}}  onClick={e=>resetSearch()}>重設</button> 
                                } */}
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="p-2">
                            <button className="btn btn-primary" style={{ width: "100%" }} onClick={e => onSearch()} >搜尋</button>
                        </div>
                    </div>

                </div>
                {/* <div className="row">
                    
                </div> */}
            </div>
            <div className="card p-2 m-2" >
                {users.length > 0 ?
                    <div style={{ display: "flex", overflow: "auto" }}>
                        <table className="table table-hover" >
                            <thead >
                                <tr>
                                    <th></th>
                                    <th scope="col">#</th>
                                    <th scope="col" onClick={() => sortUsers("name", "desc")}>Username</th>
                                    <th scope="col" >名</th>
                                    <th scope="col" >Role</th>
                                    <th scope="col" >電話</th>
                                    <th scope="col" >電郵</th>
                                    <th scope="col" >Active</th>
                                    <th scope="col"></th>
                                </tr>
                            </thead>
                            {tableBody}
                        </table>
                    </div>



                    : clicked ? "搜尋先" : "冇記錄"


                }
            </div>


        </div>
        </>
    )
}

export default UserListScreen;