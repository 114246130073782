import React from 'react';
import axios from 'axios';
import { useState, useEffect } from 'react';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';



var style = {
    backgroundColor: "#F8F8F8",
    borderTop: "1px solid #E7E7E7",
    padding: "20px",
    position: "fixed",
    left: "0",
    bottom: "0",
    height: "55px",
    width: "100%",
    textAlign: "right"
}

var phantom = {
    display: 'block',
    padding: '20px',
    height: '55px',
    width: '100%',
}

function Footer({ children }) {

    const [loading, setLoading] = useState(true);

    const [error, setError] = useState("");

    const [socialMedia, setSocialMedia] = useState({
        facebook: "https://www.facebook.com/playcardchill",
        instagram: "https://instagram.com/playcardchill?r=nametag",
        whatsappGroup: "https://playcardchill.com/api/socialMedia/whatsappPKM"
    });

    useEffect(() => {
        axios.get('/api/config/getSocialMedia').then(res => {
            setLoading(false);
            if(res.data.socialMedia){
                setSocialMedia(res.data.socialMedia);
            }
        }).catch(err => {
            console.error(err);
            setLoading(false);
            setError("好似有啲問題...");
        });
    }, [])
    return (
        <div>
            <div style={phantom} />
            <div style={style}>

                <div className="container-fluid">
                    <div className="row" >
                        <div className="col" style={{ fontSize: "10px", textAlign: "left", marginTop: "-2px" }}>
                            <a href={socialMedia.whatsappGroup} target="_blank"><WhatsAppIcon /></a>
                            <span>&nbsp;&nbsp;</span>
                            <a href={socialMedia.instagram} target="_blank"><InstagramIcon /></a>
                            <span>&nbsp;&nbsp;</span>
                            <a href={socialMedia.facebook} target="_blank"><FacebookIcon /></a>
                        </div>
                        <div className="col">
                            <div style={{ fontFamily: "pokemonFont", fontSize: "15px", textAlign: "center", marginTop: "-3px" }}>Play Card Chill</div>
                        </div>
                        <div className="col">
                            <div style={{ fontSize: "10px", textAlign: "right", marginTop: "-1px" }}>大埔大日子商場3樓317-318號鋪</div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default Footer