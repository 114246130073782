import React, { useState } from 'react';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';


 const RefundDialog = ({ callback }) => {

    const [reason, setReason] = useState("");
    const [type, setType] = useState("退貨");
    const [method, setMethod] = useState("Cash");
    const [price, setPrice] = useState("退貨");
    // let { orderType } = match.params;

    let testReturn = (product) => {
        callback.handleCallback(product);
    }

    let onUpload = () => {
        const item={id: callback.id, type: type, method: method, price: price, reason: reason}
        testReturn(item)
    }

    return (
        <div>
            確定要退？
            <br />
            退左就冇得返轉頭架喇
            <br />
            <div className="p-2">
                <label for="paymentMethod" class="form-label">型式</label>
                <select class="form-select" aria-label="paymentMethod" value={type} onChange={e => setType(e.target.value)}>
                    <option value="退貨"> 退貨+退款 </option>
                    <option value="退款"> 退款 </option>
                </select>
            </div>
            <div className="p-2">
                <label for="paymentMethod" class="form-label">退款方式</label>
                <select class="form-select" aria-label="paymentMethod" value={method} onChange={e => setMethod(e.target.value)}>
                    <option value="cash"> Cash </option>
                    <option value="payme"> Payme </option>
                    <option value="fps"> FPS </option>
                    <option value="octopus"> Octopus </option>
                    <option value="yedpay"> Yedpay </option>
                </select>
            </div>
            <div className="p-2">
                <label for="price" class="form-label">金額</label>
                <input type="number" min="0.1" step="0.1" pattern="[0-9]*" inputmode="numeric" class="form-control" value={price} placeholder="金額" onChange={e => setPrice(e.target.value)} />
            </div>
            <TextField 
                fullWidth 
                required 
                id="standard-required" 
                label="原因" 
                variant="standard" 
                onChange={e=>setReason(e.target.value)}
            />
            <Button onClick={e=>onUpload()} color="error">{type}</Button>
            <Button onClick={callback.handleCloseDetail} color="primary">Cancel</Button>
        </div>
        
    );
}

export default RefundDialog;
