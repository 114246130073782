import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { login } from "../actions/UserAction"
import Breadcrumb from '../components/Breadcrumb';

const LoginScreen = () => {

    const loginState = useSelector(state => state.loginReducer);

    const { loading, success, error } = loginState;

    const dispatch = useDispatch();

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");

    function preformLogin(e) {
        e.preventDefault();
        const user = {
            username: username,
            password: password
        }

        dispatch(login(user));

    }

    useEffect(() => {
        
        if (localStorage.getItem("user") != null) {
            alert("登入咗喇");
            window.location.href = "/admin";
        }
    }, []);

    return (
        <>
        <Breadcrumb />
        <div>
            <div className="row justify-content-center " style={{ marginTop: "150px" }}>
                <div className="col-md-5 card p-5">
                    <div className="div">
                        <h2 className="text-center">Login</h2>
                        <form onSubmit={preformLogin}>
                            <input type="text" placeholder="Username" className="form-control" value={username} onChange={(e) => { setUsername(e.target.value) }} required />
                            <input type="password" placeholder="Password" className="form-control" value={password} onChange={(e) => { setPassword(e.target.value) }} required />
                            <button className="btn btn-dark mt-3" type="submit" >Login</button>
                        </form>
                    </div>
                </div>


            </div>

        </div>
        </>
    )
}

export default LoginScreen;