import React from 'react';

const Homescreen = () => {
    return (
        <div className="container-fluid p-4">
            <div className="row justify-content-md-center" >
                <img src={require("../img/banner.png")} alt={"banner"} style={{height:"auto", maxWidth:"800px", alignContent:"center"}}></img>
            </div>
            <div className="row" style={{minHeight:"300px", height:"450px"}}>
                <div className="col-md">
                    <iframe
                        src="https://calendar.google.com/calendar/embed?src=uqk9l9a48rre7k1qulrkkospf8%40group.calendar.google.com&ctz=Asia%2FHong_Kong"
                        style={{ border: "0" }}
                        width="90%"
                        height="90%"
                        loading="lazy"
                        allowfullscreen
                        scrolling="no">

                    </iframe>
                </div>
                <div className="col-md">
                    <label for="map"><h3>地址︰大埔大日子商場3樓317-318號鋪</h3></label>
                    <iframe
                        id="map"
                        width="90%"
                        height="90%"
                        style={{ border: "0" }}
                        loading="lazy"
                        allowfullscreen
                        src="https://www.google.com/maps/embed/v1/place?key=AIzaSyBPhYpn8u-o3DQXV7yV_XIbhzo0UrhAVqg&q=大日子商場,Seattle+WA">
                    </iframe>
                </div>
            </div>

        </div>
    );
}

export default Homescreen;
