import axios from "axios";


export const registerUser = (user, headers) => dispatch => {

    dispatch({ type: "USER_REGISTER_REQUEST" });
    console.log("USER_REGISTER_REQUEST")
    axios.post('/api/user/registerUser', user, headers).then(res => {
        console.log(res);
        dispatch({ type: "MANAGER_REGISTER_REQUEST_SUCCESS", payload: res.data });
    }).catch(err => {
        console.error(err);
        dispatch({ type: "MANAGER_REGISTER_REQUEST_FAIL", payload: err });
    });
}

export const login = (user) => dispatch => {

    dispatch({ type: "LOGIN_REQUEST" });
    console.log("LOGIN_REQUEST")
    axios.post('/api/user/login', user).then(res => {
        dispatch({ type: "LOGIN_REQUEST_SUCCESS"});
        user = res.data.user;
        user.token=res.data.token;
        let date = new Date();
        date.setHours(date.getHours()+(res.data.expiresIn/3600));
        user.expiresIn=date;
        
        localStorage.setItem("user", JSON.stringify(user));
        window.location.href="/admin";
        
    }).catch(err => {
        console.log(err.response);
        dispatch({ type: "LOGIN_REQUEST_FAIL", payload: err });
        alert("Wrong username or password")
    });
}