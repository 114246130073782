import React, { useState, useEffect } from 'react'
import axios from 'axios';
import '../css/input.css';

const EditUserDialog = ({ req }) => {

    const user = JSON.parse(localStorage.getItem("user"));

    if (!user || user.role.indexOf('admin') == -1) {
        window.location.href = "/login";
    }
    const [userId, setUserId] = useState("");
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPwd, setConfirmPwd] = useState("");
    const [role, setRole] = useState([""]);
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [name, setName] = useState("");
    const [commission, setCommission] = useState();
    const [commissionText, setCommissionText] = useState("抽佣");
    // const [remark, setRemark] = useState({});
    const [isActive, setIsActive] = useState();
    const [uploadStatus, setUploadStatus] = React.useState(0);


    useEffect(() => {

        if (req != null) {
            if (req.mode != null && req.mode == "edit") {
                if (req.user != null) {
                    let user = req.user
                    setUserId(user._id)
                    setUsername(user.username)
                    setName(user.name)
                    setRole(user.role)
                    setPhone(user.phone)
                    setEmail(user.email)
                    setIsActive(user.isActive)

                    if (user.role.indexOf("seller") >= 0) {
                        setCommission(user.remark.commission)
                    }

                }
            }
        }
    }, []);


    let addRoles = () => {
        setRole([...role, ""]);
    }

    let handleChange = (i, e) => {
        if (role[i] == "seller") {
            setCommission("%")
        }
        let newRole = [...role];
        newRole[i] = e.target.value;
        setRole(newRole);
    }

    let removeRoles = (i) => {
        if (role[i] == "seller") {
            setCommission("%")
        }
        let newRole = [...role];
        newRole.splice(i, 1);
        setRole(newRole)
    }

    var showRolesSelect = role.map((element, index) => {
        return <div className="row">
            <div className="col-md-6">
                <select class="form-select" aria-label="specialType" value={role[index]} onChange={e => handleChange(index, e)} key={index}>
                    <option disabled value=""> -- select an option -- </option>
                    <option value="manager">Manager</option>
                    <option value="seller">賣家(寄賣簿)</option>
                    <option value="supplier">Supplier</option>
                </select>
            </div>
            <div className="col-md-6">
                <button className="btn btn-outline-danger" onClick={() => removeRoles(index)}>刪除</button>
            </div>
        </div>
    })

    const handleChangeCommission = (value) => {
        setCommission(value)
        setCommissionText("抽佣" + value + "%")
    }

    const handleEdit = () => {
        let updateUser = {}
        let canUpload = true

        if (name == "") {
            canUpload = false
            alert("Please Enter Name")
        }
        if (username == "") {
            canUpload = false
            alert("Please Enter Username")
        }
        if (phone == "") {
            canUpload = false
            alert("Please Enter Phone")
        }
        if (role.length <= 0) {
            canUpload = false
            alert("Please Enter role")
        }
        if (role.indexOf("seller") >= 0 && commission == null) {
            canUpload = false
            alert("Please Enter commission")
        }
        if (canUpload) {
            setUploadStatus(1)
            if (password == confirmPwd && password != "") {
                updateUser.password = password
            }
            updateUser._id = userId
            updateUser.username = username
            updateUser.name = name
            updateUser.phone = phone
            updateUser.email = email
            updateUser.role = role
            updateUser.isActive = isActive
            if (updateUser.role.indexOf("seller") >= 0) {
                updateUser.remark={commission:parseInt(commission)}
            }
            // console.log(updateUser)
            axios.post("/api/user/editUser", { user: updateUser }, {
                headers: {
                    'Authorization': `Basic ${user.token}`
                }
            }).then(res => {
                console.log(res.status)
                if (res.data.message === "success") {
                    alert("Done");
                    setUploadStatus(2)
                } else {
                    console.log("fail")
                    alert("fail " + res.data.error);
                    setUploadStatus(0)
                }
            }).catch(err => {
                if (err.response.data.error == "not enough stock") {
                    alert(err.response.data.error)
                    setUploadStatus(0)
                } else {
                    console.log(err.response.data.error)
                    setUploadStatus(0)
                }
            });
        }

    }

    const handleToggle = (e) => {
        if (e.target.checked) {
            setIsActive(true)
        } else {
            setIsActive(false)
        }
    }

    return (
        <div>
            <div className="row justify-content-center ">
                <div className="col-md-5 card p-2" >
                    <div className="div">
                        <h2 className="text-center">Edit User</h2>
                        <div className='p-3'>
                            <input type="text" placeholder="username" className="form-control" value={username} onChange={(e) => { setUsername(e.target.value) }} disabled />
                            <input type="text" placeholder="name" className="form-control" value={name} onChange={(e) => { setName(e.target.value) }} required />
                            <input type="password" placeholder="Password" className="form-control" value={password} onChange={(e) => { setPassword(e.target.value) }} />
                            <input type="password" placeholder="Confirm Password" className="form-control" value={confirmPwd} onChange={(e) => { setConfirmPwd(e.target.value) }} />
                            <input type="number" pattern="^([9]|[6]|[5])[0-9][0-9][0-9][0-9][0-9][0-9]" max="99999999" placeholder="Phone" className="form-control" value={phone} onChange={(e) => { setPhone(e.target.value) }} required />
                            <input type="email" pattern=".+@.+\..+" placeholder="Email" className="form-control" value={email} onChange={(e) => { setEmail(e.target.value) }} required />
                            {/* <input type="text"  placeholder="Role" className="form-control" value={email} onChange={(e) => { setEmail(e.target.value) }} required /> */}
                            <div className='card p-3'>
                                <div className="row">
                                    <div className="col-md-6">
                                        <label for="specialType" class="form-label">Roles</label>
                                    </div>
                                    <div className="col-md-6">
                                        <a className="btn btn-outline-success" onClick={() => addRoles()}>增加</a>
                                    </div>
                                </div>
                                {showRolesSelect}
                            </div>
                            {role.indexOf("seller") >= 0 ?
                                <div className='card p-3'>
                                    <label for="commission" class="form-label">{commissionText}</label>
                                    <input type="text" placeholder="抽佣" className="form-control" min="0" value={commission} onChange={(e) => { handleChangeCommission(e.target.value) }} required />
                                </div>
                                : null}

                            <div className='card p-3'>
                                <label for="isActive" class="form-label">Status: {isActive ? <>Active</> : <>Inactive</>}</label>
                                <br />
                                <label class="switch">
                                    <input type="checkbox" defaultChecked={isActive} onChange={e => handleToggle(e)} />
                                    <span class="slider round"></span>
                                </label>
                            </div>
                            <br />
                            {uploadStatus == 0 ?
                                <button className="btn btn-dark mt-3" onClick={e => handleEdit()}>Edit</button>
                            : uploadStatus == 1 ?
                                <button className="btn btn-secondary mt-3" onClick={e => handleEdit()} disabled>Editing...</button>
                            : uploadStatus == 2 ?
                                <button className="btn btn-success mt-3" onClick={e => handleEdit()} disabled>Edit Success</button>
                            : null}


                        </div>
                    </div>
                </div>


            </div>

        </div>
    )
}

export default EditUserDialog;