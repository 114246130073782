export const getAllCardsBySeriesReducer = (state = { cards: [] }, action) => {

    switch (action.type) {
        case "GET_ALL_CARDS_BY_SERIES_REQUEST": return {
            ...state,
            loading: true
        };
        case "GET_ALL_CARDS_BY_SERIES_REQUEST_SUCCESS": return {
            ...state,
            cards: action.payload,
            loading: false
        }
        case "GET_ALL_CARDS_BY_SERIES_REQUEST_FAIL": return {
            ...state,
            error: action.payload,
            loading: false
        }
        default: return state
    }
}