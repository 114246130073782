import axios from 'axios';
import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const TrainerDetail = ({ trainer }) => {

    console.log(trainer);
    const [user, setUser] = useState({});

    useEffect(() => {
        if (localStorage.getItem("user")) {
            setUser(JSON.parse(localStorage.getItem("user")));
        }
    }, [])

    var edit = "";
    var deleted="";
    if (user.role === "admin" || user.role === "manager") {
        edit = <button className="btn btn-warning">Edit</button>
    }
    if(user.role === "admin"){
        deleted =< button className="btn btn-small btn-danger" onClick={e=>handleClickOpen()}>DELETE</button>
    }

    const onDelete = (e) =>{
        axios.post("/api/card/deleteCard", {id: trainer._id}, {
            headers: {
                'Authorization': `Basic ${user.token}`
            }
        }).then(res=>{
            if (res.data.message === "success") {
                alert("Deleted");
                window.location.href="/";
            }
        }).catch(err=>{
            console.log(err.response.status);
            let message = "fail";
            console.log(err)
            alert(message);
        })
        handleClose();
    }

    // for confirm dialog
    const [open, setOpen] = React.useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    let dialog = <div>
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {"Warning"}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Confirm DELETE card {trainer.cardName}?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>cancel</Button>
                <Button onClick={onDelete} autoFocus>
                    Confirm
                </Button>
            </DialogActions>
        </Dialog>
    </div>


    return (
        <div className="row ">
            {dialog}
            <div className="col-md-6">
                <div className="card p-2 m-2">
                    <img src={trainer.cardImg} className="img-fluid" style={{ height: '400px' }} alt={trainer.cardName} />
                </div>
            </div>
            <div className="col-md-6">
                <div className="m-2">
                    <div className="card p-2 m-2">
                        <div className="card-title">
                            <div>
                                {trainer.cardName}<span className="p-2">{trainer.trainerType}</span>
                            </div>
                        </div>
                        <div className='card-body'>
                            <ul className="list-group">
                                <li className="list-group-item"><p>{"系列：" + trainer.cardSeries}</p></li>
                                <li className="list-group-item"><p>{"效果：" + trainer.ability}</p></li>
                                <li className="list-group-item"><p>{"Rare: " + trainer.cardRare}</p></li>
                                <li className="list-group-item"><p>{"賽制標記：" + trainer.cardSet}</p></li>
                                <li className="list-group-item"><p>{trainer.stock>0? "有貨" : "冇貨"}</p></li>
                                <li className="list-group-item"><p>${trainer.price==null?9999:trainer.price}</p></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            {edit}
            {deleted}
        </div>

    );
}

export default TrainerDetail;