import React from 'react';
import { useState } from 'react';
import dieFace1 from '../img/die/normal/Die_Faces_1.png'
import dieFace2 from '../img/die/normal/Die_Faces_2.png'
import dieFace3 from '../img/die/normal/Die_Faces_3.png'
import dieFace4 from '../img/die/normal/Die_Faces_4.png'
import dieFace5 from '../img/die/normal/Die_Faces_5.png'
import dieFace6 from '../img/die/normal/Die_Faces_6.png'
import Breadcrumb from '../components/Breadcrumb';


const RollDieScreen = () => { 

    const imageArray = [dieFace1, dieFace2, dieFace3, dieFace4, dieFace5, dieFace6];

    const [count, setCount] = useState(0);
    
    const roll=(e)=>{
            // Use a functional state update to correctly increment the count
            const innerTimerId = setInterval(() => {
                setCount(randomNumberInRange(0, imageArray.length));
            }, 50);
            const timer = setTimeout(()=>{
                clearInterval(innerTimerId);
            }, 1500)
            
        // return () => {clearInterval(timerId);}
    }

    const randomNumberInRange = (min, max) => {
        // 👇️ get number between min (inclusive) and max (inclusive)
        return Math.floor(Math.random() * (max - min + 1)) + min;
      }



    const image = imageArray[count % imageArray.length];

    return (
        <>
        <Breadcrumb />
        <div className="p-4 ">
            <button className="w-30 img-fluid" onClick={e=>roll(e)}><img src={image} className="w-30 img-fluid" alt="" /></button>
        </div>
        </>
    )
}

export default RollDieScreen;