import axios from "axios";


export const getAllSeries = () => dispatch => {

    dispatch({ type: "GET_ALL_SERIES_REQUEST" });
    console.log("GET_ALL_SERIES_REQUEST")
    axios.get('/api/series/getAllSeries').then(res => {
        // console.log(res);
        dispatch({ type: "GET_ALL_SERIES_REQUEST_SUCCESS", payload: res.data.series });
    }).catch(err => {
        console.error(err);
        dispatch({ type: "GET_ALL_SERIES_REQUEST_FAIL", payload: err });
    });
}