import React from 'react';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CardDetailDialog from './CardDetailDialog';


const Card = ({ card }) => {

    var link;
    if (card.cardType == "pokemon") {
        link = `/cardDetail/pokemon/${card._id}`;
    } else if (card.cardType == "trainer") {
        link = `/cardDetail/trainer/${card._id}`;
    }

    const [open, setOpen] = React.useState(false);
    const handleClickOpen = (e) => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    let dialog = <div>
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth={"md"}
        >
            <DialogTitle id="alert-dialog-title">
            </DialogTitle>
            <DialogContent>
               <CardDetailDialog req={{id:card._id, cardType:card.cardType}} />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>關閉</Button>
            </DialogActions>
        </Dialog>
    </div>

    return (
        <div className="col-md-3">
            {dialog}
            {/* <Button onClick={e=>handleClickOpen(e)}>open</Button> */}
            <div className="card m-4">
                <a style={{textDecoration: "none", color:"black" }} onClick={e=>handleClickOpen(e)}>
                    <div className="card-header">
                        <h5 style={{textDecoration: "none"}}>{card.cardName}</h5>
                    </div>
                    <div className="card-body">
                        <img src={card.cardImg} className="img-fluid h ratio" alt={card.cardName} />
                    </div>
                </a>
            </div>

        </div>
    );
}

export default Card;