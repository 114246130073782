import React, {useState, useEffect} from 'react';
import { useLocation } from 'react-router-dom';

import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';




const Breadcrumb = () => {
    

    const [breadcrumb, setBreadcrumb] = useState("");
    const path = window.location.pathname
    useEffect(() => {
        returnBreadcrumb()
        // alert(path);
    }, [path]);
    

    var returnBreadcrumb = () =>{
        if(path.split('/')[1]!=""){
            // console.log(window.location.pathname);
            // console.log(window.location.pathname.split('/'));
            let newBreadcrumb=<div>
                    <hr style={{margin:"55px 0px 0px 0px"}}/>
                    <Breadcrumbs separator="›" aria-label="breadcrumb" style={{color:"#3388FF", width:"100%", marginTop:"0px", paddingLeft:"15px", backgroundColor:"rgb(231, 231, 231)"}}>
                    
                    
                        {path.split('/').map((element, index)=>{
                            console.log(index)
                            if(index==1 && element!=""){
                                return <Link underline="hover" color="inherit" href="/">
                                    › home
                                </Link>
                            }else if(index > 1 && index < 3){
                                return <Link
                                    underline="hover"
                                    color="inherit"
                                    href={"/"+path.split('/')[index-1]}>
                                    {path.split('/')[index-1]}
                                </Link>
                            }
                            
                        })}
                    </Breadcrumbs>
                    <hr style={{margin:"0px"}} />
                </div>
            setBreadcrumb(newBreadcrumb)
        }else{
            setBreadcrumb("")
        }
    }

    return <div>
        {breadcrumb}
        </div>

}

export default Breadcrumb;