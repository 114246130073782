import React, { useEffect, useState } from 'react';
import PokemonDetail from '../components/PokemonDetail';
import TrainerDetail from '../components/TrainerDetail';
import axios from 'axios';


const CardDetailDialog = ({ req }) => {
    let condition = {};
    let { cardType, id } = req;
    condition = { _id: id }


    const [ loading, setLoading ] = useState(true);

    const [ error, setError ] = useState("");

    const [ cards, setCards ] = useState([]);

    useEffect(()=>{
        axios.post('/api/card/getAllCardByCondition', {condition, mode:"full"}).then(res => {
            setLoading(false);
            // console.log(res);
            setCards(res.data.cards);
        }).catch(err => {
            console.error(err);
            setLoading(false);
            setError("好似有啲問題...");
        });
    }, [])


    return (
        <div>
            
            <div className="container-fluid">
                {loading ? (
                    <div className="spinner-border text-primary m-3" role="status">

                    </div>
                ) : error ? (
                    <h1>Something went wrong</h1>
                ) : (

                    cards.map(card => {
                        return <div key={card._id}>
                            {/* {console.log(card._id)} */}
                            {cardType=="pokemon" ? (<PokemonDetail pokemon={card} />) : cardType=="trainer" ? (<TrainerDetail trainer={card} />) : (<h1>無呢張卡喎</h1>)}
                        </div>
                    })

                )}
            </div>
        </div>
    );
}

export default CardDetailDialog;
