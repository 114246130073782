import React from 'react';
import { Link } from 'react-router-dom';

const Product = ({ series }) => {
    return (
        <div className="col-md-3">

            <div className="card m-4">
                <Link style={{textDecoration: "none", color:"black"}} to={`/card/cardSeries/${series.seriesCode}`}>
                    <div className="card-header">
                        <h6>{series.seriesCode}</h6><p />
                        <h5>{series.seriesName}</h5>
                    </div>
                    <div className="card-body">
                        <img src={series.seriesImg} className="img-fluid h ratio" alt={series.seriesName} />
                    </div>
                </Link>
            </div>

        </div>
    );
}

export default Product;