import React from 'react';
import { useState } from 'react';
import coinHead from '../img/coin/normal/coin_head.png'
import coinHeadLeft from '../img/coin/normal/coin_head_left.png'
import coinHeadRight from '../img/coin/normal/coin_head_right.png'
import coinTail from '../img/coin/normal/coin_tail.png'
import coinTailLeft from '../img/coin/normal/coin_tail_left.png'
import coinTailRight from '../img/coin/normal/coin_tail_right.png'
import Breadcrumb from '../components/Breadcrumb';


const FlipCoinScreen = () => { 

    const imageArray = [coinHead, coinHeadLeft, coinTailRight, coinTail, coinTailLeft, coinHeadRight];


    const [count, setCount] = useState(0);

    const [loading, setLoading] = useState(false);
    const [first, setFirst] = useState(true);

    
    
    const flip=(e)=>{
            // Use a functional state update to correctly increment the count
            setFirst(false)
            var i=0
            var coinFace=randomNumber()
            const loop = setInterval(() => {
                setLoading(true)
                setCount(++i);
            }, 100);
            const timer = setTimeout(()=>{
                clearInterval(loop);
                const stop = setInterval(() =>{
                    if(i % imageArray.length != coinFace ){
                        setCount(++i)
                    }else{
                        setLoading(false)
                        clearInterval(stop)
                    }
                }, 130)
            }, 1500)
            
    }

    const randomNumber = () => {
        // 👇️ get number between min (inclusive) and max (inclusive)
        let result = Math.floor((Math.random() * 1000) % 6)
        while (result!=0 && result!=3){
            result = Math.floor((Math.random() * 1000) % 6)
        }
        return result;
    }



    const image = imageArray[count % imageArray.length];

    return (
        <>
        <Breadcrumb />
        <div className="p-4 ">
            
            <button className="w-30 img-fluid"onClick={e=>flip(e)}>
                <img src={image} className="w-30 img-fluid" alt="" />
                {!first ? 
                    loading ? 
                        (<div>flip緊...</div>)
                    : <div className="w-30 img-fluid">flip完!!!</div> 
                : null}
            </button>
        </div>
        </>
    )
}

export default FlipCoinScreen;