import React from 'react';
import axios from 'axios';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { KeyboardCapslockSharp, Receipt } from '@mui/icons-material';
import { height } from '@mui/system';
import Button from '@mui/material/Button';
// import Dialog from '@mui/material/Dialog';
// import DialogActions from '@mui/material/DialogActions';
// import DialogContent from '@mui/material/DialogContent';
// import DialogContentText from '@mui/material/DialogContentText';
// import DialogTitle from '@mui/material/DialogTitle';
// import CompetitionDetailDialog from '../components/CompetitionDetailDialog';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Breadcrumb from '../components/Breadcrumb';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';


const CompetitionRecordScreen = () => {

    const user = JSON.parse(localStorage.getItem("user"));

    if (!user || (user.role.indexOf('admin') == -1 && user.role.indexOf('manager') == -1 && user.role.indexOf('shopkeeper') == -1)) {
        window.location.href = "/admin";
    }
    const [name, setName] = useState("");

    const [date, setDate] = useState("all");

    const [range, setRange] = useState("day");

    const [game, setGame] = useState("all");

    const [isActive, setIsActive] = useState("all");

    const [clicked, setClicked] = useState(true);

    const [competitions, setCompetitions] = useState([]);

    const [open, setOpen] = React.useState(false);

    const [openDetail, setOpenDetail] = React.useState(false);

    const [selectedPreorder, setSelectedPreorder] = React.useState([]);

    const [competitionDetail, setCompetitionDetail] = React.useState({});

    const [tableBody, setTableBody] = React.useState();

    const [orderCount, setOrderCount] = React.useState(0);

    const [orderBy, setOrderBy] = React.useState(0);



    // let selectedPreorder=[];

    useEffect(() => {
        renderTableBody(competitions)
    }, [competitions]);


    const onSearch = () => {
        // match.params.phone=null;
        setClicked(false)
        request(setKey())

    }

    const setKey = () => {
        var key = ""
        if (date != null && date != "") {
            if (key != "") {
                key += "&"
            }
            let newDate = new Date(date.toISOString());
            newDate.setHours(newDate.getHours(), +480)
            key += "date=" + newDate.toISOString().split("T")[0];
        }
        if (game != "all") {
            if (key != "") {
                key += "&"
            }
            key += "game=" + game;
        }

        if (key != "") {
            key += "&"
        }
        key += "range=" + range;

        // if (productCat != "all") {
        //     if (key != "") {
        //         key += "&"
        //     }
        //     key += "productCat=" + productCat;
        // }
        // console.log(key)
        return key;
    }

    const selected = (e) => {
        let array = selectedPreorder
        if (e.target.checked) {
            array.push(e.target.value)
        } else {
            var i = array.indexOf(e.target.value);
            array.splice(i, 1);
        }
        setSelectedPreorder(array);
        console.log(selectedPreorder);

    }

    const request = (key) => {
        console.log(key)
        axios.get('/api/competition/getCompetitionByDate?' + key, {
            headers: {
                'Authorization': `Basic ${user.token}`
            }
        }).then(res => {
            // setLoading(false);
            // console.log(res);
            if (res.data.competitions != null) {
                setCompetitions(res.data.competitions);
            }

        }).catch(err => {
            console.error(err);
            // setLoading(false);
            alert("好似有啲問題...");
        });
    }


    const sortProducts = (value) => {
        if (value == orderBy) {
            setOrderCount(orderCount + 1);
        } else {
            setOrderCount(1);
        }
        let sorted = competitions.sort(function (a, b) {
            if (orderCount % 2 == 1) {
                return a[value] < b[value] ? 1 : -1;
            } else if (orderCount % 2 == 0) {
                return a[value] > b[value] ? 1 : -1;
            }

        });
        // console.log(sorted)
        setCompetitions(sorted);
        setOrderBy(value)
        renderTableBody(competitions);
    }

    const renderTableBody = (results) => {
        setTableBody(<tbody>
            {results.map((competition, index) => {
                let cDate = new Date(competition.date)
                cDate.setHours(cDate.getHours(), +480)
                return <tr>
                    <th><input type="checkbox" id="_id" value={competition._id} onChange={e => selected(e)} /></th>
                    <th scope="row">{index + 1}</th>
                    <td>{cDate.toISOString().split("T")[0]}</td>
                    <td>{competition.name}</td>
                    <td>{competition.game}</td>
                    <td>{competition.parti}</td>
                    <td>{competition.cost}</td>
                    <td>{competition.price}</td>
                    <td><button className="btn btn-success" style={{ width: "100%" }} value={competition._id} onClick={e => handleClickOpenDetail("edit", competition._id)} disabled>Detail</button> </td>
                </tr>
            })}
        </tbody>)
    }



    const resetSearch = () => {
        window.location.href = "/admin/competition";
    }


    const handleClickOpenDetail = (mode, id) => {
        setOpenDetail(true);
        let req = {};
        if (mode == "edit") {
            req = { mode, id }
        } else {
            req = { mode }
        }
        setCompetitionDetail(req)
    };

    const handleCloseDetail = () => {
        setOpenDetail(false);
        request(setKey())
    };

    let competitionDetailDialog = <div>
        <Dialog
            open={openDetail}
            onClose={handleCloseDetail}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth
            maxWidth={"md"}
        >
            <DialogTitle id="alert-dialog-title">
            </DialogTitle>
            <DialogContent>
                {console.log(competitionDetail)}
                {/* <CompetitionDetailDialog req={competitionDetail} /> */}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCloseDetail}>關閉</Button>
            </DialogActions>
        </Dialog>
    </div>

    // var index = 0;

    return (
        <>
            <Breadcrumb />
            <div className="container-fluid">
                {/* {competitionDetailDialog} */}
                <h2>比賽紀錄</h2>
                <div className="card p-2 m-2">
                    <div className="row">
                        <div className="col-md-4">
                            <div className='datePicker' style={{ width: "100%" }}>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        inputFormat="DD/MM/YYYY"
                                        label="比賽日期"
                                        value={date}
                                        onChange={(newValue) => {
                                            setDate(newValue);
                                        }}
                                        renderInput={(params) => <TextField {...params}  size="small"/>}
                                    />
                                </LocalizationProvider>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="p-2">
                                <select className="form-select" aria-label="productCat" value={range} onChange={e => setRange(e.target.value)}>
                                    <option value="day" disabled> 日期範圍: 一日 </option>
                                    <option value="day">一日</option>
                                    <option value="week">前一星期</option>
                                    <option value="month">前一個月</option>
                                </select>
                            </div>
                        </div>
                        {/* <div className="col-md-4">

                        <div className="p-2">
                            <select className="form-select" aria-label="productType" value={productType} onChange={e => setProductType(e.target.value)}>
                                <option value="all"> 商品類型: 全部 </option>
                                <option value="booster">Booster</option>
                                <option value="stater">預組</option>
                                <option value="sleeve">卡套</option>
                                <option value="box">卡盒</option>
                                <option value="playmat">Playmat</option>
                                <option value="giftBox">禮盒</option>
                                <option value="sundry">雜項</option>
                                <option value="other">其他</option>
                            </select>
                        </div>
                    </div> */}
                        {/* <div className="col-md-6">
                        <div className="p-2">
                            <select className="form-select" aria-label="productCat" value={game} onChange={e => setGame(e.target.value)}>
                                <option value="all"> 比賽遊戲: 全部 </option>
                                <option value="PKM">PKM</option>
                                <option value="DGM">DGM</option>
                                <option value="YGO">YGO</option>
                                <option value="OP">One Piece</option>
                                <option value="other">其他</option>
                            </select>
                        </div>
                    </div> */}

                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <div className="p-2">
                                <button className="btn btn-warning" style={{ marginTop: "7px", width: "100%" }} onClick={e => resetSearch()}>重設</button>
                                {/* {
                                    productType==='all' && orderStatus==='all' && (phone === '' || name === '')? 
                                        <button className="btn btn-warning" style={{marginTop: "7px", width:"100%"}}  disabled>重設</button> 
                                    :
                                    <button className="btn btn-warning" style={{marginTop: "7px", width:"100%"}}  onClick={e=>resetSearch()}>重設</button> 
                                } */}
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="p-2">
                                <button className="btn btn-primary" style={{ marginTop: "7px", width: "100%" }} onClick={e => onSearch()} >搜尋</button>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="p-2">
                                {/* {newCompetitionButton()} */}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card p-2 m-2" >
                    {competitions.length > 0 ?
                        <div style={{ display: "flex", overflow: "auto" }}>
                            <table className="table table-hover" >
                                <thead >
                                    <tr>
                                        <th></th>
                                        <th scope="col">#</th>
                                        <th scope="col" onClick={() => sortProducts("date", "desc")}>日期</th>
                                        <th scope="col" >比賽名</th>
                                        <th scope="col" onClick={() => sortProducts("game", "desc")}>比賽遊戲</th>
                                        <th scope="col" >人數</th>
                                        <th scope="col" onClick={() => sortProducts("cost", "desc")}>成本</th>
                                        <th scope="col" onClick={() => sortProducts("price", "desc")}>價錢</th>
                                        <th scope="col">詳情</th>
                                    </tr>
                                </thead>
                                {tableBody}
                            </table>
                        </div>



                        : clicked ? "搜尋先" : "冇記錄"


                    }
                </div>


            </div>
        </>
    )
}

export default CompetitionRecordScreen;