import React from 'react';
import axios from 'axios';
import { useState, useEffect } from 'react';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import Breadcrumb from '../components/Breadcrumb';


const AboutUsScreen = () => {

    const [loading, setLoading] = useState(true);

    const [error, setError] = useState("");

    const [socialMedia, setSocialMedia] = useState({
        facebook: "https://www.facebook.com/playcardchill",
        instagram: "https://instagram.com/playcardchill?r=nametag",
        whatsappGroup: "https://playcardchill.com/api/socialMedia/whatsappPKM"
    });

    useEffect(() => {
        axios.post('/api/config/getSocialMedia').then(res => {
            setLoading(false);
            //console.log(res);
            setSocialMedia(res.data.socialMedia);
        }).catch(err => {
            console.error(err);
            setLoading(false);
            setError("好似有啲問題...");
        });
    }, [])

    return (
        <>
        <Breadcrumb />
        <div className="p-4 ">
            <div className="container-fluid">
                <div className="row" >
                    <div className="col">
                        <img src={require("../img/logo.png")} alt={"logo"} style={{ height: "auto", maxWidth: "250px" }}></img>
                    </div>
                    <div className="col" >
                        <p>專營Pokemon 集換卡</p>
                        <p>歡迎大家交流來本店交流定期舉辦比賽，大家互相切磋，一齊上位</p>
                        <p>本店網站設有查卡器，供大家尋找卡片資料</p>
                        <p>如果任何產品想預訂/有任何散卡想放賣</p>
                        <p>歡迎聯絡我地客服專員或者任何途徑pm 我地</p>

                    </div>


                </div>
            </div>
            <h3>Email: playcardchill@gmail.com</h3>
            <p />
            <h3>電話︰63458076 / 90643439</h3>
            <p />
            <h3>Social Media: <span>&nbsp;</span>
                <a href={socialMedia.whatsappGroup} target="_blank"><WhatsAppIcon fontSize="large" /></a>
                <span>&nbsp;</span>
                <a href={socialMedia.instagram} target="_blank"><InstagramIcon fontSize="large" /></a>
                <span>&nbsp;</span>
                <a href={socialMedia.facebook} target="_blank"><FacebookIcon fontSize="large" /></a>
            </h3>
            <p />
            <br />
            <label for="map"><p><h3>地址︰</h3></p>
                <p><h3>大埔鄉事會街9號 大日子商場 3樓317-318號鋪</h3></p>
                <p><h3>🚊大埔墟東鐵站A出口步行5分鐘</h3></p></label>
            <iframe
                id="map"
                width="90%"
                height="90%"
                style={{ border: "0" }}
                loading="lazy"
                allowfullscreen
                src="https://www.google.com/maps/embed/v1/place?key=AIzaSyBPhYpn8u-o3DQXV7yV_XIbhzo0UrhAVqg&q=大日子商場,Seattle+WA">
            </iframe>
        </div>
        </>
    )
}

export default AboutUsScreen;