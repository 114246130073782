import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

const AdvanceSearchScreen = () => {

    const [isShown, setIsShown] = useState(true);
    const [showSideBarBtn, setShowSideBarBtn] = useState("隠藏側邊欄")
    const [cardType, setCardType] = useState("");
    const [height, setHeight] = useState(window.innerHeight - 55 - 60);
    const [cards, setCards] = useState([{}]);

    const showSideBar = (e) => {
        if (isShown) {
            setIsShown(false);
            setShowSideBarBtn("顯示側邊欄")
        } else {
            setIsShown(true);
            setShowSideBarBtn("隠藏側邊欄")
        }
    }

    const resizeObserver = new ResizeObserver(entries => {
        // console.log(window.innerHeight)
        setHeight(window.innerHeight - 55 - 60);
    })

    resizeObserver.observe(document.body)

    return (
        <main style={{ height: height, maxHeight: height }}>

            {isShown ?
                <div className="flex-shrink-0 p-3 " style={{ width: "230px", backgroundColor: "#EEEEEE", }}>
                    <a href="/" className="d-flex align-items-center pb-3 mb-3 link-dark text-decoration-none border-bottom">
                        {/* <svg className="bi me-2" width="30" height="24"><use xlink:href="#bootstrap" /></svg> */}
                        <span className="fs-5 fw-semibold">進階搜尋</span>
                    </a>
                    <ul className="list-unstyled ps-0">
                        <li className="mb-1">
                            <button className="btn btn-toggle align-items-center rounded collapsed" data-bs-toggle="collapse" data-bs-target="#home-collapse" aria-expanded="true">
                                卡牌類型
                            </button>
                            <div className="collapse show" id="home-collapse">
                                <ul className="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                                    <div class="form-check">
                                        <input class="form-check-input" style={{ marginLeft: "20px", marginRight: "5px" }} type="radio" name="flexRadioDefault" id="flexRadioDefault1" onClick={e => setCardType("pokemon")} />
                                        <label class="form-check-label" style={{ paddingTop: "5px" }} for="flexRadioDefault1">
                                            Pokemon
                                        </label>
                                    </div>
                                    <div class="form-check">
                                        <input class="form-check-input" style={{ marginLeft: "20px", marginRight: "5px" }} type="radio" name="flexRadioDefault" id="flexRadioDefault2" onClick={e => setCardType("pokemon")} />
                                        <label class="form-check-label" style={{ paddingTop: "5px" }} for="flexRadioDefault2">
                                            訓練家
                                        </label>
                                    </div>
                                    {/* <li><input type="checkbox" style={{ marginLeft: "20px" }} /><a href="#" className="link-dark rounded" style={{ marginLeft: "0px" }}>Pokemon</a></li>
                                    <li><input type="checkbox" style={{ marginLeft: "20px" }} /><a href="#" className="link-dark rounded" style={{ marginLeft: "0px" }}>訓練家</a></li> */}
                                </ul>
                            </div>
                        </li>
                        {cardType == "" ? "" :
                            
                            cardType == "pokemon" ?
                                <div>
                                    <li className="mb-1">
                                        <button className="btn btn-toggle align-items-center rounded collapsed" data-bs-toggle="collapse" data-bs-target="#dashboard-collapse" aria-expanded="false">
                                            Dashboard
                                    </button>
                                        <div className="collapse" id="dashboard-collapse">
                                            <ul className="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                                                <li><a href="#" className="link-dark rounded">Overview</a></li>
                                                <li><a href="#" className="link-dark rounded">Weekly</a></li>
                                                <li><a href="#" className="link-dark rounded">Monthly</a></li>
                                                <li><a href="#" className="link-dark rounded">Annually</a></li>
                                            </ul>
                                        </div>
                                    </li>
                                    <li className="mb-1">
                                        <button className="btn btn-toggle align-items-center rounded collapsed" data-bs-toggle="collapse" data-bs-target="#orders-collapse" aria-expanded="false">
                                            Orders
                                    </button>
                                        <div className="collapse" id="orders-collapse">
                                            <ul className="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                                                <li><a href="#" className="link-dark rounded">New</a></li>
                                                <li><a href="#" className="link-dark rounded">Processed</a></li>
                                                <li><a href="#" className="link-dark rounded">Shipped</a></li>
                                                <li><a href="#" className="link-dark rounded">Returned</a></li>
                                            </ul>
                                        </div>
                                    </li>
                                    <li className="border-top my-3"></li>
                                    <li className="mb-1">
                                        <button className="btn btn-toggle align-items-center rounded collapsed" data-bs-toggle="collapse" data-bs-target="#account-collapse" aria-expanded="false">
                                            Account
                                    </button>
                                        <div className="collapse" id="account-collapse">
                                            <ul className="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                                                <li><a href="#" className="link-dark rounded">New...</a></li>
                                                <li><a href="#" className="link-dark rounded">Profile</a></li>
                                                <li><a href="#" className="link-dark rounded">Settings</a></li>
                                                <li><a href="#" className="link-dark rounded">Sign out</a></li>
                                            </ul>
                                        </div>
                                    </li>
                                </div>
                                : cardType == "Trainer" ?
                                    <div>

                                    </div>
                                    : ""}
                    </ul>
                </div>
                : ""
            }
            <div className=" p-3 " >
                <div style={{ overflow: "auto", maxHeight: window.innerHeight - 55 - 60 }}>
                    <button className="btn btn-outline-success btn-small" onClick={e => showSideBar(e)}>{showSideBarBtn}</button><br />

                </div>



            </div>
        </main>
    )
}

export default AdvanceSearchScreen;