import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import font from "../resources/fonts/msyh.ttf"
import {
    PDFDownloadLink,
    Document,
    Image,
    Font,
    Page,
    Text,
    View,
    StyleSheet,
    PDFViewer,
  } from "@react-pdf/renderer";
import axios from 'axios';

Font.register({
  family: 'MicrosoftBlack',
  src: font,
});


const styles = StyleSheet.create({
    
    page: {
      backgroundColor: "white",
      fontFamily: 'MicrosoftBlack',
    },
    section: {
      margin: 10,
      padding: 10,
    },
    viewer: {
      width: window.innerWidth, //the pdf viewer will take up all of the width and height
      height: window.innerHeight,
    },
    image: {
      width: "25mm",
      padding: 10,
      float: "left"
    },
    paragraph: {
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
    },
    text: {
      marginTop: "1.5mm",
      width: "auto",
      float: "left",
      fontSize: "14mm"
    },
    table: {
        fontSize: "4mm",
        width: '100%',
    },
    row: {
        marginLeft: "4mm",
        display: 'flex',
        flexDirection: 'row',
        borderTop: '1px solid #EEE',
        paddingTop: 8,
        paddingBottom: 8,
    },
    header: {
        borderTop: 'none',
    },
    columnName: {
        width: '40%',
    },
    columnQuantity: {
        width: '15%',
    },
    columnprice: {
        width: '15%',
    },
    columnDeposity: {
        width: '15%',
    },
    columnFinalPayment: {
        width: '15%',
    },
  });


const ReceiptDialog = ({ req }) => {

    let {id} = req;

    const user = JSON.parse(localStorage.getItem("user"));

    const [ loading, setLoading ] = useState(true);

    const [ error, setError ] = useState("");

    const [ preorders, setPreorders ] = useState([]);

    const [ orderDate, setOrderDate ] = useState();

    // const [finalPayment, setFinalPayment] = useState(0);

    var finalPayment=0;
    var name="";
    var phone=""



    useEffect(()=>{
        console.log(id)
        axios.post('/api/preorder/getPreorderByIds', {id},{
            headers: {
                'Authorization': `Basic ${user.token}`
            }
        }).then(res => {
            setLoading(false);
            // console.log(res);
            setPreorders(res.data.preorder);
            let date=res.data.preorder[0].createdDate
            setOrderDate(date.split('T')[0])
        }).catch(err => {
            console.error(err);
            setLoading(false);
            setError("好似有啲問題...");
        });
    }, [])

    // const getTotal=(final)=>{
    //     let money=finalPayment;
    //     money+=final;
    //     setFinalPayment(money);
    // }

    const MyDoc = () => (
        <Document>
            {/*render a single page*/}
            <Page size="A4" style={styles.page}>
                <View>
                    <Text style={{fontSize:"2mm", marginLeft:"1mm", marginTop:"1mm"}}>{id[0]}</Text>
                </View>
                <View style={{marginTop:"5mm"}} />
                <View style={styles.paragraph}>
                    <Image style={styles.image} src={require("../img/logo.png")} />
                    <Text style={styles.text}>Play Card Chill</Text>
                    <Text style={{marginLeft:"38mm", marginTop:"4mm", fontSize:"5mm"}}>日期：{orderDate}</Text>
                </View>
                <View>
                    <Text style={{fontSize:"5mm", marginLeft:"13mm"}}>大埔鄉事會街15號大日子商場3樓317-318號鋪</Text>
                </View>
                <View style={{flexDirection: 'row', alignItems: 'center', marginTop:"2mm"}}>
                    <View style={{flex: 1, height: 1, backgroundColor: 'black'}} />
                    <View>
                    <Text style={{width: 50, textAlign: 'center'}}>發票</Text>
                    </View>
                    <View style={{flex: 1, height: 1, backgroundColor: 'black'}} />
                </View>
                <View style={{marginTop:"5mm"}} />
                <View style={styles.table}>
                    <View style={[styles.row, styles.header]}>
                        <Text style={styles.columnName}>商品</Text>
                        <Text style={styles.columnQuantity}>數量</Text>
                        <Text style={styles.columnprice}>價錢</Text>
                        <Text style={styles.columnDeposity}>來訂</Text>
                        <Text style={styles.columnFinalPayment}>尾數</Text>
                    </View>
                    <View style={{height: 1, width: "100%", backgroundColor: 'black'}} />
                    {preorders.map(preorder=>{
                        return <View style={[styles.row]}>
                            <Text style={styles.columnName}>{preorder.product}</Text>
                            <Text style={styles.columnQuantity}>{preorder.quantity}</Text>
                            <Text style={styles.columnprice}>${preorder.price}</Text>
                            <Text style={styles.columnDeposity}>${preorder.deposite}</Text>
                            <Text style={styles.columnFinalPayment}>${preorder.finalPayment}</Text>
                            {finalPayment+=preorder.finalPayment}
                            {name=preorder.name}
                            {phone=preorder.phone}
                        </View>
                    })}
                    
                </View>
                <View style={{height: 1, width: "100%", backgroundColor: 'black'}} />
                <View style={{flexDirection: 'row', alignItems: 'center', marginTop:"2mm", marginLeft:"4mm"}}>
                    <View style={{flex: 1}}>
                        <Text style={{fontSize: "4mm"}}>
                            顧客名稱︰{name}
                        </Text>
                    </View>
                    <View style={{flex: 1}}>
                        <Text style={{fontSize: "4mm"}}>
                            電話︰{phone}
                        </Text>
                    </View>
                    <View style={{flex: 1}}>
                        <Text style={{fontSize: "4mm"}}>
                            應繳貨款︰${finalPayment}
                        </Text>
                    </View>
                </View>
                <View style={{marginTop:"10mm"}} />
                <View style={{marginLeft:"4mm", fontSize:"3mm"}}>
                    <Text>條款︰</Text>
                    <Text style={{marginTop:"1mm"}}>1. 客戶必須於簽收前，清楚檢查貨物與訂購的型號、確保數量正確及包裝完整無損。</Text>
                    <Text style={{marginTop:"1mm"}}>2. 當客戶發現貨物包裝上型號錯誤、破損或數量不符時，請於收貨當日通知本店，否則本店不接受顧客在這期間之後的任何投訴處理及責任。</Text>
                    <Text style={{marginLeft:"3.4mm"}}>本店有權拒絕更換已簽收之貨品。</Text>
                    <Text style={{marginTop:"1mm"}}>3. 如發現貨品損毀，請立即通知客服，電話為6223 2329或 電郵致playcardchill@gmail.com 安排更換貨品或退款。</Text>
                    <Text style={{marginTop:"1mm"}}>4. 發出到貨通知後，客戶須在發出通知日期計起7日內完成交易/取貨，否則會被視為惡性棄單，並會自動取消訂單，所付訂金將不獲退款</Text>
                    <Text style={{marginLeft:"3.5mm"}}></Text>
                </View>
            </Page>
        </Document>
      )


    return (
    <div>
        {/* { loading ? <div>loading</div> :
        <PDFViewer style={styles.viewer}>
            Start of the document
            {MyDoc()}
        </PDFViewer>
        } */}
        <PDFDownloadLink document={<MyDoc />} fileName="receipt.pdf">
            {({ blob, url, loading, error }) => (loading ? 'Loading document...' : 'Download')}
        </PDFDownloadLink>
    </div>
    );
}

export default ReceiptDialog;
