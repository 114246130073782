export const getAllSeriesReducer = (state = { series: [] }, action) => {

    switch (action.type) {
        case "GET_ALL_SERIES_REQUEST": return {
            ...state,
            loading: true
        };
        case "GET_ALL_SERIES_REQUEST_SUCCESS": return {
            ...state,
            series: action.payload,
            loading: false
        }
        case "GET_ALL_SERIES_REQUEST_FAIL": return {
            ...state,
            error: action.payload,
            loading: false
        }
        default: return state
    }
}