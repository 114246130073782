//import logo from './logo.svg';
import './App.css';
import React, { Component } from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import NavBar from './components/NavBar';
import Footer from './components/Footer';
import bootstrapCss from '../node_modules/bootstrap/dist/css/bootstrap.min.css'
import bootstrapJs from '../node_modules/bootstrap/dist/js/bootstrap.min.js'
import Homescreen from './screens/HomeScreen';
import RegisterScreen from './screens/RegisterScreen';
import LoginScreen from './screens/LoginScreen';
import SeriesListScreen from './screens/SeriesListScreen';
import CardListScreen from './screens/CardListScreen';
import CardDetailScreen from './screens/CardDetailScreen';
import NewPokemonScreen from './screens/NewPokemonScreen';
import NewSeriesScreen from './screens/NewSeriesScreen';
import NewTrainerScreen from './screens/NewTrainerScreen';
import AdminScreen from './screens/AdminScreen';
import AboutUsScreen from './screens/AboutUsScreen';
import AdvanceSearchScreen from './screens/AdvanceSearchScreen';
import ShopActivityScreen from './screens/ShopActivityScreen';
import PTCGNewsScreen from './screens/PTCGNewsScreen';
import PreOrderScreen from './screens/PreOrderScreen';
import RollDieScreen from './screens/RollDieScreen';
import FlipCoinScreen from './screens/FlipCoinScreen';
import ProductScreen from './screens/ProductScreen';
import PriceListScreen from './screens/PriceListScreen';
import NewOrderRecordScreen from './screens/NewOrderRecordScreen';
import DailyReportScreen from './screens/DailyReportScreen';
import ConsignmentRecordScreen from './screens/ConsignmentRecordScreen';
import UserListScreen from './screens/UserListScreen';
import CompetitionRecordScreen from './screens/CompetitionRecordScreen';
import CompetitionDetailScreen from './screens/CompetitionDetailScreen';
import MonthlyReportScreen from './screens/MonthlyReportScreen';
import NewPreOrderScreen from './screens/NewPreOrderScreen';
// import NewPreOrderScreen from './screens/NewPreOrderScreen';
// import  sign  from './screens/sign';


class App extends Component {


  //

  render() {
    return (

      <div >
        
        <NavBar />
        <div style={{ marginTop: "5px"}}>
          <BrowserRouter >

            <Route path='/' component={Homescreen} exact />
            {/* <Route path='/productList' component={ProductListScreen} exact /> */}
            {/* <Route path='/productDetail/:id' component={ProductDetailScreen} exact /> */}
            <Route path='/series' component={SeriesListScreen} exact />
            <Route path='/card/:condition/:param/:matchType?' component={CardListScreen} exact />
            <Route path='/cardDetail/:cardType/:id' component={CardDetailScreen} exact />

            <Route path='/register' component={RegisterScreen} exact />
            <Route path='/login' component={LoginScreen} exact />
            
            <Route path='/aboutUs' component={AboutUsScreen} exact />
            <Route path='/advanceSearch' component={AdvanceSearchScreen} exact />
            <Route path='/shopActivity' component={ShopActivityScreen} exact />
            <Route path='/news' component={PTCGNewsScreen} exact />
            {/* <Route path='/sign' component={sign} exact /> */}

            
            <Route path='/admin' component={AdminScreen} exact />
            <Route path='/admin/preorder' component={PreOrderScreen} exact />
            <Route path='/admin/product' component={ProductScreen} exact />
            <Route path='/admin/priceList' component={PriceListScreen} exact />
            <Route path='/admin/NewOrderRecord/:orderType' component={NewOrderRecordScreen} exact />
            <Route path='/admin/dailyReport/' component={DailyReportScreen} exact />
            <Route path='/admin/consignmentRecord/' component={ConsignmentRecordScreen} exact />
            <Route path='/admin/manageUser/' component={UserListScreen} exact />
            <Route path='/admin/CompetitionRecord/' component={CompetitionRecordScreen} exact />
            <Route path='/admin/CompetitionDetail/:mode/' component={CompetitionDetailScreen} exact />
            <Route path='/admin/CompetitionDetail/:mode/:id' component={CompetitionDetailScreen} exact />
            <Route path='/admin/monthlyReport/' component={MonthlyReportScreen} exact />
            <Route path='/admin/monthlyReport/' component={MonthlyReportScreen} exact />
            <Route path='/admin/newPreorder' component={NewPreOrderScreen} exact />
            {/* <Route path='/admin/preorder/:phone' component={PreOrderScreen} exact />
            <Route path='/admin/newPreorder/:phone/:name/:shippingAddress' component={NewPreOrderScreen} exact />
            <Route path='/admin/editPreorder/:id' component={NewPreOrderScreen} exact /> */}
            {/* <Route path='/admin/NewPokemon' component={NewPokemonScreen} exact /> */}
            {/* <Route path='/admin/NewPokemon/:id' component={NewPokemonScreen} exact /> */}
            {/* <Route path='/admin/NewSeries' component={NewSeriesScreen} exact /> */}
            {/* <Route path='/admin/NewTrainer' component={NewTrainerScreen} exact /> */}

            <Route path='/tools/die' component={RollDieScreen} exact />
            <Route path='/tools/flipCoin' component={FlipCoinScreen} exact />
            

          </BrowserRouter>
          
        </div>
        <Footer />

      </div>
    );
  }

}

export default App;


