import React, { useState } from 'react';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';


 const ConfirmationDialog = ({ callback }) => {

    const [reason, setReason] = useState("");
    // let { orderType } = match.params;

    let testReturn = (product) => {
        callback.handleCallback(product);
    }

    let onUpload = () => {
        const item={id: callback.id, reason:reason}
        testReturn(item)
    }

    return (
        <div>
            確定要Delete？
            <br />
            Del左就冇得返轉頭架喇
            <br />
            <TextField 
                fullWidth 
                required 
                id="standard-required" 
                label="原因" 
                variant="standard" 
                onChange={e=>setReason(e.target.value)}
            />
            <Button onClick={e=>onUpload()} color="error">DELETE</Button>
            <Button onClick={callback.handleCloseDetail} color="primary">Cancel</Button>
        </div>
        
    );
}

export default ConfirmationDialog;
