import React, {useState} from 'react';
import logo from '../img/logo.png'

const NavBar = () => {

    const user = JSON.parse(localStorage.getItem("user"));

    var loginMenu = ""
    if (user) {
        loginMenu = <ul className="navbar-nav mb-2 mb-lg-0">
            <li className="nav-item dropstart">
            <a className="nav-link dropdown-toggle" href="#" id="navbarDropdownUser" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                {user.name}
            </a>
            <ul className="dropdown-menu" aria-labelledby="navbarDropdownUser">
                {user.role.indexOf('admin')>=0 || user.role.indexOf('manager')>=0 || user.role.indexOf('shopkeeper')>=0 || user.role.indexOf('seller')>=0 ? <li><a className="dropdown-item" href="/admin">Admin Page</a></li> : null}
                {user.role.indexOf('admin')>=0  ?<li><a className="dropdown-item" href="/register">Register New User</a></li>: null}
                {user.role.indexOf('admin')>=0  ?<li><a className="dropdown-item" href="/admin/manageUser">Manage User</a></li>: null}
                <div class="dropdown-divider"></div>
                <li><a className="dropdown-item" href="#" onClick={e=> logout(e)}>Logout</a></li>
                
            </ul>
            </li>
        </ul>

    }

    

    

    const logout=(e)=>{
        localStorage.removeItem("user");
        window.location.href="/"
    }

    const [keyword, setKeyword]=useState("");


    const onSearch=(e)=>{
        if(keyword!=""){
            window.location.href=`/card/cardName/${keyword}`
        }
        
    }

    return (
        <nav className="navbar navbar-fixed navbar-expand-lg navbar-light bg-light" style={{ position: "fixed", zIndex: 10000, padding:"0px" }}>
            <div className="container-fluid">
                <a className="navbar-brand" style={{fontFamily:"pokemonFont", fontSize:"30px"}}href="/">
                    <img src={logo} alt="logo" width="auto" height="26" className="d-inline-block align-text-top me-2" />
                    Play Card Chill
                </a>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">

                    <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                        {/* <li className="nav-item dropdown">
                            <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                卡牌資訊
                            </a>
                            <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                                <li><a className="dropdown-item" href="/series">系列</a></li>
                                <li><a className="dropdown-item" href="/card/cardType/pokemon">精靈</a></li>
                                <li><a className="dropdown-item" href="/card/cardType/trainer">訓練家</a></li>
                                <li><a className="dropdown-item" href="#">進階搜尋</a></li>
                                <div class="dropdown-divider"></div>
                                <div className="d-flex">
                                    <input className="form-control" style={{ height: "100%" }} type="search" placeholder="卡牌名稱" aria-label="Search" onChange={e=>setKeyword(e.target.value)}/>
                                    <button className="btn btn-sm btn-primary" style={{ height: "100%", marginTop: "auto" }} onClick={e=>onSearch(e)} disabled={keyword === ''} >Search</button>
                                </div>
                            </ul>
                        </li> */}
                        <li className="nav-item">
                            <a className="nav-link" href="/news">最新消息</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="/shopActivity">店鋪活動</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="/onlineStore">網店</a>
                        </li>
                        <li className="nav-item dropdown">
                            <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                小工具
                            </a>
                            <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                                <li><a className="dropdown-item" href="/tools/die">骰仔</a></li>
                                <li><a className="dropdown-item" href="/tools/flipCoin">Flip Coin</a></li>
                                {/* <li><a className="dropdown-item" href="/tools/hpCounter">生命值計算器</a></li> */}
                            </ul>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="/aboutUs">關於我們</a>
                        </li>
                    </ul>
                    {loginMenu}

                </div>
            </div>
        </nav>
    );
}

export default NavBar;
