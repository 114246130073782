import React from 'react';
import axios from 'axios';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { KeyboardCapslockSharp, Receipt } from '@mui/icons-material';
import { height } from '@mui/system';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import ProductDetailDialog from '../components/ProductDetailDialog';
import '../css/input.css';
import '../css/table.css';
import Breadcrumb from '../components/Breadcrumb';
import ConfirmationDialog from '../components/ConfirmationDialog';
import RefundDialog from '../components/RefundDialog';

const DailyReportScreen = () => {

    const user = JSON.parse(localStorage.getItem("user"));

    if (!user || (user.role.indexOf('admin') == -1 && user.role.indexOf('manager') == -1 && user.role.indexOf('shopkeeper') == -1)) {
        window.location.href = "/admin";
    }

    const [productName, setProductName] = useState("");

    const [productType, setProductType] = useState("all");

    const [productCat, setProductCat] = useState("all");

    const [isActive, setIsActive] = useState("all");

    const [clicked, setClicked] = useState(true);

    const [orderRecords, setOrderRecords] = useState([]);

    const [selectedPreorder, setSelectedPreorder] = useState([]);

    const [productDetail, setProductDetail] = useState({});

    const [tableBody, setTableBody] = useState();

    const [orderCount, setOrderCount] = useState(0);

    const [orderBy, setOrderBy] = useState(0);

    const [reportDate, setReportDate] = useState("")

    const [reportDateValue, setReportDateValue] = useState();

    const [cash, setCash] = useState(0);
    const [payme, setPayme] = useState(0);
    const [fps, setFps] = useState(0);
    const [octopus, setOctopus] = useState(0);
    const [yedpay, setYedpay] = useState(0);

    const [buyCash, setBuyCash] = useState(0);
    const [buyPayme, setBuyPayme] = useState(0);
    const [buyFps, setBuyFps] = useState(0);
    const [buyOctopus, setBuyOctopus] = useState(0);
    const [buyYedpay, setBuyYedpay] = useState(0);

    const [sellCash, setSellCash] = useState(0);
    const [sellPayme, setSellPayme] = useState(0);
    const [sellFps, setSellFps] = useState(0);
    const [sellOctopus, setSellOctopus] = useState(0);
    const [sellYedpay, setSellYedpay] = useState(0);

    const [lastCash, setLastCash] = useState(0);
    const [lastPayme, setLastPayme] = useState(0);
    const [lastFps, setLastFps] = useState(0);

    const [lastDailySummary, setLastDailySummary] = useState({});
    const [displayDate, setDisplayDate] = useState();
    // let selectedPreorder=[];

    useEffect(() => {
        // request(setKey())
        if (orderRecords.length > 0) {
            renderTableBody(orderRecords)
        }

    }, [orderRecords]);


    const onSearch = () => {
        // match.params.phone=null;
        setClicked(false)
        request(setKey())

    }

    const setKey = () => {
        let key = "";

        if (reportDateValue != null && reportDateValue != "") {
            console.log(reportDateValue.toISOString().split('T')[0])
            if (key != "") {
                key += "&"
            }
            key += "date=" + Date.parse(reportDateValue.toISOString().split('T')[0]);
        } else {
            key += "date=" + Date.parse(new Date().toISOString().split('T')[0]);
        }
        if (productName != "") {
            if (key != "") {
                key += "&"
            }
            key += "productName=" + productName;
        }

        if (productType != "all") {
            if (key != "") {
                key += "&"
            }
            key += "productType=" + productType;
        }

        if (productCat != "all") {
            if (key != "") {
                key += "&"
            }
            key += "productCat=" + productCat;
        }
        // console.log(key)
        return key;
    }

    const selected = (e) => {
        let array = selectedPreorder
        if (e.target.checked) {
            array.push(e.target.value)
        } else {
            var i = array.indexOf(e.target.value);
            array.splice(i, 1);
        }
        setSelectedPreorder(array);
        console.log(selectedPreorder);

    }

    const request = (key) => {
        axios.get('/api/orderRecord/dailyReport?' + key, {
            headers: {
                'Authorization': `Basic ${user.token}`
            }
        }).then(res => {
            // setLoading(false);
            // console.log(res);
            if (res.data.dailyReport != null) {
                console.log(res.data.dailyReport);
                setOrderRecords(res.data.dailyReport);
            } else {
                setOrderRecords([])
            }
            let key = ""
            if (reportDateValue != null && reportDateValue != "") {
                key += "date=" + Date.parse(reportDateValue.toISOString().split('T')[0]);
            } else {
                key += "date=" + Date.parse(new Date().toISOString().split('T')[0]);
            }
            axios.get('/api/dailySummary/getLastDailySummary?' + key, {
                headers: {
                    'Authorization': `Basic ${user.token}`
                }
            }).then(res => {
                // console.log(res);
                if (res.data.dailySummary != null) {
                    setLastDailySummary(res.data.dailySummary);
                } else {
                    setLastDailySummary({})
                }
            }).catch(err => {
                
                
            });

        }).catch(err => {
            console.error(err);
            // setLoading(false);
            if(err.response && err.response.status==401){
                alert("要再login過")
                window.location.href = "/admin";
            }else{
                alert("好似有啲問題...");
            }
        });
    }



    const formatDate = (date) => {
        let formattedDate = new Date(date)
        return formattedDate.toLocaleString()
    }

    const [thType, setThType] = useState("買/賣↑↓")
    const [thProductName, setThProductName] = useState("貨名↑↓")
    const [thProductType, setThProductType] = useState("商品類型↑↓")
    const [thProductCat, setThProductCat] = useState("商品類別↑↓")
    const [thPaymentMethod, setThPaymentMethod] = useState("付款方式↑↓")

    const sortOrders = (value) => {
        const type = ["買/賣↑↓", "買/賣↑", "買/賣↓"]
        const productName = ["貨名↑↓", "貨名↑", "貨名↓"]
        const productType = ["商品類型↑↓", "商品類型↑", "商品類型↓"]
        const productCat = ["商品類別↑↓", "商品類別↑", "商品類別↓"]
        const paymentMethod = ["付款方式↑↓", "付款方式↑", "付款方式↓"]


        if (value == orderBy && orderCount < 2) {
            setOrderCount(orderCount + 1);
        } else {
            setOrderCount(1);
        }

        if (value == "type") {
            setThType(type[orderCount % 3])
            setThProductName(productName[0])
            setThProductType(productType[0])
            setThProductCat(productCat[0])
            setThPaymentMethod(paymentMethod[0])
        } else if (value == "productName") {
            setThType(type[0])
            setThProductName(productName[orderCount % 3])
            setThProductType(productType[0])
            setThProductCat(productCat[0])
            setThPaymentMethod(paymentMethod[0])
        } else if (value == "productCat") {
            setThType(type[0])
            setThProductName(productName[0])
            setThProductType(productType[0])
            setThProductCat(productCat[orderCount % 3])
            setThPaymentMethod(paymentMethod[0])
        } else if (value == "productType") {
            setThType(type[0])
            setThProductName(productName[0])
            setThProductType(productType[orderCount % 3])
            setThProductCat(productCat[0])
            setThPaymentMethod(paymentMethod[0])
        } else if (value == "paymentMethod") {
            setThType(type[0])
            setThProductName(productName[0])
            setThProductType(productType[0])
            setThProductCat(productCat[0])
            setThPaymentMethod(paymentMethod[orderCount % 3])
        }

        let sorted = orderRecords.sort(function (a, b) {
            if (orderCount % 3 == 2) {
                return a[value].toUpperCase() < b[value].toUpperCase() ? 1 : -1;
            } else if (orderCount % 3 == 1) {
                return a[value].toUpperCase() > b[value].toUpperCase() ? 1 : -1;
            }
        });

        console.log(sorted)
        setOrderRecords(sorted);
        setOrderBy(value)
        renderTableBody(orderRecords);
    }

    const renderTableBody = (pos) => {
        let newCash = 0
        let newFps = 0
        let newPayme = 0
        let newOctopus = 0
        let newYedpay = 0

        let newBuyCash = 0
        let newBuyFps = 0
        let newBuyPayme = 0
        let newBuyOctopus = 0
        let newBuyYedpay = 0

        let newSellCash = 0
        let newSellFps = 0
        let newSellPayme = 0
        let newSellOctopus = 0
        let newSellYedpay = 0
        setTableBody(<tbody>
            {pos.map((orderRecord, index) => {
                orderRecord.testDate=orderRecord.remarks.remarks.substring(0,8)
                if (orderRecord.type == "buy") {
                    if (orderRecord.paymentMethod == "cash") {
                        newCash -= orderRecord.price
                        newBuyCash -= orderRecord.price
                    } else if (orderRecord.paymentMethod == "payme") {
                        newPayme -= orderRecord.price
                        newBuyPayme -= orderRecord.price
                    } else if (orderRecord.paymentMethod == "fps") {
                        newFps -= orderRecord.price
                        newBuyFps -= orderRecord.price
                    } else if (orderRecord.paymentMethod == "octopus") {
                        newOctopus -= orderRecord.price
                        newBuyOctopus -= orderRecord.price
                    } else if (orderRecord.paymentMethod == "yedpay") {
                        newYedpay -= orderRecord.price
                        newBuyYedpay -= orderRecord.price
                    }
                } else {
                    if (orderRecord.paymentMethod == "cash") {
                        newCash += orderRecord.price
                        newSellCash += orderRecord.price
                    } else if (orderRecord.paymentMethod == "payme") {
                        newPayme += orderRecord.price
                        newSellPayme += orderRecord.price
                    } else if (orderRecord.paymentMethod == "fps") {
                        newFps += orderRecord.price
                        newSellFps += orderRecord.price
                    } else if (orderRecord.paymentMethod == "octopus") {
                        newOctopus += orderRecord.price
                        newSellOctopus += orderRecord.price
                    } else if (orderRecord.paymentMethod == "yedpay") {
                        newYedpay += orderRecord.price
                        newSellYedpay += orderRecord.price
                    }
                }
                let trStyle = {}
                if (!orderRecord.claimed) {
                    trStyle = { "color": "red" }
                } else if (orderRecord.paymentStatus == "退貨" || orderRecord.paymentStatus == "退款" || orderRecord.productName == "退款") {
                    trStyle = { "color": "orange" }
                } else {
                    trStyle = { "color": "black" }
                }
                if (orderRecord.paymentMethod == "cash") {
                    trStyle["background-color"] = "#d9ead3"
                } else if (orderRecord.paymentMethod == "payme") {
                    trStyle["background-color"] = "#f4cccc"
                } else if (orderRecord.paymentMethod == "fps") {
                    trStyle["background-color"] = "#cfe2f3"
                } else if (orderRecord.paymentMethod == "octopus") {
                    trStyle["background-color"] = "#fff2cc"
                } else if (orderRecord.paymentMethod == "yedpay") {
                    trStyle["background-color"] = "#bcbcbc"
                }
                return <tr style={trStyle}>
                    <th><input type="checkbox" id="_id" value={orderRecord._id} onChange={e => selected(e)} /></th>
                    <th scope="row">{index + 1}</th>
                    <td>{formatDate(orderRecord.orderdDate).split(" ")[1]}</td>
                    <td>{orderRecord.type}</td>
                    {orderRecord.productType == "bulkCard" ?
                        <td>{orderRecord.remarks.cardName}</td>
                        :
                        <td>{orderRecord.productName}</td>
                    }
                    <td>{orderRecord.productCat}</td>
                    {orderRecord.productType == "bulkCard" ?
                        <td>{orderRecord.productName}</td>
                        :
                        <td>{orderRecord.productType}</td>
                    }
                    {orderRecord.productType == "bulkCard" ?
                        <td>N/A</td>
                        :
                        <td>{orderRecord.productBatch}</td>
                    }

                    <td><select class="form-select" aria-label="paymentMethod" value={orderRecord.paymentMethod} onChange={onPaymentMetnodChange(index)}>
                        <option value="cash"> Cash </option>
                        <option value="payme"> Payme </option>
                        <option value="fps"> FPS </option>
                        <option value="octopus"> Octopus </option>
                        <option value="yedpay"> yedpay </option>
                        {orderRecord.type == "buy" ?
                            <option value="account"> Account </option>
                            : null}
                    </select></td>
                    <td>{orderRecord.paymentStatus}</td>
                    <td>${orderRecord.price}</td>
                    <td>{orderRecord.quantity}</td>
                    <td>{orderRecord.handlerName}</td>
                    {orderRecord.canClaim == false ?
                        <td>{orderRecord.claimed ? <div>Claim左</div> : <div>未Claim</div>}</td>
                        :
                        <td>
                            <select class="form-select" aria-label="claimed" value={orderRecord.claimed} onChange={onClaimedChange(index)}>
                                <option value={true} > Claim左 </option>
                                <option value={false} > 未Claim </option>
                            </select>
                        </td>
                    }

                    <td>{orderRecord.sellerName}</td>
                    {console.log(orderRecords[index].remarks.remarks)}
                    <td>
                        {orderRecord.productId == "645af342b5e4f4000b9a5a80" ?
                            <div>{orderRecord.remarks.refundRecord.productName + " " + orderRecord.remarks.refundRecord.date}</div>
                            :
                            <textarea class="form-control" style={{ minWidth: "100px" }} id="remark" rows="2" value={orderRecord.remarks.remarks} onChange={onRemarksChange(index)}></textarea>}

                    </td>
                    <td><button className="btn btn-warning" style={{ width: "100%" }} value={orderRecord._id} onClick={e => handleSave(orderRecord._id, orderRecord.remarks.remarks, orderRecord.paymentMethod, orderRecord.claimed)} >Edit</button> </td>
                    {orderRecord.remarks.remarks.indexOf("獎品") == -1 && orderRecord.paymentStatus != "退款" && orderRecord.paymentStatus != "退貨" && orderRecord.type != "buy" && orderRecord.productType != "比賽" ?
                        <td><button className="btn btn-danger" style={{ width: "100%" }} value={orderRecord._id} onClick={e => handleClickOpenRefund(orderRecord._id)} >退貨/款</button> </td>
                        : <td></td>}
                    {orderRecord.remarks.remarks.indexOf("獎品") == -1 && orderRecord.paymentStatus != "退款" && orderRecord.paymentStatus != "退貨" && orderRecord.productName != "退款" && orderRecord.productType != "比賽" ?
                        <td><button className="btn btn-danger" style={{ width: "100%" }} value={orderRecord._id} onClick={e => handleClickOpenDetail(orderRecord._id)} >Delete</button> </td>
                        : <td></td>}

                </tr>
            })}
        </tbody>)
        setCash(newCash)
        setPayme(newPayme)
        setFps(newFps)
        setOctopus(newOctopus)
        setYedpay(newYedpay)

        setBuyCash(newBuyCash)
        setBuyFps(newBuyFps)
        setBuyOctopus(newBuyOctopus)
        setBuyPayme(newBuyPayme)
        setBuyYedpay(newBuyYedpay)

        setSellCash(newSellCash)
        setSellFps(newSellFps)
        setSellPayme(newSellPayme)
        setSellOctopus(newSellOctopus)
        setSellYedpay(newSellYedpay)
    }

    const onRemarksChange = (index) => e => {
        let newOrderRecords = orderRecords
        newOrderRecords[index].remarks.remarks = e.target.value
        setOrderRecords(newOrderRecords)
        renderTableBody(orderRecords);
    }

    const onPaymentMetnodChange = (index) => e => {
        let newOrderRecords = orderRecords
        newOrderRecords[index].paymentMethod = e.target.value
        setOrderRecords(newOrderRecords)
        renderTableBody(orderRecords);
    }

    const onClaimedChange = (index) => e => {
        let newOrderRecords = orderRecords
        newOrderRecords[index].claimed = e.target.value
        setOrderRecords(newOrderRecords)
        renderTableBody(orderRecords);
    }

    const handleSave = (id, remarks, paymentMethod, claimed) => {
        // console.log(id+" "+remarks+" "+ paymentMethod+" "+ claimed)
        console.log("send update remarks and payment method");
        let or = { id: id, remarks: remarks, paymentMethod: paymentMethod, claim: claimed }
        axios.post("/api/orderRecord/editOrderRecordRemarks", { orderRecord: or }, {
            headers: {
                'Authorization': `Basic ${user.token}`
            }
        }).then(res => {
            console.log(res.status)
            if (res.data.message === "success") {
                alert("Done");
            } else {
                console.log("fail")
                alert("fail " + res.data.error);
            }
        }).catch(err => {
            if (err.response.data.error == "something's' wrong") {
                alert(err.response.data.error)
            } else {
                console.log(err.response.data.error)
            }
        });
    }

    const handleRefund = (id, type, method, price, reason) => {
        console.log("send refund");

        let or = { id: id, status: type, method: method, price: price, reason: reason }
        // console.log(or)
        axios.post("/api/orderRecord/refund", { orderRecord: or }, {
            headers: {
                'Authorization': `Basic ${user.token}`
            }
        }).then(res => {
            console.log(res.status)
            if (res.data.message === "success") {
                setRefundId("")
                alert("Done");
                onSearch()
                renderTableBody(orderRecords)
            } else {
                console.log("fail")
                setRefundId("")
                alert("fail " + res.data.error);
            }
        }).catch(err => {
            setRefundId("")
            if (err.response.data.error == "something's' wrong") {
                alert(err.response.data.error)
            } else {
                console.log(err.response.data.error)
            }

        });
    }

    const handleDelete = (id, reason) => {
        console.log("send delete");

        let or = { id: id, reason: reason }
        axios.post("/api/orderRecord/deleteOrderRecord", { orderRecord: or }, {
            headers: {
                'Authorization': `Basic ${user.token}`
            }
        }).then(res => {
            console.log(res.status)
            if (res.data.message === "success") {
                setDeleteId("")
                alert("Done");
            } else {
                console.log("fail")
                setDeleteId("")
                alert("fail " + res.data.error);
            }
        }).catch(err => {
            setDeleteId("")
            if (err.response.data.error == "something's' wrong") {
                alert(err.response.data.error)
            } else {
                console.log(err.response.data.error)
            }

        });
    }



    const resetSearch = () => {
        window.location.href = "/admin/dailyReport";
    }

    const [refundId, setRefundId] = useState("")
    const [openRefund, setOpenRefund] = React.useState(false)

    let handleClickOpenRefund = (id) => {
        setOpenRefund(true)
        setRefundId(id)

    };

    let handleCloseRefund = () => {
        setOpenRefund(false)
    };

    let handleRefundCallback = (data) => {
        if (data.reason != null && data.reason != "") {
            handleRefund(data.id, data.type, data.method, data.price, data.reason)
            setOpenRefund(false)
        }

    }

    let refundDialog = <div>
        <Dialog
            open={openRefund}
            onClose={handleCloseRefund}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title" textAlign="center">
            </DialogTitle>
            <DialogContent>
                <RefundDialog callback={{ handleCallback: handleRefundCallback, handleCloseDetail: handleCloseRefund, id: refundId }} />
            </DialogContent>
        </Dialog>
    </div>

    const [deleteId, setDeleteId] = useState("")
    const [open, setOpen] = React.useState(false)

    let handleClickOpenDetail = (id) => {
        setOpen(true)
        setDeleteId(id)

    };

    let handleCloseDetail = () => {
        setOpen(false)
    };

    let handleCallback = (data) => {
        if (data.reason != null && data.reason != "") {
            handleDelete(data.id, data.reason)
            setOpen(false)
        }

    }

    let deleteDialog = <div>
        <Dialog
            open={open}
            onClose={handleCloseDetail}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title" textAlign="center">
            </DialogTitle>
            <DialogContent>
                <ConfirmationDialog callback={{ handleCallback: handleCallback, handleCloseDetail: handleCloseDetail, id: deleteId }} />
            </DialogContent>
        </Dialog>
    </div>


    return (
        <>
            <Breadcrumb />
            {deleteDialog}
            {refundDialog}
            <div>

                <h2>每日結算</h2>
                <div className='d-flex flex-column' >
                    <div className="card p-2 m-2">
                        <div className="row">
                            <div className="col-md-4">
                                <div className='datePicker'>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker
                                            inputFormat="DD/MM/YYYY"
                                            label="Report Date"
                                            value={reportDateValue}
                                            onChange={(newValue) => {
                                                setReportDateValue(newValue);
                                            }}
                                            renderInput={(params) => <TextField {...params} size="small" />}
                                        />
                                    </LocalizationProvider>
                                </div>
                            </div>
                            {/* <div className="col-md-4">

                        <div className="p-2">
                            <select className="form-select" aria-label="productType" value={productType} onChange={e => setProductType(e.target.value)}>
                                <option value="all"> 商品類型: 全部 </option>
                                <option value="booster">Booster</option>
                                <option value="stater">預組</option>
                                <option value="sleeve">卡套</option>
                                <option value="box">卡盒</option>
                                <option value="playmat">Playmat</option>
                                <option value="giftBox">禮盒</option>
                                <option value="sundry">雜項</option>
                                <option value="other">其他</option>
                            </select>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="p-2">
                            <select className="form-select" aria-label="productCat" value={productCat} onChange={e => setProductCat(e.target.value)}>
                                <option value="all"> 商品類別: 全部 </option>
                                <option value="PKM">PKM</option>
                                <option value="DGM">DGM</option>
                                <option value="YGO">YGO</option>
                                <option value="WS">WS</option>
                                <option value="SV">SV</option>
                                <option value="OP">One Piece</option>
                                <option value="DragonShield">龍盾</option>
                                <option value="other">其他</option>
                            </select>
                        </div>
                    </div> */}

                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="p-2">
                                    <button className="btn btn-warning" style={{ marginTop: "7px", width: "100%" }} onClick={e => resetSearch()}>重設</button>
                                    {/* {
                                    productType==='all' && orderStatus==='all' && (phone === '' || name === '')? 
                                        <button className="btn btn-warning" style={{marginTop: "7px", width:"100%"}}  disabled>重設</button> 
                                    :
                                    <button className="btn btn-warning" style={{marginTop: "7px", width:"100%"}}  onClick={e=>resetSearch()}>重設</button> 
                                } */}
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="p-2">
                                    <button className="btn btn-primary" style={{ marginTop: "7px", width: "100%" }} onClick={e => onSearch()} >搜尋</button>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="card p-2 m-2" >
                        {orderRecords.length > 0 ?
                            <div >
                                <table className="table table-hover" style={{overflow:"auto", maxHeight:"80vh", display:"block"}}>
                                    <thead className='stickyHead' style={{lineHeight:"100%"}}>
                                        <tr style={{maxHeight:"75px"}}>
                                            <th></th>
                                            <th scope="col" >#</th>
                                            <th scope="col" >時間</th>
                                            <th scope="col" onClick={() => sortOrders("type", "desc")}>{thType}</th>
                                            <th scope="col" onClick={() => sortOrders("productName", "desc")}>{thProductName}</th>
                                            <th scope="col" onClick={() => sortOrders("productCat", "desc")}>{thProductCat}</th>
                                            <th scope="col" onClick={() => sortOrders("productType", "desc")}>{thProductType}</th>
                                            <th scope="col" >Batch</th>
                                            <th scope="col" onClick={() => sortOrders("paymentMethod", "desc")} style={{ minWidth: "110px" }}>{thPaymentMethod}</th>
                                            <th scope="col" >付款狀態</th>
                                            <th scope="col" >價錢</th>
                                            <th scope="col" >數量</th>
                                            {/* <th scope="col" onClick={() => sortProducts("suggestedPrice", "desc")}>官方價</th> */}
                                            {/* <th scope="col" onClick={() => sortProducts("pccPrice", "desc")}>PCC價</th> */}
                                            <th scope="col" >經手人</th>
                                            <th scope="col" style={{ minWidth: "130px" }}>Claimed</th>
                                            <th scope="col" >賣家</th>
                                            {/* <th scope="col" onClick={() => sortProducts("createdDate", "desc")}>建立日期</th> */}
                                            {/* <th scope="col" onClick={() => sortProducts("modifiedDate", "desc")}>修改日期</th> */}
                                            <th scope="col" onClick={() => sortOrders("testDate", "desc")}>Remarks</th>
                                            <th scope="col">Save</th>
                                            <th scope="col">退貨/款</th>
                                            <th scope="col">Delete</th>
                                        </tr>
                                    </thead>
                                    {tableBody}
                                </table>
                            </div>
                            : clicked ? "搜尋先" : "冇記錄"


                        }
                    </div>
                    {orderRecords != null && orderRecords.length > 0 ?
                        <div className='card p-2 m-2'>
                            <div>今日結算 {new Date(orderRecords[0].orderdDate).getDate()+"/"+(new Date(orderRecords[0].orderdDate).getMonth()+1)}</div>
                            <hr />
                            <div>賣出</div>
                            <div className='m-2'>
                                <label style={{ width: "9%", textAlign: "center" }}>
                                    Cash ${sellCash}
                                </label>
                                <label style={{ width: "9%", textAlign: "center" }}>
                                    |
                                </label>
                                <label style={{ width: "9%", textAlign: "center" }}>
                                    Payme ${sellPayme}
                                </label>
                                <label style={{ width: "9%", textAlign: "center" }}>
                                    |
                                </label>
                                <label style={{ width: "9%", textAlign: "center" }}>
                                    FPS ${sellFps}
                                </label>
                                <label style={{ width: "10%", textAlign: "center" }}>
                                    |
                                </label>
                                <label style={{ width: "9%", textAlign: "center" }}>
                                    八達通 ${sellOctopus}
                                </label>
                                <label style={{ width: "9%", textAlign: "center" }}>
                                    |
                                </label>
                                <label style={{ width: "9%", textAlign: "center" }}>
                                    Yedpay ${sellYedpay}
                                </label>
                                <label style={{ width: "9%", textAlign: "center" }}>
                                    |
                                </label>
                                <label style={{ width: "9%", textAlign: "center" }}>
                                    total ${sellCash + sellFps + sellOctopus + sellPayme + sellYedpay}
                                </label>
                            </div>
                            <hr />
                            <div>買入</div>
                            <div className='m-2'>
                                <label style={{ width: "9%", textAlign: "center" }}>
                                    Cash ${buyCash}
                                </label>
                                <label style={{ width: "9%", textAlign: "center" }}>
                                    |
                                </label>
                                <label style={{ width: "9%", textAlign: "center" }}>
                                    Payme ${buyPayme}
                                </label>
                                <label style={{ width: "9%", textAlign: "center" }}>
                                    |
                                </label>
                                <label style={{ width: "9%", textAlign: "center" }}>
                                    FPS ${buyFps}
                                </label>
                                <label style={{ width: "10%", textAlign: "center" }}>
                                    |
                                </label>
                                <label style={{ width: "9%", textAlign: "center" }}>
                                    八達通 ${buyOctopus}
                                </label>
                                <label style={{ width: "9%", textAlign: "center" }}>
                                    |
                                </label>
                                <label style={{ width: "9%", textAlign: "center" }}>
                                    Yedpay ${buyYedpay}
                                </label>
                                <label style={{ width: "9%", textAlign: "center" }}>
                                    |
                                </label>
                                <label style={{ width: "9%", textAlign: "center" }}>
                                    total ${buyCash + buyFps + buyPayme + buyOctopus + buyYedpay}
                                </label>
                            </div>
                            <hr />
                            <div>total</div>
                            <div className='m-2'>
                                <label style={{ width: "9%", textAlign: "center" }}>
                                    Cash ${cash}
                                </label>
                                <label style={{ width: "9%", textAlign: "center" }}>
                                    |
                                </label>
                                <label style={{ width: "9%", textAlign: "center" }}>
                                    Payme ${payme}
                                </label>
                                <label style={{ width: "9%", textAlign: "center" }}>
                                    |
                                </label>
                                <label style={{ width: "9%", textAlign: "center" }}>
                                    FPS ${fps}
                                </label>
                                <label style={{ width: "10%", textAlign: "center" }}>
                                    |
                                </label>
                                <label style={{ width: "9%", textAlign: "center" }}>
                                    八達通 ${octopus}
                                </label>
                                <label style={{ width: "9%", textAlign: "center" }}>
                                    |
                                </label>
                                <label style={{ width: "9%", textAlign: "center" }}>
                                    Yedpay ${yedpay}
                                </label>
                                <label style={{ width: "9%", textAlign: "center" }}>
                                    |
                                </label>
                                <label style={{ width: "9%", textAlign: "center" }}>
                                    total ${cash + fps + payme + octopus + yedpay}
                                </label>
                            </div>
                            {/* {lastDailySummary==null || lastDailySummary=={}? null:
                    <>
                    <hr />
                    <div>上一次結算 {lastDailySummary.date}</div>
                    <div className='m-2'>
                        
                        <label style={{ width: "14%", textAlign: "center" }}>
                            Cash ${lastDailySummary.cash}
                        </label>
                        <label style={{ width: "14%", textAlign: "center" }}>
                            |
                        </label>
                        <label style={{ width: "14%", textAlign: "center" }}>
                            Payme ${lastDailySummary.payme}
                        </label>
                        <label style={{ width: "16%", textAlign: "center" }}>
                            |
                        </label>
                        <label style={{ width: "14%", textAlign: "center" }}>
                            FPS ${lastDailySummary.fps}
                        </label>
                        <label style={{ width: "14%", textAlign: "center" }}>
                            |
                        </label>
                        <label style={{ width: "14%", textAlign: "center" }}>
                            total ${lastDailySummary.total}
                        </label>
                    </div>
                    
                    </>
                } */}

                        </div>
                        : null}

                </div>
            </div>
        </>
    )
}

export default DailyReportScreen;