import React from 'react';
import { Link } from 'react-router-dom';
import Breadcrumb from '../components/Breadcrumb';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { QRCodeSVG } from 'qrcode.react';

const AdminScreen = () => {

    const user = JSON.parse(localStorage.getItem("user"));
    if (!user) {
        alert("please login")
        window.location.href = "/login";
    }else if((user.role.indexOf('admin') == -1 && user.role.indexOf('manager') == -1 && user.role.indexOf('shopkeeper') == -1 && user.role.indexOf('seller') == -1)){
        alert("unauthorised")
        localStorage.removeItem("user");
        window.location.href = "/login";
    }

    const [qrcodeList, setQrcodeList] = useState("");

    useEffect(() => {
        axios.get('/api/product/quickAccess', {
            headers: {
                'Authorization': `Basic ${user.token}`
            }
        }).then(res => {
            //setLoading(false);
            // console.log(res);
            if (res.data.products.length>0) {
                setQrcodeList(
                    
                    res.data.products.map((p, index) => {
                        return <div className="col-md-4 p-2">
                                <div className='card'>
                                    <div className='row'>
                                        <div className="col-md-8 p-2">
                                            <div style={{ height: "auto", margin: "0 auto", maxWidth: 350, minWidth: 200, width: "100%" }}>
                                                <QRCodeSVG
                                                    style={{ height: "auto", maxWidth: "100%", width: "100%", alignConten: "right" }}
                                                    value={"{\"productId\":\"" + p._id + "\", \"name\":\"" + p.name + "\", \"pccPrice\":" + p.pccPrice + "}"} />
                                            </div>
                                        </div>
                                        <div className="col-md-4 p-2">
                                            <h5>
                                                <div>
                                                    貨名：{p.name+" - batch "+p.batch}
                                                </div>
                                                <hr style={{marginRight:"5px"}}/>
                                                <div>
                                                    商品類型：{p.type}
                                                </div>
                                                <hr style={{marginRight:"5px"}}/>
                                                <div>
                                                    商品類別：{p.cat}
                                                </div>
                                                <hr style={{marginRight:"5px"}}/>
                                                <div>
                                                    上市日期：{formatDate(p.activeDate).split(',')[0]}
                                                </div>
                                                <hr style={{marginRight:"5px"}}/>
                                                <div>
                                                    剩餘數量：{p.stock}
                                                </div>
                                                <hr style={{marginRight:"5px"}}/>
                                                <div>
                                                    描述：{p.description}
                                                </div>
                                                <hr style={{marginRight:"5px"}}/>
                                            </h5>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                        
                    })
                    
                )
                
            }
        }).catch(err => {
            console.error(err);
            //setLoading(false);
            alert("好似有啲問題...");
        });
    }, [])

    const formatDate = (date) => {
        let formattedDate = new Date(date)
        return formattedDate.toLocaleString()
    }

    return (
        <>
        <Breadcrumb />
        <div className="container-fluid">

            
                <h2 >admin</h2>
                {(user.role.indexOf('admin') == -1 && user.role.indexOf('manager') == -1 && user.role.indexOf('shopkeeper') == -1) ? null:
                <div className='card m-2'>
                    <div className='card-title m-2'>
                        買賣
                    </div>
                    <div className='card-body'>
                        <div className="row">
                            <div className="col-md-2 p-2">
                                <Link to="/admin/newOrderRecord/sell">
                                    <div className="card p-2">
                                        賣出
                                    </div>
                                </Link>
                            </div>
                            <div className="col-md-2 p-2">
                                <Link to="/admin/newOrderRecord/buy">
                                    <div className="card p-2">
                                        買入
                                    </div>
                                </Link>
                            </div>
                            <div className="col-md-2 p-2">
                                <Link to="/admin/newPreorder">
                                    <div className="card p-2">
                                        新訂購
                                    </div>
                                </Link>
                            </div>
                            <div className="col-md-2 p-2">
                                <Link to="/admin/priceList">
                                    <div className="card p-2">
                                        價目表
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                }
                {(user.role.indexOf('admin') == -1 && user.role.indexOf('manager') == -1 && user.role.indexOf('shopkeeper') == -1) ? null:
                <div className='card m-2'>
                    <div className='card-title m-2'>
                        比賽
                    </div>
                    <div className='card-body'>
                        <div className="row">
                            <div className="col-md-2 p-2">
                                <Link to="/admin/competitionDetail/new" params={{ mode: "new", id:null }}>
                                    <div className="card p-2">
                                        新比賽
                                    </div>
                                </Link>
                            </div>
                            <div className="col-md-2 p-2">
                                <Link to="/admin/competitionRecord">
                                    <div className="card p-2">
                                        比賽紀錄
                                    </div>
                                </Link>
                            </div>
                            
                        </div>
                    </div>
                </div>
                }
                <div className='card m-2'>
                    <div className='card-title m-2'>
                        紀錄
                    </div>
                    <div className='card-body'>
                        <div className="row">
                            {(user.role.indexOf('admin') == -1 && user.role.indexOf('manager') == -1 && user.role.indexOf('shopkeeper') == -1) ? null:
                            <>
                            <div className="col-md-2 p-2">
                                <Link to="/admin/product">
                                    <div className="card p-2">
                                        貨物
                                    </div>
                                </Link>
                            </div>

                            

                            <div className="col-md-2 p-2">
                                <Link to="/admin/dailyReport">
                                    <div className="card p-2">
                                        每日結算
                                    </div>
                                </Link>
                            </div>
                            <div className="col-md-2 p-2">
                                <Link to="/admin/monthlyReport">
                                    <div className="card p-2">
                                        月結
                                    </div>
                                </Link>
                            </div>
                            </>
                            }
                            {(user.role.indexOf('admin') == -1 && user.role.indexOf('manager') == -1 && user.role.indexOf('seller') == -1) ? null:

                            <div className="col-md-2 p-2">
                                <Link to="/admin/consignmentRecord">
                                    <div className="card p-2">
                                        寄賣紀錄
                                    </div>
                                </Link>
                            </div>
                            }
                            
                        </div>
                    </div>
                </div>
                
                
                
                
                

                {(user.role.indexOf('admin') == -1 && user.role.indexOf('manager') == -1) ? null:
                <>
                
                {/* <div className="col-md-2 p-2">
                    <Link to="/admin/newPokemon">
                        <div className="card p-2">
                            New Pokemon
                        </div>
                    </Link>
                </div>

                <div className="col-md-2 p-2">
                    <Link to="/admin/newTrainer">
                        <div className="card p-2">
                            New Trainer
                        </div>
                    </Link>
                </div>

                <div className="col-md-2 p-2">
                    <Link to="/admin/newSeries">
                        <div className="card p-2">
                            New Series
                        </div>
                    </Link>
                </div> */}
                </>
                }
            </div>
            {(user.role.indexOf('admin') == -1 && user.role.indexOf('manager') == -1 && user.role.indexOf('shopkeeper') == -1) ? null:  
            <>
            
            <hr />
            <div className="row">
                <h2>最新上市</h2>
            </div>
            <div className="row">
                {qrcodeList}
            </div>
            </>
            }
        </>
    )
}

export default AdminScreen;