export const registerUserReducer = (state = {  }, action) => {

    switch (action.type) {
        case "MANAGER_REGISTER_REQUEST": return {
            ...state,
            loading: true
        };
        case "MANAGER_REGISTER_REQUEST_SUCCESS": return {
            ...state,
            success: true,
            loading: false
        }
        case "MANAGER_REGISTER_REQUEST_FAIL": return {
            ...state,
            error: true,
            loading: false
        }
        default: return state
    }
}

export const loginReducer = (state = {  }, action) => {

    switch (action.type) {
        case "LOGIN_REQUEST": return {
            ...state,
            loading: true
        };
        case "LOGIN_REQUEST_SUCCESS": return {
            ...state,
            success: true,
            loading: false
        }
        case "LOGIN_REQUEST_FAIL": return {
            ...state,
            error: true,
            loading: false
        }
        default: return state
    }
}