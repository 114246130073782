import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import '../css/alert.css';
import '../css/input.css';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


const PrizeDetailDialog = ({ callback }) => {

    const user = JSON.parse(localStorage.getItem("user"));
    if (!user || (user.role.indexOf('admin') == -1 && user.role.indexOf('manager') == -1 && user.role.indexOf('shopkeeper') == -1) || (user.role.indexOf('shopkeeper') >= 0)) {
        window.location.href = "/admin";
    }

    const [value, setValue] = React.useState(0);

    const [productName, setProductName] = useState("");

    const [cardName, setCardName] = useState("");

    const [productType, setProductType] = useState("");

    const [productCat, setProductCat] = useState("");

    const [products, setProducts] = useState([]);
    const [managers, setManagers] = useState([]);
    const [sellers, setSellers] = useState([]);

    const [productId, setProductId] = useState("");
    const [type, setType] = useState("");
    const [quantity, setQuantity] = useState(1);
    const [paymentMethod, setPaymentMethod] = useState("cash");
    const [price, setPrice] = useState(0);
    const [handler, setHandler] = useState("");
    const [seller, setSeller] = useState("");
    const [remarks, setRemarks] = useState("");
    const [paymentStatus, setPaymentStatus] = useState("");


    const [index, setIndex] = useState(-1);
    const prevIndex = useRef(index);

    const prevProducts = useRef(products);
    const prevProductType = useRef(productType);
    const prevProductCat = useRef(productCat);

    const prevValue = useRef(value);


    const [selectProduct, setSelectProduct] = useState();


    useEffect(() => {
        if (prevProducts.current !== products) {
            console.log("products " + products.length)
            prevProducts.current = products
            selectProductFunc(products)
        } else if (prevProductType.current !== productType) {
            console.log("productType " + productType)
            prevProductType.current = productType
            setProductId("")
            getProduct()
        } else if (prevProductCat.current !== productCat) {
            console.log("productCat " + productCat)
            prevProductCat.current = productCat
            setProductId("")
            getProduct()
        } else if (prevIndex.current !== index) {
            console.log("index " + index)
            setProductId(products[index]._id)
            setProductName(products[index].name)
            setPrice(products[index].pccPrice)
            prevIndex.current = index
        }
        if (managers.length == 0 && sellers.length == 0) {
            getUser("manager", "managers")
            getUser("seller", "sellers")
        }

    }, [products, productType, productCat, index])

    const getUser = (role, mode) => {
        let key = "";
        key = "role=" + role;
        request('/api/user/getUser?', key, mode)
    }

    const getProduct = () => {
        if (productCat != "" && productType != "") {
            request('/api/product/getProduct?', setKey(), "products")
        }
    }

    const setKey = () => {
        let key = ""
        key = "productType=" + productType + "&productCat=" + productCat;
        // console.log(key)
        return key;
    }

    const request = (url, key, mode) => {
        axios.get(url + key, {
            headers: {
                'Authorization': `Basic ${user.token}`
            }
        }).then(res => {
            console.log(res);
            if (mode == "products" && res.data.products != null) {
                setProducts(res.data.products)
            } else if (mode == "managers" && res.data.users != null) {
                setManagers(res.data.users)
            } else if (mode == "sellers" && res.data.users != null) {
                setSellers(res.data.users)
            }

        }).catch(err => {
            console.error(err);
            alert("好似有啲問題...");
        });
    }

    let testReturn = (product) => {
        callback.handleCallback(product);
        callback.handleCloseDetail()
    }



    let onUpload = (e) => {
        e.preventDefault();

        let canUpload = true;
        if (productId == "") {
            canUpload = false;
        }
        if (paymentMethod == "") {
            canUpload = false;
        } if (value == 1 && productName == "") {
            canUpload = false;
        }
        if (canUpload) {

            let sellRecord = {
                productId: productId,
                name: productName,
                type: productType,
                cat: productCat,
                handler: managers.find(function (item) {
                    if (item.username === "admin") {
                        return item._id;
                    }
                }),
                quantity: quantity,
                paymentMethod: "cash",
                paymentStatus: "全數",
                remarks: { remarks: "獎品 " + remarks }
            }
            if (value == 1) {
                sellRecord.seller= seller
                sellRecord.price=price
                sellRecord.remarks.cardName = productName
            }else{
                sellRecord.seller= managers.find(function (item) {
                    if (item.username === "admin") {
                        return item._id;
                    }
                })
                sellRecord.price=0;
            }
            testReturn(sellRecord)

        } else {
            alert("請正確輸入所有資料")
        }

    }

    const selectProductFunc = (pos) => {
        console.log(pos)
        if (pos == null) {
            setSelectProduct(<div>Please select the type and cat first</div>)
            resetSelectProduct()
        } else if (pos.length == 0) {
            setSelectProduct(<div>No product under this cat and type</div>)
            resetSelectProduct()
        } else {
            let count = -1
            setSelectProduct(<select className="form-select" aria-label="product" onChange={e => setIndex(e.target.value)}>
                <option value={-1} disabled selected="selected">請選擇貨品</option>
                {pos.map(product => {
                    count++
                    return <option value={count}>{product.name + " - bacth" + product.batch}</option>
                })}
            </select>)
        }
    }



    let handleBulkCardCatChange = (e) => {
        setProductId()
        if (e.target.value == "PKM") {
            setProductId("636a5fc7a488fe000bee30c4")
        } else if (e.target.value == "DGM") {
            setProductId("636a6106822d26552ca34110")
        } else if (e.target.value == "YGO") {
            setProductId("636a610e822d26552ca34111")
        } else if (e.target.value == "OP") {
            setProductId("63dc7965dee69c10f55c0062")
        } else if (e.target.value == "other") {
            setProductId("63dc799edee69c10f55c0063")
        }
    }

    const resetSelectProduct = () => {
        setProductId("")
        setProductName("")
        setPaymentStatus("")
        setCardName("")
        prevIndex.current = -1
        setIndex(-1)
    }


    // for tab changing
    const handleChange = (event, newValue) => {
        setValue(newValue);
        resetSelectProduct();
        if (newValue == 0) {
            setProductType("")
        } else {
            setProductType("bulkCard")
        }

    };




    return (
        <>
            <div className="container-fluid" style={{ alignContent: "center", marginTop: "5px" }}>
                <h2>獎品</h2>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                        <Tab label="貨" {...a11yProps(0)} />

                        <Tab label="散卡" {...a11yProps(1)} />

                    </Tabs>
                </Box>
                <TabPanel value={value} index={0}>
                    <div className="card p-2 m-2">
                        <div className="row">

                            <div className="col-md-4">

                                <div className="p-2">
                                    <select className="form-select" aria-label="productType" value={productType} onChange={e => setProductType(e.target.value)}>
                                        <option value="" disabled selected="selected"> 商品類型:</option>
                                        <option value="booster">Booster</option>
                                        <option value="stater">預組</option>
                                        <option value="sleeve">卡套</option>
                                        <option value="box">卡盒</option>
                                        <option value="playmat">Playmat</option>
                                        <option value="giftBox">禮盒</option>
                                        <option value="VJump">VJump</option>
                                        <option value="sundry">雜項</option>
                                        <option value="other">其他</option>
                                    </select>
                                </div>
                            </div>
                            {productType != "" ?
                                <div className="col-md-4">
                                    <div className="p-2">
                                        <select className="form-select" aria-label="productCat" value={productCat} onChange={e => setProductCat(e.target.value)}>
                                            <option value="" disabled selected="selected"> 商品類別</option>
                                            <option value="PKM">PKM</option>
                                            <option value="DGM">DGM</option>
                                            <option value="YGO">YGO</option>
                                            <option value="WS">WS</option>
                                            <option value="SV">SV</option>
                                            <option value="OP">One Piece</option>
                                            <option value="DragonShield">龍盾</option>
                                            <option value="King">King</option>
                                            <option value="RSS">RSS</option>
                                            <option value="KMC">KMC</option>
                                            <option value="Broccoli">Broccoli</option>
                                            <option value="other">其他</option>
                                        </select>
                                    </div>
                                </div>
                                : null}
                            {productCat != "" ?
                                <div className="col-md-4">
                                    <div className="p-2">
                                        {selectProduct}
                                    </div>
                                </div>
                                : null}

                        </div>

                    </div >
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <div className='card m-1'>
                        <div className='card-body'>
                            <div className="p-2">
                                <label style={{ width: "50%" }}>卡名</label>
                                <input type="text" class="form-control" value={productName} placeholder="卡名" onChange={e => setProductName(e.target.value)} />
                            </div>
                            <div className="p-2">
                                <label for="productCat" class="form-label">商品類別</label>
                                <div className="switch-field">
                                    <input type="radio" id="pkmRadio" name="catRadio" value="PKM" onChange={e => handleBulkCardCatChange(e)} />
                                    <label for="pkmRadio">PKM</label>
                                    <input type="radio" id="dgmRadio" name="catRadio" value="DGM" onChange={e => handleBulkCardCatChange(e)} />
                                    <label for="dgmRadio">DGM</label>
                                    <input type="radio" id="ygoRadio" name="catRadio" value="YGO" onChange={e => handleBulkCardCatChange(e)} />
                                    <label for="ygoRadio">YGO</label>
                                    <input type="radio" id="opRadio" name="catRadio" value="OP" onChange={e => handleBulkCardCatChange(e)} />
                                    <label for="opRadio">OP</label>
                                    <input type="radio" id="otherRadio" name="catRadio" value="other" onChange={e => handleBulkCardCatChange(e)} />
                                    <label for="otherRadio">other</label>
                                </div>
                            </div>
                            <hr />
                            <div className="p-2">
                                <label for="quantity" class="form-label">數量</label>
                                <div className='row'>
                                    <div className='col-md-9'>
                                        <input type="number" min="1" step="1" pattern="[0-9]*" inputmode="numeric" class="form-control" value={quantity} placeholder="數量" onChange={e => setQuantity(e.target.value)} />
                                    </div>
                                </div>
                            </div>
                            <hr />
                            <div className="p-2">
                                <label for="seller" class="form-label">賣家</label>
                                <select class="form-select" aria-label="seller" value={seller} onChange={e => setSeller(e.target.value)}>
                                    <option disabled value=""> 賣家 </option>
                                    {sellers.map(seller => {
                                        return <option value={seller._id}>{seller.name}</option>
                                    })}
                                </select>
                            </div>
                            <hr />
                            <div className="p-2">
                                <label for="price" class="form-label">價錢</label>
                                <input type="number" min="0.1" step="0.1" pattern="[0-9]*" inputmode="numeric" class="form-control" value={price} placeholder="價錢" onChange={e => setPrice(e.target.value)} />
                            </div>
                            <hr />
                            <div className="p-2">
                                <label for="remark" class="form-label">備註</label>
                                <textarea class="form-control" id="remark" rows="3" value={remarks} onChange={e => setRemarks(e.target.value)}></textarea>
                            </div>
                            <button style={{ width: "33%" }} type='submit' className="btn btn-success btn-large m-2" onClick={e => onUpload(e)}>Upload</button>

                        </div>

                    </div>



                    <br />
                </TabPanel>
                {productId != null && productId != "" && (value == 0) ?
                    <div className="card p-2 m-2">
                        <div className='card-body'>

                            <div className="p-2">
                                <label for="quantity" class="form-label">數量</label>
                                <div className='row'>
                                    <div className='col-md-9'>
                                        <input type="number" min="1" step="1" pattern="[0-9]*" inputmode="numeric" class="form-control" value={quantity} placeholder="數量" onChange={e => setQuantity(e.target.value)} />
                                    </div>
                                    {value == 0 ?
                                        <div className='col-md-3' style={{ marginTop: "10px", paddingTop: "6px" }}>
                                            <label class="form-label">剩餘數量: {products[index].stock}</label>
                                        </div>
                                        : null}
                                </div>
                            </div>


                            <hr />
                            <div className="p-2">
                                <label for="remark" class="form-label">備註</label>
                                <textarea class="form-control" id="remark" rows="3" value={remarks} onChange={e => setRemarks(e.target.value)}></textarea>
                            </div>
                            <hr />


                            <button style={{ width: "33%" }} type='submit' className="btn btn-success btn-large m-2" onClick={e => onUpload(e)}>Upload</button>

                        </div>

                    </div>
                    : null}
            </div>
        </>
    );
}

export default PrizeDetailDialog;
