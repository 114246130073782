import React, { useEffect, useState } from 'react';
import PokemonDetail from './PokemonDetail';
import TrainerDetail from './TrainerDetail';
import axios from 'axios';
import '../css/input.css';

import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';


const ProductDetailDialog = ({ req }) => {

    const user = JSON.parse(localStorage.getItem("user"));

    const [isEdit, setIsEdit] = useState(false);

    const [img, setImg] = useState("");

    const [name, setName] = useState("");

    const [type, setType] = useState("");

    const [cat, setCat] = useState("");

    const [batch, setBatch] = useState(0);

    const [stock, setStock] = useState(0);

    const [carton, setCarton] = useState(0);

    const [box, setBox] = useState(0);

    const [description, setDescription] = useState("");

    const [suggestedPrice, setSuggestedPrice] = useState(-1.0);

    const [pccPrice, setPccPrice] = useState(-1.0);

    const [isActive, setIsActive] = useState(false);

    const [typeMore, setTypeMore] = useState("");

    const [catMore, setCatMore] = useState("");

    const [uploadStatus, setUploadStatus] = React.useState(0);

    const [activeDate, setActiveDate] = React.useState();
    



    useEffect(() => {
        // console.log(req)
        if (req.mode == "new") {
            if (req.name != null && req.name != "") {
                setName(req.name)
            }
        } else {
            setIsEdit(true);
            setName(req.name);
            setType(req.type);
            setCat(req.cat);
            setBatch(req.batch);
            setStock(req.stock);
            setCarton(req.carton)
            setBox(req.box)
            setDescription(req.description);
            setSuggestedPrice(req.suggestedPrice);
            setPccPrice(req.pccPrice);
            setIsActive(req.isActive);
            setActiveDate(req.activeDate);
        }
    }, [])


    let onUpload = (e) => {
        e.preventDefault();
        let msg="";
        let canUpload = true;
        if (name == "") {
            canUpload = false;
            msg+="name "
        }
        if (type == "" || (type == "other" && typeMore == "")) {
            canUpload = false;
            msg+="type "
        }

        if (cat == "" || (cat == "other" && cat == "")) {
            canUpload = false;
            msg+="cat "
        }

        if (description == "" && (type == "booster" || type == "stater" || type == "giftBox")) {
            canUpload = false;
            msg+="description "
        }

        if (batch == 0 || batch == null || batch == "") {
            canUpload = false;
            msg+="batch "
        }
        if (pccPrice == -1.0) {
            canUpload = false;
            msg+="pccPrice "
        }
        if (suggestedPrice == -1.0) {
            canUpload = false;
            msg+="suggestedPrice "
        }
        if(type=="booster" && activeDate==null){
            canUpload = false;
            msg+="activeDate "
            if((carton==0 && box>0) || (carton>0 && box==0)){
                canUpload = false;
                msg+="unit "
            }
        }


        if (canUpload) {
            setUploadStatus(1)
            if (cat == "other") {
                setCat(cat + "-" + catMore)
            }
            if (type == "other") {
                setType(type + "-" + typeMore)
            }
            let product = {
                name: name,
                type: type,
                cat: cat,
                batch: batch,
                description: description,
                suggestedPrice: suggestedPrice,
                pccPrice: pccPrice,
                isActive: isActive,
                img: img
            }
            if(product.type=="booster"){
                // console.log(typeof(activeDate))
                try{
                    product.activeDate=Date.parse(activeDate.toISOString());
                }catch{
                    product.activeDate=Date.parse(activeDate);
                }
                if(carton>0 && box>0){
                    product.unit={
                        carton:carton,
                        box:box
                    }
                }
                
            }
            

            if (isEdit) {
                console.log("send edit");
                product.stock=stock
                product.productId=req.id
                axios.post("/api/product/editProduct", { product }, {
                    headers: {
                        'Authorization': `Basic ${user.token}`
                    }
                }).then(res => {
                    if (res.data.message === "success") {
                        alert("Edit Success");
                        setUploadStatus(2)
                    } else {
                        alert("fail " + res.data.error);
                        setUploadStatus(0)
                    }
                }).catch(err => {
                    setUploadStatus(0)
                    alert("fail " + err.response.data.error);
                });
                
            } else {
                console.log("send create");
                axios.post("/api/product/newProduct", product, {
                    headers: {
                        'Authorization': `Basic ${user.token}`
                    }
                }).then(res => {
                    if (res.data.message == "success") {
                        alert("Upload Success");
                        setUploadStatus(2)
                    } else {
                        setUploadStatus(0)
                        alert("fail " + res.data.error);
                    }
                }).catch(err => {
                    setUploadStatus(0)
                    alert("fail " + err.response.data.error);
                });
            }

        } else {
            alert("請正確輸入所有資料\n"+msg)
        }

    }

    const handleToggle = (e) => {
        if (e.target.checked) {
            setIsActive(true)
        } else {
            setIsActive(false)
        }
    }

    const getBase64 = file => {
        return new Promise(resolve => {
            let fileInfo;
            let baseURL = "";
            // Make new FileReader
            let reader = new FileReader();

            // Convert the file to base64 text
            reader.readAsDataURL(file);

            // on reader load somthing...
            reader.onload = () => {
                // Make a fileInfo Object
                //console.log("Called", reader);
                baseURL = reader.result;
                //console.log(baseURL);
                resolve(baseURL);
            };

        });
    };

    const fileExt = (e) => {
        //for getting extension
        if (!e || !e.target || !e.target.files || e.target.files.length === 0) {
            return null;
        }

        const name = e.target.files[0].name;
        const lastDot = name.lastIndexOf('.');

        const fileName = name.substring(0, lastDot);
        const ext = name.substring(lastDot + 1);

        return ext;
    }

    const handleImg = (e) => {

        let ext = fileExt(e);

        // console.log(ext)
        if (ext != null) {
            // resizeFile(e.target.files[0])
            getBase64(e.target.files[0]).then(fileInfo => {
                setImg(fileInfo);
            });
        }
    }

    return (
        <div className="card m-2">
            <div className='card-title m-2'>
                {req.mode == "edit" ?
                    <h2>修改貨物資料</h2>
                    : <h2>新增貨物資料</h2>}
            </div>

            <div className='card-body'>
                <hr />
                <div className="  p-2">
                    <label for="img" class="form-label">圖</label>
                    <input type="file" class="form-control" id="img" placeholder="圖" accept=".jpg, .jpeg, .png" max="1" onChange={e => handleImg(e)} />
                </div>
                <hr />
                <div className="p-2">
                    <label for="name" class="form-label">貨名</label>
                    <input type="text" class="form-control" value={name} placeholder="貨名" onChange={e => setName(e.target.value)} />
                </div>
                <hr />
                <div className="p-2">
                    <label for="type" class="form-label">商品類型</label>
                    <select className="form-select" aria-label="productType" value={type} onChange={e => setType(e.target.value)}>
                        <option value="all"> 商品類型: 全部 </option>
                        <option value="booster">Booster</option>
                        <option value="stater">預組</option>
                        <option value="bulkCard">散卡</option>
                        <option value="sleeve">卡套</option>
                        <option value="box">卡盒</option>
                        <option value="playmat">Playmat</option>
                        <option value="giftBox">禮盒</option>
                        <option value="VJump">VJump</option>
                        <option value="sundry">雜項</option>
                        <option value="other">其他</option>
                    </select>
                    {type.length >= 5 && type.substring(0, 5) == "other" ?
                        <div>
                            <label for="more" class="form-label">其他</label>
                            <input type="text" class="form-control" value={typeMore} placeholder="其他" onChange={e => setTypeMore(e.target.value)} />
                        </div> : null
                    }
                </div>
                <hr />
                <div className="p-2">
                    <label for="cat" class="form-label">商品類別</label>
                    <select class="form-select" aria-label="productType" value={cat} onChange={e => setCat(e.target.value)}>
                        <option disabled value=""> 商品類別 </option>
                        <option value="PKM">PKM</option>
                        <option value="DGM">DGM</option>
                        <option value="YGO">YGO</option>
                        <option value="WS">WS</option>
                        <option value="SV">SV</option>
                        <option value="OP">One Piece</option>
                        <option value="DragonShield">龍盾</option>
                        <option value="King">King</option>
                        <option value="RSS">RSS</option>
                        <option value="KMC">KMC</option>
                        <option value="Broccoli">Broccoli</option>
                        <option value="UG">UG</option>
                        <option value="other">其他</option>
                    </select>
                    {cat.length >= 5 && cat.substring(0, 5) == "other" ?
                        <div>
                            <label for="more" class="form-label">其他</label>
                            <input type="text" class="form-control" value={catMore} placeholder="其他" onChange={e => setCatMore(e.target.value)} />
                        </div> : null
                    }
                </div>
                <hr />
                <div className="p-2">
                    <label for="batch" class="form-label">Batch</label>
                    <input type="number" min="1" step="1" pattern="[0-9]*" inputmode="numeric" class="form-control" value={batch} placeholder="Batch" onChange={e => setBatch(e.target.value)} />
                </div>
                <hr />
                <div className="p-2">
                    <label for="suggestedPrice" class="form-label">建議零售價</label>
                    <input type="number" min="0.1" step="0.1" pattern="[0-9]*" inputmode="numeric" class="form-control" value={suggestedPrice} placeholder="官方價" onChange={e => setSuggestedPrice(e.target.value)} />
                </div>
                <hr />
                <div className="p-2">
                    <label for="pccPrice" class="form-label">PCC價</label>
                    <input type="number" min="0.1" step="0.1" pattern="[0-9]*" inputmode="numeric" class="form-control" value={pccPrice} placeholder="PCC價" onChange={e => setPccPrice(e.target.value)} />
                </div>
                {isEdit? <>
                    <hr />
                    <div className="p-2">
                        <label for="pccPrice" class="form-label">貨量</label>
                        <input type="number" step="1" pattern="[0-9]*" inputmode="numeric" class="form-control" value={stock} placeholder="貨量" onChange={e => setStock(e.target.value)} disabled/>
                    </div>
                </>
                : null}
                <hr />
                {type=="booster" ? 
                <>
                <div className="p-2">
                    <label for="unit" class="form-label">單位</label>
                    <div id="unit" className='row'>
                        <div className='col'>
                            <label for="pccPrice" class="form-label">一箱幾盒</label>
                            <input type="number" step="1" pattern="[0-9]*" inputmode="numeric" class="form-control" value={carton} placeholder="一箱幾盒" onChange={e => setCarton(e.target.value)} />
                        </div>
                        <div className='col'>
                            <label for="pccPrice" class="form-label">一盒幾包</label>
                            <input type="number" step="1" pattern="[0-9]*" inputmode="numeric" class="form-control" value={box} placeholder="一盒幾包" onChange={e => setBox(e.target.value)} />
                        </div>
                    </div>
                </div>
                <hr />
                </>
                : null}
                
                <div className="p-2">
                    <label for="description" class="form-label">描述</label>
                    <textarea class="form-control" id="description" rows="3" value={description} onChange={e => setDescription(e.target.value)}></textarea>
                </div>
                <hr />
                <div className="p-2">
                    <label for="isActive" class="form-label">上架</label>
                    <br />
                    <label class="switch">
                        <input type="checkbox" checked={isActive} onChange={e => handleToggle(e)} />
                        <span class="slider round"></span>
                    </label>
                </div>
                <hr />
                {type=="booster" ? <div className="p-2">
                    <label for="isActive" class="form-label">上架日期</label>
                    <br />
                    <div className='datePicker'>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    inputFormat="DD/MM/YYYY"
                                    label="上架日期"
                                    value={activeDate}
                                    onChange={(newValue) => {
                                        setActiveDate(newValue);
                                    }}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </LocalizationProvider>
                        </div>
                </div> 
                : null}
                
                <hr />
                {uploadStatus==0 ?
                    <button type='submit' className="btn btn-success btn-large m-2" onClick={e => onUpload(e)}>Upload</button>
                : uploadStatus == 1 ?
                    <button type='submit' className="btn btn-secondary btn-large m-2" disabled >Uploading...</button>
                :uploadStatus == 2 ?
                    <button type='submit' className="btn btn-primary btn-large m-2" disabled >Upload Success</button>
                :null}
                

            </div>

        </div>
    );
}

export default ProductDetailDialog;
