import React from 'react';
import axios from 'axios';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { KeyboardCapslockSharp, Receipt } from '@mui/icons-material';
import { height } from '@mui/system';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import ProductDetailDialog from '../components/ProductDetailDialog';
import '../css/input.css';
import Breadcrumb from '../components/Breadcrumb';

const ConsignmentRecordScreen = () => {

    const user = JSON.parse(localStorage.getItem("user"));

    if (!user || (user.role.indexOf('admin') == -1 && user.role.indexOf('manager') == -1 && user.role.indexOf('seller') == -1)) {
        window.location.href = "/admin";
    }

    const [productName, setProductName] = useState("");

    const [productType, setProductType] = useState("all");

    const [productCat, setProductCat] = useState("all");

    const [isActive, setIsActive] = useState("all");

    const [clicked, setClicked] = useState(true);

    const [orderRecords, setOrderRecords] = useState([]);

    const [open, setOpen] = React.useState(false);

    const [openDetail, setOpenDetail] = React.useState(false);

    const [selectedPreorder, setSelectedPreorder] = React.useState([]);

    const [productDetail, setProductDetail] = React.useState({});

    const [tableBody, setTableBody] = React.useState();

    const [orderCount, setOrderCount] = React.useState(0);

    const [orderBy, setOrderBy] = React.useState(0);

    const [reportDate, setReportDate] = React.useState("")

    const [reportStartDateValue, setReportStartDateValue] = React.useState(addMonths(new Date().toISOString().split('T')[0], -1));
    const [reportEndDateValue, setReportEndDateValue] = React.useState(new Date(new Date().toISOString().split('T')[0]));

    const [cash, setCash] = React.useState(0);
    const [payme, setPayme] = React.useState(0);
    const [fps, setFps] = React.useState(0);

    const [lastCash, setLastCash] = React.useState(0);
    const [lastPayme, setLastPayme] = React.useState(0);
    const [lastFps, setLastFps] = React.useState(0);

    const [lastDailySummary, setLastDailySummary] = React.useState({});

    const [sellerId, setSellerId] = React.useState("all");
    const [seller, setSeller] = React.useState("");
    const [sellers, setSellers] = React.useState([]);
    // let selectedPreorder=[];

    const [sellerCommission, setSellerCommission] = React.useState(0)
    const [shopCommission, setShopCommission] = React.useState(0)
    const [orderTotal, setOrderTotal] = React.useState(0)

    const [cashSellerCommission, setCashSellerCommission] = React.useState(0)
    const [fpsSellerCommission, setFpsSellerCommission] = React.useState(0)
    const [paymeSellerCommission, setPaymeSellerCommission] = React.useState(0)

    const [cashShopCommission, setCashShopCommission] = React.useState(0)
    const [fpsShopCommission, setFpsShopCommission] = React.useState(0)
    const [paymeShopCommission, setPaymeShopCommission] = React.useState(0)


    const [init, setInit] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false);

    useEffect(() => {
        // request(setKey())
        if (orderRecords.length > 0) {
            renderTableBody(orderRecords)
        }
        if (!init) {
            //user.role.indexOf('admin') < 0 && user.role.indexOf('manager') < 0 && user.role.indexOf('seller') >= 0
            if (user.role.indexOf('admin') >= 0 || user.role.indexOf('manager') >= 0) {
                request('/api/user/getUser?', "role=seller", "sellers")
            }
            setInit(true)
        }

    }, [orderRecords]);


    const onSearch = () => {
        // match.params.phone=null;
        setClicked(false)
        request('/api/orderRecord/getConsignmentRecord?', setKey(), 'report')

    }

    function addMonths(date, months) {
        let d = new Date(date)
        d.setMonth(d.getMonth() + months);

        return d;
    }

    const setKey = () => {
        let key = "";

        if (reportStartDateValue != null && reportStartDateValue != "") {

            console.log(reportStartDateValue.toISOString().split('T')[0])
            if (key != "") {
                key += "&"
            }
            key += "startDate=" + Date.parse(reportStartDateValue.toISOString().split('T')[0]);
        } else {
            key += "startDate=" + Date.parse(new Date().toISOString().split('T')[0]);
        }
        if (reportEndDateValue != null && reportEndDateValue != "") {
            console.log(reportEndDateValue.toISOString().split('T')[0])
            if (key != "") {
                key += "&"
            }
            key += "endDate=" + Date.parse(reportEndDateValue.toISOString().split('T')[0]);
        } else {
            key += "endDate=" + Date.parse(addMonths(new Date().toISOString().split('T')[0], 1));
        }
        // if (productName != "") {
        //     if (key != "") {
        //         key += "&"
        //     }
        //     key += "productName=" + productName;
        // }

        // if (productType != "all") {
        //     if (key != "") {
        //         key += "&"
        //     }
        //     key += "productType=" + productType;
        // }

        // if (productCat != "all") {
        //     if (key != "") {
        //         key += "&"
        //     }
        //     key += "productCat=" + productCat;
        // }

        if (sellerId != null && sellerId != "") {
            if (key != "") {
                key += "&"
            }
            key += "sellerId=" + sellerId;
        }

        // console.log(key)
        return key;
    }

    const selected = (e) => {
        let array = selectedPreorder
        if (e.target.checked) {
            array.push(e.target.value)
        } else {
            var i = array.indexOf(e.target.value);
            array.splice(i, 1);
        }
        setSelectedPreorder(array);
        console.log(selectedPreorder);

    }

    const request = (url, key, mode) => {
        setIsLoading(true)
        axios.get(url + key, {
            headers: {
                'Authorization': `Basic ${user.token}`
            }
        }).then(res => {
            // setLoading(false);
            // console.log(res);
            setIsLoading(false)
            if (mode == 'report') {
                if (res.data.consignmentRecord != null) {
                    setOrderRecords(res.data.consignmentRecord);
                } else {
                    setOrderRecords([])
                }
            } else if (mode == 'sellers') {
                if (res.data.users != null) {
                    setSellers(res.data.users)
                } else {
                    setSellers([])
                }

            }




        }).catch(err => {
            console.error(err);
            // setLoading(false);
            alert("好似有啲問題...");
        });
    }



    const formatDate = (date) => {
        let formattedDate = new Date(date)
        return formattedDate.toLocaleString()
    }

    const [thSeller, setThSeller] = useState("賣家↑↓")
    const [thProductCat, setThProductCat] = useState("商品類別↑↓")
    const [thProductType, setThProductType] = useState("商品類型↑↓")
    const [thPaymentMethod, setThPaymentMethod] = useState("付款方式↑↓")

    const sortOrders = (value) => {

        const tmpSeller = ["賣家↑↓", "賣家↑", "賣家↓"]
        const productCat = ["商品類別↑↓", "商品類別↑", "商品類別↓"]
        const productType = ["商品類型↑↓", "商品類型↑", "商品類型↓"]
        const paymentMethod = ["付款方式↑↓", "付款方式↑", "付款方式↓"]


        if (value == orderBy && orderCount < 2) {
            setOrderCount(orderCount + 1);
        } else {
            setOrderCount(1);
        }

        if (value == "sellerName") {
            setThSeller(tmpSeller[orderCount % 3])
            setThProductCat(productCat[0])
            setThProductType(productType[0])
            setThPaymentMethod(paymentMethod[0])
        } else if (value == "productCat") {
            setThSeller(tmpSeller[0])
            setThProductCat(productCat[orderCount % 3])
            setThProductType(productType[0])
            setThPaymentMethod(paymentMethod[0])
        } else if (value == "productType") {
            setThSeller(tmpSeller[0])
            setThProductCat(productCat[0])
            setThProductType(productType[orderCount % 3])
            setThPaymentMethod(paymentMethod[0])
        } else if (value == "paymentMethod") {
            setThSeller(tmpSeller[0])
            setThProductCat(productCat[0])
            setThProductType(productType[0])
            setThPaymentMethod(paymentMethod[orderCount % 3])
        }

        let sorted = orderRecords.sort(function (a, b) {
            if (orderCount % 3 == 2) {
                return a[value] < b[value] ? 1 : -1;
            } else if (orderCount % 3 == 1) {
                return a[value] > b[value] ? 1 : -1;
            }

        });
        console.log(sorted)
        setOrderRecords(sorted);
        setOrderBy(value)
        renderTableBody(orderRecords);
    }



    const renderTableBody = (pos) => {
        let newSellerCommission = 0
        let newShopCommission = 0
        let orderTotal = 0

        let newCashSellerCommission = 0
        let newFpsSellerCommission = 0
        let newPaymeSellerCommission = 0

        let newCashShopCommission = 0
        let newFpsShopCommission = 0
        let newPaymeShopCommission = 0

        let newCash = 0
        let newFps = 0
        let newPayme = 0


        setTableBody(<tbody>
            {pos.map((orderRecord, index) => {
                let tmpSellerCommission = orderRecord.price * (1 - (orderRecord.sellerCommission / 100))
                let tmpShopCommission = orderRecord.price * (orderRecord.sellerCommission / 100)

                newSellerCommission += tmpSellerCommission
                newShopCommission += tmpShopCommission
                orderTotal += orderRecord.price

                let trStyle = {}
                if(user.role.indexOf('admin') >= 0 || user.role.indexOf('manager') >= 0){
                    if (orderRecord.paymentMethod == "cash") {
                        newCashSellerCommission += tmpSellerCommission
                        newCashShopCommission += tmpShopCommission
                        newCash += orderRecord.price
                        trStyle["background-color"] = "#d9ead3"
                    } else if (orderRecord.paymentMethod == "fps") {
                        newFpsSellerCommission += tmpSellerCommission
                        newFpsShopCommission += tmpShopCommission
                        newFps += orderRecord.price
                        trStyle["background-color"] = "#cfe2f3"
                    } else if (orderRecord.paymentMethod == "payme") {
                        newPaymeSellerCommission += tmpSellerCommission
                        newPaymeShopCommission += tmpShopCommission
                        newPayme += orderRecord.price
                        trStyle["background-color"] = "#f4cccc"
                    }
                }

                return <tr style={trStyle}>
                    <th><input type="checkbox" id="_id" value={orderRecord._id} onChange={e => selected(e)} /></th>
                    <th scope="row">{index + 1}</th>
                    <td>{formatDate(orderRecord.orderdDate)}</td>
                    {user.role.indexOf('admin') >= 0 || user.role.indexOf('manager') >= 0 ?
                    <td>{orderRecord.sellerName}</td>
                    : null}
                    {orderRecord.productType == "bulkCard" ?
                        <td>{orderRecord.remarks.cardName}</td>
                        :
                        <td>{orderRecord.productName}</td>
                    }
                    <td>{orderRecord.productCat}</td>
                    {user.role.indexOf('admin') >= 0 || user.role.indexOf('manager') >= 0 ?
                    <>
                    {orderRecord.productType == "bulkCard" ?
                        <td>{orderRecord.productName}</td>
                        :
                        <td>{orderRecord.productType}</td>
                    }

                    <td>{orderRecord.paymentMethod}</td>
                    </> : null}
                    <td>{orderRecord.quantity}</td>
                    <td>${orderRecord.price}</td>
                    {user.role.indexOf('admin') >= 0 || user.role.indexOf('manager') >= 0 ?
                    <>
                    <td>${tmpSellerCommission}</td>
                    <td>${tmpShopCommission.toFixed(2)}</td>

                    <td>{orderRecord.remarks.remarks}</td>
                    </> :null}
                </tr>
            })}
        </tbody>)
        setSellerCommission(newSellerCommission)
        setShopCommission(newShopCommission)
        setOrderTotal(orderTotal)

        setCashSellerCommission(newCashSellerCommission)
        setCashShopCommission(newCashShopCommission)
        setCash(newCash)

        setPaymeSellerCommission(newPaymeSellerCommission)
        setPaymeShopCommission(newPaymeShopCommission)
        setPayme(newPayme)

        setFpsSellerCommission(newFpsSellerCommission)
        setFpsShopCommission(newFpsShopCommission)
        setFps(newFps)

    }

    const onRemarksChange = (index) => e => {
        let newOrderRecords = orderRecords
        newOrderRecords[index].remarks.remarks = e.target.value
        setOrderRecords(newOrderRecords)
        renderTableBody(orderRecords);
    }

    const handleSave = (id, value) => {
        console.log(id + " " + value)
        console.log("send update remarks");
        let or = { id: id, remarks: value }
        axios.post("/api/orderRecord/editOrderRecordRemarks", { orderRecord: or }, {
            headers: {
                'Authorization': `Basic ${user.token}`
            }
        }).then(res => {
            console.log(res.status)
            if (res.data.message === "success") {
                alert("Done");
            } else {
                console.log("fail")
                alert("fail " + res.data.error);
            }
        }).catch(err => {
            if (err.response.data.error == "something's' wrong") {
                alert(err.response.data.error)
            } else {
                console.log(err.response.data.error)
            }
        });
    }



    const resetSearch = () => {
        window.location.href = "/admin/product";
    }

    return (
        <>
            <Breadcrumb />
            <div className="container-fluid">

                {/* {productDetailDialog} */}
                <h2>寄賣紀錄</h2>

                <div className="card p-2 m-2">
                    <div className="row">
                        <div className="col-md-4">
                            <div className='datePicker'>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        inputFormat="DD/MM/YYYY"
                                        label="Start Date"
                                        value={reportStartDateValue}
                                        onChange={(newValue) => {
                                            setReportStartDateValue(newValue);
                                        }}
                                        renderInput={(params) => <TextField {...params} size="small" />}
                                    />
                                </LocalizationProvider>
                            </div>

                        </div>
                        <div className="col-md-4">
                            <div className='datePicker'>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        inputFormat="DD/MM/YYYY"
                                        label="End Date"
                                        value={reportEndDateValue}
                                        onChange={(newValue) => {
                                            setReportEndDateValue(newValue);
                                        }}
                                        renderInput={(params) => <TextField {...params} size="small" />}
                                    />
                                </LocalizationProvider>
                            </div>

                        </div>
                        {user.role.indexOf('admin') >= 0 || user.role.indexOf('manager') >= 0 ?
                        <div className="col-md-4">

                            <div className="p-2">
                                <select class="form-select" aria-label="seller" value={sellerId} onChange={e => setSellerId(e.target.value)}>
                                    <option value="all" > 賣家: 全部 </option>
                                    {sellers.map(seller => {
                                        return <option value={seller._id}>{seller.name}</option>
                                    })}
                                </select>
                            </div>
                        </div>
                        : null}


                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="p-2">
                                <button className="btn btn-warning" style={{ marginTop: "7px", width: "100%" }} onClick={e => resetSearch()}>重設</button>
                                {/* {
                                    productType==='all' && orderStatus==='all' && (phone === '' || name === '')? 
                                        <button className="btn btn-warning" style={{marginTop: "7px", width:"100%"}}  disabled>重設</button> 
                                    :
                                    <button className="btn btn-warning" style={{marginTop: "7px", width:"100%"}}  onClick={e=>resetSearch()}>重設</button> 
                                } */}
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="p-2">
                                <button className="btn btn-primary" style={{ marginTop: "7px", width: "100%" }} onClick={e => onSearch()} >搜尋</button>
                            </div>
                        </div>

                    </div>
                </div>
                <div className="card p-2 m-2" >
                    {isLoading ? "loading..."
                        : orderRecords.length > 0 ?
                            <div style={{ display: "flex", overflow: "auto" }}>
                                <table className="table table-hover" >
                                    <thead >
                                        <tr>
                                            <th></th>
                                            <th scope="col">#</th>
                                            <th scope="col">日期</th>
                                            {user.role.indexOf('admin') >= 0 || user.role.indexOf('manager') >= 0 ?
                                            <th scope="col" onClick={() => sortOrders("sellerName", "desc")}>{thSeller}</th>
                                            : null}
                                            <th scope="col" >貨名</th>
                                            <th scope="col" onClick={() => sortOrders("productCat", "desc")}>{thProductCat}</th>
                                            {user.role.indexOf('admin') >= 0 || user.role.indexOf('manager') >= 0 ?
                                            <>
                                            <th scope="col" onClick={() => sortOrders("productType", "desc")}>{thProductType}</th>
                                            {/* <th scope="col" >Batch</th> */}
                                            <th scope="col" onClick={() => sortOrders("paymentMethod", "desc")}>{thPaymentMethod}</th>
                                            {/* <th scope="col" >付款狀態</th> */}
                                            </> : null}
                                            <th scope="col" >數量</th>
                                            <th scope="col" >價錢</th>
                                            {user.role.indexOf('admin') >= 0 || user.role.indexOf('manager') >= 0 ?
                                            <>
                                            <th scope="col" >賣家落袋</th>
                                            <th scope="col" >鋪頭分成</th>
                                            {/* <th scope="col" onClick={() => sortProducts("suggestedPrice", "desc")}>官方價</th> */}
                                            {/* <th scope="col" onClick={() => sortProducts("pccPrice", "desc")}>PCC價</th> */}
                                            {/* <th scope="col" >經手人</th> */}
                                            {/* <th scope="col" onClick={() => sortProducts("createdDate", "desc")}>建立日期</th> */}
                                            {/* <th scope="col" onClick={() => sortProducts("modifiedDate", "desc")}>修改日期</th> */}
                                            <th scope="col">Remarks</th>
                                            {/* <th scope="col">Save</th> */}
                                            </> : null}
                                        </tr>
                                    </thead>
                                    {tableBody}
                                </table>
                            </div>
                            : clicked ? "搜尋先"
                                : "冇記錄"


                    }
                    {orderRecords != null && orderRecords.length > 0 ?
                        <>
                            <hr />
                            <div>結算 {lastDailySummary.date}</div>
                            {user.role.indexOf('admin') >= 0 || user.role.indexOf('manager') >= 0 ?
                            <>
                            <div className='card  m-1' style={{ backgroundColor: "#d9ead3" }}>Cash:
                                <div className='m-2'>
                                    <label style={{ width: "20%", textAlign: "center" }}>
                                        銷售總額 ${cash.toFixed(2)}
                                    </label>
                                    <label style={{ width: "20%", textAlign: "center" }}>
                                        |
                                    </label>
                                    <label style={{ width: "20%", textAlign: "center" }}>
                                        賣家落袋 ${cashSellerCommission.toFixed(2)}
                                    </label>
                                    <label style={{ width: "20%", textAlign: "center" }}>
                                        |
                                    </label>
                                    <label style={{ width: "20%", textAlign: "center" }}>
                                        鋪頭分成 ${cashShopCommission.toFixed(2)}
                                    </label>
                                </div>
                            </div>
                            <hr />
                            <div className='card  m-1' style={{ backgroundColor: "#cfe2f3" }}>
                                FPS:
                                <div className='m-2'>
                                    <label style={{ width: "20%", textAlign: "center" }}>
                                        銷售總額 ${fps.toFixed(2)}
                                    </label>
                                    <label style={{ width: "20%", textAlign: "center" }}>
                                        |
                                    </label>
                                    <label style={{ width: "20%", textAlign: "center" }}>
                                        賣家落袋 ${fpsSellerCommission.toFixed(2)}
                                    </label>
                                    <label style={{ width: "20%", textAlign: "center" }}>
                                        |
                                    </label>
                                    <label style={{ width: "20%", textAlign: "center" }}>
                                        鋪頭分成 ${fpsShopCommission.toFixed(2)}
                                    </label>
                                </div>
                            </div>
                            <hr />
                            <div className='card m-1' style={{ backgroundColor: "#f4cccc" }}>Payme:
                                <div className='m-2'>
                                    <label style={{ width: "20%", textAlign: "center" }}>
                                        銷售總額 ${payme.toFixed(2)}
                                    </label>
                                    <label style={{ width: "20%", textAlign: "center" }}>
                                        |
                                    </label>
                                    <label style={{ width: "20%", textAlign: "center" }}>
                                        賣家落袋 ${paymeSellerCommission.toFixed(2)}
                                    </label>
                                    <label style={{ width: "20%", textAlign: "center" }}>
                                        |
                                    </label>
                                    <label style={{ width: "20%", textAlign: "center" }}>
                                        鋪頭分成 ${paymeShopCommission.toFixed(2)}
                                    </label>
                                </div>
                            </div>
                            <hr />
                            </>
                            : null}
                            <div>total: </div>
                            <div className='m-2'>
                                <label style={{ width: "20%", textAlign: "center" }}>
                                    銷售總額 ${orderTotal.toFixed(2)}
                                </label>
                                <label style={{ width: "20%", textAlign: "center" }}>
                                    |
                                </label>
                                <label style={{ width: "20%", textAlign: "center" }}>
                                    賣家落袋 ${sellerCommission.toFixed(2)}
                                </label>
                                <>
                                <label style={{ width: "20%", textAlign: "center" }}>
                                    |
                                </label>
                                <label style={{ width: "20%", textAlign: "center" }}>
                                    鋪頭分成 ${shopCommission.toFixed(2)}
                                </label>
                                </>
                            </div>

                            <hr />


                        </>
                        : null}

                </div>


            </div>
        </>
    )
}

export default ConsignmentRecordScreen;