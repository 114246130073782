import { combineReducers } from "redux";
import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from "redux-thunk";
import { registerUserReducer, loginReducer } from "./reducers/UserReducer";
import { getAllSeriesReducer } from "./reducers/SeriesReducer";
import { getAllCardsBySeriesReducer } from "./reducers/CardReducer";

const finalReducers = combineReducers({
    registerUserReducer: registerUserReducer,
    loginReducer: loginReducer,
    getAllSeriesReducer: getAllSeriesReducer,
    getAllCardsBySeriesReducer: getAllCardsBySeriesReducer
})

const user = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")): null  ;

const initialState = {
    loginReducer: {user: user}
}

if(user && (new Date > new Date(user.expiresIn))){
    // console.log("login expired");
    // alert("Please login again");
    localStorage.removeItem("user");
    window.location.href = "/login";
}

const composeEnhancers = composeWithDevTools({
    // Specify here name, actionsBlacklist, actionsCreators and other options
});

const store = createStore(finalReducers, composeEnhancers(
    applyMiddleware(thunk)
))

export default store;