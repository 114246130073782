import React, { useEffect, useState } from 'react';
import PokemonDetail from '../components/PokemonDetail';
import TrainerDetail from '../components/TrainerDetail';
import axios from 'axios';
import '../css/input.css';
import Breadcrumb from '../components/Breadcrumb';

import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

// for dialog
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import PrizeDetailDialog from '../components/PrizeDetailDialog';


const CompetitionDetailScreen = ({ match }) => {

    let { mode, id } = match.params


    const user = JSON.parse(localStorage.getItem("user"));
    if (!user || (user.role.indexOf('admin') == -1 && user.role.indexOf('manager') == -1 && user.role.indexOf('shopkeeper') == -1)) {
        window.location.href = "/admin";
    }

    const [isEdit, setIsEdit] = useState(false);

    const [name, setName] = useState("");

    const [game, setGame] = useState("");

    const [gameMore, setGameMore] = useState("");

    const [price, setPrice] = useState(0);

    const [parti, setParti] = useState(0);

    const [cost, setCost] = useState(0);

    const [date, setDate] = useState(new Date());

    const [cash, setCash] = useState(0);

    const [payme, setPayme] = React.useState(0);

    const [fps, setFps] = React.useState(0);

    const [octopus, setOctopus] = React.useState(0);

    const [yedpay, setYedpay] = React.useState(0);

    const [prizes, setPrizes] = React.useState([]);

    const [prizeDetail, setPrizeDetail] = React.useState([]);

    const [remarks, setRemarks ] = React.useState("");

    const [uploadStatus, setUploadStatus] = React.useState(0);



    useEffect(() => {
        
        if (mode == "edit"){
            axios.get('/api/competition/getCompetitionById?id=' + id, {
                headers: {
                    'Authorization': `Basic ${user.token}`
                }
            }).then(res => {
                // setLoading(false);
                // console.log(res);
                if (res.data.competition != null) {
                    setName(res.data.competition.name);
                    setGame(res.data.competition.game);
                    setPrice(res.data.competition.price);
                    setParti(res.data.competition.parti)
                    setCost(res.data.competition.cost)
                    setDate(res.data.competition.date);
                    setPrizes(res.data.competition.prizes)
                    setFps(res.data.competition.fps)
                    setCash(res.data.competition.cash)
                    setPayme(res.data.competition.payme)
                    setOctopus(res.data.competition.octopus)
                    setYedpay(res.data.competition.yedpay)
                    // setBatch(res.data.competition.batch);
                    // setDescription(res.data.competition.description);
                    // setSuggestedPrice(res.data.competition.suggestedPrice);
                    // setPccPrice(res.data.competition.pccPrice);
                    // setIsActive(res.data.competition.isActive);
                    // setActiveDate(res.data.competition.activeDate);
                }
    
            }).catch(err => {
                console.error(err);
                // setLoading(false);
                alert("好似有啲問題...");
            });
            
        }
    }, [])


    let onUpload = (e) => {
        e.preventDefault();

        let canUpload = true;
        if (name == "") {
            canUpload = false;
        }
        if (game == "") {
            canUpload = false;
        }

        if (date == "" || date == null) {
            canUpload = false;
        }

        if (price == "" || price == null) {
            canUpload = false;
        }

        if (canUpload) {
            setUploadStatus(1)
            let competition = {
                name: name,
                game: game,
                date: date,
                cost: cost,
                price: price,
                parti: parti,
                cash: cash,
                fps: fps,
                payme: payme,
                octopus: octopus,
                yedpay: yedpay,
                prizes: prizes,
                remarks: remarks
            }

            if (isEdit) {
                console.log("send edit");
                
                axios.post("/api/competition/editCompetition", { competition: competition, id: id }, {
                    headers: {
                        'Authorization': `Basic ${user.token}`
                    }
                }).then(res => {
                    if (res.data.message === "success") {
                        alert("Edit Success");
                        setUploadStatus(2)
                    } else {
                        alert("fail " + res.data.error);
                        setUploadStatus(0)
                    }
                }).catch(err => {
                    setUploadStatus(0)
                    alert("catch fail " + err.response.data.error);
                });
                
            } else {
                console.log("send create");
                axios.post("/api/competition/newCompetition", { competition: competition }, {
                    headers: {
                        'Authorization': `Basic ${user.token}`
                    }
                }).then(res => {
                    if (res.data.message == "success") {
                        alert("Upload Success");
                        setUploadStatus(2)
                        setTimeout(() => {
                            window.location.href = "/admin/competitionDetail/new";
                        }, "4000")
                    } else {
                        setUploadStatus(0)
                        alert("fail " + res.data.error);
                    }
                }).catch(err => {
                    setUploadStatus(0)
                    alert("fail " + err.response.data.error);
                });
            }

        } else {
            alert("請正確輸入所有資料")
        }

    }

    // dialog

    const [openDetail, setOpenDetail] = React.useState(false);

    const handleClickOpenDetail = (mode, id) => {
        setOpenDetail(true);
        let req = {};
        if (mode == "edit") {
            req = { mode, id}
        } else {
            req = { mode }
        }
        setPrizeDetail(req)
    };

    const handleCloseDetail = () => {
        setOpenDetail(false);
        // request(setKey())
    };

    // const [prize, setPrize] = useState([]);
    let handleCallback = (data) => {
        // console.log(data);
        let newPrizes = prizes
        newPrizes.push(data)
        setPrizes(newPrizes)
    }

    const onPrizeDelete = (index) =>{
        let newPrizes = [...prizes];
        newPrizes.splice(index, 1);
        setPrizes(newPrizes);
    }

    let prizeDetailDialog = <div>
        <Dialog
            open={openDetail}
            onClose={handleCloseDetail}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth
            maxWidth={"md"}
        >
            <DialogTitle id="alert-dialog-title">
            </DialogTitle>
            <DialogContent>
                {/* {console.log(prizeDetail)} */}
                <PrizeDetailDialog callback = {{handleCallback:handleCallback, handleCloseDetail:handleCloseDetail}} />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCloseDetail}>關閉</Button>
            </DialogActions>
        </Dialog>
    </div>
    // dialog end

    


    return (
        <>
        <Breadcrumb />
        {prizeDetailDialog}
        <div className="container-fluid">
        
        {mode == "edit" ?
                    <h2>修改比賽資料</h2>
                    : <h2>新增比賽資料</h2>}
                    <div className="card m-2">
                        <div className='card-title m-2'><h3>基本資料</h3></div>
                        <div className='card-body'>
                            <div className="p-2">
                                <label for="name" class="form-label">比賽名</label>
                                <input type="text" class="form-control" value={name} placeholder="比賽名" onChange={e => setName(e.target.value)} />
                            </div>
                            <hr />
                            <div className="p-2">
                                <label for="cat" class="form-label">遊戲類別</label>
                                <select class="form-select" aria-label="productType" value={game} onChange={e => setGame(e.target.value)}>
                                    <option disabled value=""> 遊戲類別 </option>
                                    <option value="PKM">PKM</option>
                                    <option value="DGM">DGM</option>
                                    <option value="YGO">YGO</option>
                                    {/* <option value="WS">WS</option>
                                    <option value="SV">SV</option> */}
                                    <option value="OP">One Piece</option>
                                    <option value="other">其他</option>
                                </select>
                                {game.length >= 5 && game.substring(0, 5) == "other" ?
                                    <div>
                                        <label for="more" class="form-label">其他</label>
                                        <input type="text" class="form-control" value={gameMore} placeholder="其他" onChange={e => setGameMore(e.target.value)} />
                                    </div> : null
                                }
                            </div>
                            <hr />
                            <div className="p-2">
                                <label for="isActive" class="form-label">比賽日期</label>
                                <br />
                                <div className='datePicker'>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DatePicker
                                                inputFormat="DD/MM/YYYY"
                                                label="比賽日期"
                                                value={date}
                                                onChange={(newValue) => {
                                                    setDate(newValue);
                                                }}
                                                renderInput={(params) => <TextField {...params} />}
                                            />
                                        </LocalizationProvider>
                                    </div>
                            </div> 
                            <hr />
                            <div className="p-2">
                                <label for="suggestedPrice" class="form-label">成本</label>
                                <input type="number" min="0.1" step="0.1" pattern="[0-9]*" inputmode="numeric" class="form-control" value={cost} placeholder="官方價" onChange={e => setCost(e.target.value)} />
                            </div>
                            <hr />
                            <div className="p-2">
                                <label for="suggestedPrice" class="form-label">每位價錢</label>
                                <input type="number" min="0.1" step="0.1" pattern="[0-9]*" inputmode="numeric" class="form-control" value={price} placeholder="官方價" onChange={e => setPrice(e.target.value)} />
                            </div>
                            <hr />
                            <div className="p-2">
                                <label for="suggestedPrice" class="form-label">比賽人數</label>
                                <input type="number" min="1" step="1" pattern="[0-9]*" inputmode="numeric" class="form-control" value={parti} placeholder="人數" onChange={e => setParti(e.target.value)} />
                            </div>
                            <hr />
                            <div className="row">
                                <div className="col">
                                    <label for="pccPrice" class="form-label">價錢 cash total</label>
                                    <div className='row' style={{minWidth:"140px"}}>
                                        <div className="col-1" style={{"padding":"0", "width":"10px", "marginTop":"6px"}}>
                                            $
                                        </div>
                                        <div className="col-11" style={{paddingLeft:"5px"}}>
                                            <input type="number" min="0.1" step="0.1" pattern="[0-9]*" inputmode="numeric" class="form-control" value={cash} placeholder="PCC價" onChange={e => setCash(e.target.value)} />
                                        </div>
                                    </div>
                                    
                                </div>
                                <div className="col">
                                    <label for="pccPrice" class="form-label">價錢 payme total</label>
                                    <div className='row' style={{minWidth:"140px"}}>
                                        <div className="col-1" style={{"padding":"0", "width":"10px", "marginTop":"6px"}}>
                                            $
                                        </div>
                                        <div className="col-11" style={{paddingLeft:"5px"}}>
                                            <input type="number" min="0.1" step="0.1" pattern="[0-9]*" inputmode="numeric" class="form-control" value={payme} placeholder="PCC價" onChange={e => setPayme(e.target.value)} />
                                        </div>
                                    </div>
                                    
                                </div>
                                <div className="col">
                                    <label for="pccPrice" class="form-label">價錢 fps total</label>
                                    <div className='row' style={{minWidth:"140px"}}>
                                        <div className="col-1" style={{"padding":"0", "width":"10px", "marginTop":"6px"}}>
                                            $
                                        </div>
                                        <div className="col-11" style={{paddingLeft:"5px"}}>
                                            <input type="number" min="0.1" step="0.1" pattern="[0-9]*" inputmode="numeric" class="form-control" value={fps} placeholder="PCC價" onChange={e => setFps(e.target.value)} />
                                        </div>
                                    </div>
                                    
                                </div>
                                {/* <div className="col">
                                    <label for="pccPrice" class="form-label">價錢 八達通 total</label>
                                    <div className='row' style={{minWidth:"140px"}}>
                                        <div className="col-1" style={{"padding":"0", "width":"10px", "marginTop":"6px"}}>
                                            $
                                        </div>
                                        <div className="col-11" style={{paddingLeft:"5px"}}>
                                            <input type="number" min="0.1" step="0.1" pattern="[0-9]*" inputmode="numeric" class="form-control" value={octopus} placeholder="PCC價" onChange={e => setOctopus(e.target.value)} />
                                        </div>
                                    </div>
                                    
                                </div>
                                <div className="col">
                                    <label for="pccPrice" class="form-label">價錢 YedPay total</label>
                                    <div className='row' style={{minWidth:"140px"}}>
                                        <div className="col-1" style={{"padding":"0", "width":"10px", "marginTop":"6px"}}>
                                            $
                                        </div>
                                        <div className="col-11" style={{paddingLeft:"5px"}}>
                                            <input type="number" min="0.1" step="0.1" pattern="[0-9]*" inputmode="numeric" class="form-control" value={yedpay} placeholder="PCC價" onChange={e => setYedpay(e.target.value)} />
                                        </div>
                                    </div>
                                    
                                </div> */}
                            </div>
                        </div>
                        </div>
                        
                    <div className="card m-2">
                        <div className='card-title m-2'>
                            <div className='row'>
                                <div className='col-6'>
                                    <h3 style={{marginBottom:"0px", height:"100%"}}>獎品</h3>
                                </div>
                                <div className='col-6'>
                                    <button className="btn btn-success btn-large" style={{marginTop:"0px !important"}} onClick={e => handleClickOpenDetail("new", "")}>+</button>
                                </div>
                            </div>
                            
                            
                        </div>
                        <div className='card-body'>
                            {prizes.map((prize, index)=>{
                                return <div className='row'>
                                        <div className="col-6">
                                            {prize.cat+" "+prize.name+" "+prize.quantity}
                                        </div>
                                        <div className="col-6">
                                            <button className="btn btn-danger btn-large"  onClick={()=>onPrizeDelete(index)}>Delete</button>
                                        </div>
                                    </div>
                            })}
                            
                            
                        </div>
                        
                    

                    </div>
                    <div className="card m-2">
                        <div className='card-title m-2'>
                            Remark
                        </div>
                        <div className='card-body'>
                            <div className="p-2">
                                <textarea class="form-control" id="remark" rows="3" value={remarks} onChange={e=>setRemarks(e.target.value)}></textarea>
                            </div>
                        </div>
                    </div>
            {uploadStatus==0 ?
                            <button type='submit' className="btn btn-success btn-large m-2" onClick={e => onUpload(e)}>Upload</button>
                        : uploadStatus == 1 ?
                            <button type='submit' className="btn btn-secondary btn-large m-2" disabled >Uploading...</button>
                        :uploadStatus == 2 ?
                            <button type='submit' className="btn btn-primary btn-large m-2" disabled >Upload Success</button>
                        :null}
        </div>
        </>
    );
}

export default CompetitionDetailScreen;
