import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { QrReader } from 'react-qr-reader';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Breadcrumb from '../components/Breadcrumb';

import '../css/alert.css';
import '../css/input.css';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


const NewOrderRecordScreen = ({ match }) => {
    let { orderType } = match.params;

    const user = JSON.parse(localStorage.getItem("user"));
    if (!user || (user.role.indexOf('admin') == -1 && user.role.indexOf('manager') == -1 && user.role.indexOf('shopkeeper') == -1)) {
        window.location.href = "/admin";
    }

    const [value, setValue] = React.useState(0);

    const [qrResult, setQrResult] = useState('No result');

    const [productName, setProductName] = useState("");

    const [productUnit, setProductUnit] = useState({});

    const [productType, setProductType] = useState("");

    const [productCat, setProductCat] = useState("");

    const [products, setProducts] = useState([]);
    const [managers, setManagers] = useState([]);
    const [sellers, setSellers] = useState([]);

    const [productId, setProductId] = useState("");
    const [type, setType] = useState("");
    const [quantity, setQuantity] = useState(1);
    const [unit, setUnit] = useState();
    const [paymentMethod, setPaymentMethod] = useState("cash");
    const [price, setPrice] = useState(0);
    const [handler, setHandler] = useState("");
    const [seller, setSeller] = useState("");
    const [paid, setPaid] = useState(false);
    const [claimed, setClaimed] = useState(false);
    const [orderedBy, setorderedBy] = useState("");
    const [remark, setRemark] = useState("");
    const [paymentStatus, setPaymentStatus] = useState("");
    const [sellType, setSellType] = useState("現貨");
    const [orderDateValue, setOrderDateValue] = useState(new Date());

    const [productOptions, setProductOptions] = useState([]);


    const [totalBulkCardsCashPrice, setTotalBulkCardsCashPrice] = useState(0.0);
    const [totalBulkCardsPaymePrice, setTotalBulkCardsPaymePrice] = useState(0.0);
    const [totalBulkCardsFpsPrice, setTotalBulkCardsFpsPrice] = useState(0.0);
    const [totalBulkCardsOctopusPrice, setTotalBulkCardsOctopusPrice] = useState(0.0);
    const [totalBulkCardsYedpayPrice, setTotalBulkCardsYedpayPrice] = useState(0.0);

    const [index, setIndex] = useState(-1);
    const prevIndex = useRef(index);

    const prevProducts = useRef(products);
    const prevProductType = useRef(productType);
    const prevProductCat = useRef(productCat);
    const prevQrResult = useRef(qrResult);

    const [uploadStatus, setUploadStatus] = React.useState(0);

    const [selectProduct, setSelectProduct] = useState();

    const [scanAlertMsg, setScanAlertMsg] = React.useState("");
    const [scanAlertCss, setScanAlertCss] = React.useState({});
    const [isAlertVisible, setIsAlertVisible] = React.useState(false);
    const handleAlert = (scanSuccess, product) => {
        if (scanSuccess) {
            setScanAlertMsg("Scan到" + product + "喇~")
            setScanAlertCss({ backgroundColor: "#bafcbd" })
        } else {
            setScanAlertMsg("呢個code唔岩")
            setScanAlertCss({ backgroundColor: "#fcbaba" })
        }
        setIsAlertVisible(true);
        setTimeout(() => {
            setIsAlertVisible(false);
        }, 3000);
    }
    useEffect(() => {
        // console.log(match)
        if (prevProducts.current !== products) {
            console.log("products " + products.length)
            prevProducts.current = products
            selectProductFunc(products)
        } else if (prevProductType.current !== productType) {
            console.log("productType " + productType)
            prevProductType.current = productType
            setProductId("")
            getProduct()
        } else if (prevProductCat.current !== productCat) {
            console.log("productCat " + productCat)
            prevProductCat.current = productCat
            setProductId("")
            getProduct()
        } else if (prevIndex.current !== index) {
            console.log("index " + index)
            setProductId(products[index]._id)
            setProductName(products[index].name)
            setPrice(products[index].pccPrice)
            prevIndex.current = index
        }
        if (managers.length == 0 && sellers.length == 0) {

            getUser("staff", "managers")
            if (orderType == "buy") {
                getUser("supplier", "sellers")
            } else {
                getUser("seller", "sellers")
            }


        }
        if (prevQrResult.current !== qrResult) {
            try {
                const productJson = JSON.parse(qrResult)
                setProductId(productJson.productId)
                setProductName(productJson.name)
                setPrice(productJson.pccPrice)
                onClickScanButton()
                handleAlert(true, productJson.name)
            } catch (err) {
                handleAlert(false)
            }
        }
    }, [products, productType, productCat, index, qrResult])

    const getUser = (role, mode) => {
        let key = "";
        key = "role=" + role;
        request('/api/user/getUser?', key, mode)
    }

    const getProduct = () => {
        if (productCat != "" && productType != "") {
            request('/api/product/getProduct?', setKey(), "products")
        }
    }

    const setKey = () => {
        let key = ""
        key = "productType=" + productType + "&productCat=" + productCat;
        // console.log(key)
        return key;
    }

    const request = (url, key, mode) => {
        axios.get(url + key, {
            headers: {
                'Authorization': `Basic ${user.token}`
            }
        }).then(res => {
            // setLoading(false);
            console.log(res);
            if (mode == "products" && res.data.products != null) {
                setProducts(res.data.products)
            } else if (mode == "managers" && res.data.users != null) {
                setManagers(res.data.users)
            } else if (mode == "sellers" && res.data.users != null) {
                setSellers(res.data.users)
            }

        }).catch(err => {
            console.error(err);
            // setLoading(false);
            alert("好似有啲問題...");
        });
    }



    let onUpload = (e) => {
        e.preventDefault();

        let canUpload = true;
        if (productId == "") {
            canUpload = false;
        }
        if (handler == "" && orderType == "buy") {
            canUpload = false;
        }
        if (seller == "" && orderType == "buy") {
            canUpload = false;
        }
        if (paymentMethod == "") {
            canUpload = false;
        }
        if (canUpload) {
            setUploadStatus(1)
            if (orderType == "buy") {
                let buyRecord = {
                    productId: productId,
                    handler: handler,
                    seller: seller,
                    price: price,
                    quantity: quantity,
                    paymentMethod: paymentMethod,
                    remark: remark
                }
                console.log(buyRecord)
                console.log("send buy");
                axios.post("/api/orderRecord/newBuyRecord", { buyRecord: buyRecord }, {
                    headers: {
                        'Authorization': `Basic ${user.token}`
                    }
                }).then(res => {
                    if (res.data.message === "success") {
                        alert("Upload Success");
                        setUploadStatus(2)
                        setTimeout(() => {
                            window.location.href = "/admin/newOrderRecord/buy";
                        }, "500")
                    } else {
                        setUploadStatus(0)
                        alert("fail " + res.data.error);
                    }
                });
            } else if (orderType == "sell") {
                console.log(orderDateValue.toISOString());
                let d = orderDateValue;
                const customOrderDate = new Date(orderDateValue);
                if(Intl.DateTimeFormat().resolvedOptions().timeZone=="Europe/London"){
                    if (customOrderDate.getDate() != new Date().getDate()) {
                        d = new Date(orderDateValue.toISOString().split("T")[0])
                        d.setHours(d.getHours(), -480)
                    }
                }else if(Intl.DateTimeFormat().resolvedOptions().timeZone=="Asia/Hong_Kong"){
                    if (customOrderDate.getDate() != new Date().getDate()) {
                        d = new Date(orderDateValue.toISOString().split("T")[0])
                        d.setHours(d.getHours(), -480)
                        if (customOrderDate.getHours() < 8) {
                            d.setHours(d.getHours(), +1440)
                        }
                    }
                }
                

                if (paymentStatus != "全數" && paymentStatus != "尾數" && paymentStatus != "訂金") {
                    setPaymentStatus("全數");
                }
                
                let sellRecord = {
                    productId: productId,
                    handler: managers.find(function (item) {
                        if (item.username === user.username) {
                            return item._id;
                        }
                    }),
                    seller: managers.find(function (item) {
                        if (item.username === "admin") {
                            return item._id;
                        }
                    }),
                    price: price,
                    quantity: quantity,
                    paymentMethod: paymentMethod,
                    paymentStatus: paymentStatus,
                    orderdDate: d.toISOString(),
                    sellType: sellType,
                    remark: remark
                }
                if(unit!=null || unit!=""){
                    sellRecord.unit=unit
                }
                console.log("send sell");
                axios.post("/api/orderRecord/newSellRecord", { sellRecord: sellRecord }, {
                    headers: {
                        'Authorization': `Basic ${user.token}`
                    }
                }).then(res => {
                    console.log(res.status)
                    if (res.data.message === "success") {
                        setUploadStatus(2)
                        alert("Done");
                        setTimeout(() => {
                            window.location.href = "/admin/newOrderRecord/sell";
                        }, "500")

                    } else {
                        console.log("fail")
                        setUploadStatus(0)
                        alert("fail " + res.data.error);
                    }
                }).catch(err => {
                    setUploadStatus(0)
                    if (err.response.data.error == "not enough stock") {
                        alert(err.response.data.error)
                    } else {
                        console.log(err.response.data.error)
                    }
                });

            }

        } else {
            setUploadStatus(0)
            alert("請正確輸入所有資料")
        }

    }

    // const handleProductSelect = (value) => {
    //     let split = value.split(",")
    //     setProductId(split[0])
    //     setProductName(split[1])
    // }

    const resetSelectProduct = () => {
        setProductId("")
        setProductName("")
        setPaymentStatus("")
        prevIndex.current = -1
        setIndex(-1)
    }

    const selectProductFunc = (pos) => {
        console.log(pos)
        if (pos == null) {
            setSelectProduct(<div>Please select the type and cat first</div>)
            resetSelectProduct()
        } else if (pos.length == 0) {
            setSelectProduct(<div>No product under this cat and type</div>)
            resetSelectProduct()
        } else {
            let count = -1
            // setSelectProduct(<select className="form-select" aria-label="product" onChange={e => setIndex(e.target.value)}>
            //     <option value={-1} disabled selected="selected">請選擇貨品</option>
            //     {pos.map(product => {
            //         count++
            //         return <option value={count}>{product.name + " - bacth" + product.batch}</option>
            //     })}
            // </select>)
            let options = [];
            for (let i = 0; i < pos.length; i++) {
                options.push({ lable: pos[i].name + " - bacth" + pos[i].batch, id: i })
            }
            setProductOptions(options)
            console.log(productOptions)
            console.log(options)
            // while(productOptions.length<1){

            // }
            refreshSelectProduct(options)

        }
    }

    let refreshSelectProduct = (options) => {
        setSelectProduct(<Autocomplete
            disablePortal
            id="select-product"
            options={options}
            getOptionLabel={(option) => (option.lable)}
            onChange={onProductChange}
            style={{ height: "54px" }}
            renderInput={(params) => <TextField {...params} label="貨" size="small" />}
        />)
    }

    let onProductChange = (event, values) => {
        console.log(values)
        setIndex(values.id)
    }

    let handleBulkCardNameChange = (index, index2, e) => {
        let newbulkCardSellers = [...bulkCardSellers];
        newbulkCardSellers[index].bulkCards[index2].name = e.target.value;
        setBulkCardSellers(newbulkCardSellers);
    }

    let addBulkCardsFormFields = (index) => {
        console.log(index)
        let newbulkCardSellers = [...bulkCardSellers];
        newbulkCardSellers[index].bulkCards = [...newbulkCardSellers[index].bulkCards, { name: "", productId: "", cat: "", price: 0, quantity: 0 }]
        setBulkCardSellers(newbulkCardSellers);
    }

    let removeBulkCardsFormFields = (index, index2) => {
        let newbulkCardSellers = [...bulkCardSellers];
        newbulkCardSellers[index].bulkCards.splice(index2, 1);
        setBulkCardSellers(newbulkCardSellers);
    }

    let handleBulkCardsQuantityChange = (index, index2, e) => {
        let newbulkCardSellers = [...bulkCardSellers];
        newbulkCardSellers[index].bulkCards[index2].quantity = e.target.value;
        setBulkCardSellers(newbulkCardSellers);
    }

    let handleBulkCardsPriceChange = (index, index2, e) => {
        let newbulkCardSellers = [...bulkCardSellers];
        newbulkCardSellers[index].bulkCards[index2].price = e.target.value;
        setBulkCardSellers(newbulkCardSellers);

    }

    let handleBulkCardsCatChange = (index, index2, e) => {
        let newbulkCardSellers = [...bulkCardSellers];
        newbulkCardSellers[index].bulkCards[index2].cat = e.target.value;
        if (e.target.value == "PKM") {
            newbulkCardSellers[index].bulkCards[index2].productId = "636a5fc7a488fe000bee30c4"
        } else if (e.target.value == "DGM") {
            newbulkCardSellers[index].bulkCards[index2].productId = "636a6106822d26552ca34110"
        } else if (e.target.value == "YGO") {
            newbulkCardSellers[index].bulkCards[index2].productId = "636a610e822d26552ca34111"
        } else if (e.target.value == "OP") {
            newbulkCardSellers[index].bulkCards[index2].productId = "63dc7965dee69c10f55c0062"
        } else if (e.target.value == "other") {
            newbulkCardSellers[index].bulkCards[index2].productId = "63dc799edee69c10f55c0063"
        }
        setBulkCardSellers(newbulkCardSellers);
    }

    let handleBulkCardsPaymentMethodChange = (index, e) => {
        let newbulkCardSellers = [...bulkCardSellers];
        newbulkCardSellers[index].paymentMethod = e.target.value;
        setBulkCardSellers(newbulkCardSellers);
    }

    const [bulkCardSellers, setBulkCardSellers] = useState([{ id: "", bulkCards: [{ name: "", productId: "", cat: "", price: 0, quantity: 0 }], paymentMethod: "cash" }])
    let handleBulkCardSellersChange = (i, e) => {
        let newbulkCardSellers = [...bulkCardSellers];
        newbulkCardSellers[i].id = e.target.value
        setBulkCardSellers(newbulkCardSellers);
    }

    let addBulkCardSellersFormFields = () => {
        setBulkCardSellers([...bulkCardSellers, { id: "", bulkCards: [{ name: "", productId: "", price: 0, quantity: 0 }], paymentMethod: "cash" }]);
    }

    let removeBulkCardSellersFormFields = (i) => {
        let newbulkCardSellers = [...bulkCardSellers];
        newbulkCardSellers.splice(i, 1);
        setBulkCardSellers(newbulkCardSellers)
    }

    var showBulkCardSellersFormFields = bulkCardSellers.map((element, index) => {
        return <div className="card p-1">
            <div className='card-body p-1'>
                <div className="p-2">
                    <label for="seller" class="form-label">賣家</label><button className="btn btn-danger m-2" onClick={() => removeBulkCardSellersFormFields(index)}>刪除</button>
                    <select class="form-select" aria-label="seller" value={bulkCardSellers[index].id} onChange={e => handleBulkCardSellersChange(index, e)}>
                        <option disabled value=""> 賣家 </option>
                        {sellers.map(seller => {
                            return <option value={seller._id}>{seller.name}</option>
                        })}
                    </select>
                </div>
                <hr />
                <div className="p-2">

                    {bulkCardSellers[index].bulkCards.map((element, index2) => {
                        return <div className='card m-1'>
                            <div className='card-body'>
                                <div className="p-2">

                                    <label style={{ width: "50%" }}>卡名</label>
                                    <button className="btn btn-outline-danger" style={{ width: "50%", minWidth: "66px" }} onClick={() => removeBulkCardsFormFields(index, index2)}>刪除</button>

                                    <input type="text" class="form-control" value={bulkCardSellers[index].bulkCards[index2].name} placeholder="卡名" onChange={e => handleBulkCardNameChange(index, index2, e)} />


                                </div>
                                <hr />
                                <div className="p-2">
                                    <label for="productCat" class="form-label">商品類別</label>
                                    <div className="switch-field">
                                        <input type="radio" id={"pkmRadio" + index + index2} name={"catRadio" + index + index2} value="PKM" onChange={e => handleBulkCardsCatChange(index, index2, e)} />
                                        <label for={"pkmRadio" + index + index2}>PKM</label>
                                        <input type="radio" id={"dgmRadio" + index + index2} name={"catRadio" + index + index2} value="DGM" onChange={e => handleBulkCardsCatChange(index, index2, e)} />
                                        <label for={"dgmRadio" + index + index2}>DGM</label>
                                        <input type="radio" id={"ygoRadio" + index + index2} name={"catRadio" + index + index2} value="YGO" onChange={e => handleBulkCardsCatChange(index, index2, e)} />
                                        <label for={"ygoRadio" + index + index2}>YGO</label>
                                        <input type="radio" id={"opRadio" + index + index2} name={"catRadio" + index + index2} value="OP" onChange={e => handleBulkCardsCatChange(index, index2, e)} />
                                        <label for={"opRadio" + index + index2}>OP</label>
                                        <input type="radio" id={"otherRadio" + index + index2} name={"catRadio" + index + index2} value="other" onChange={e => handleBulkCardsCatChange(index, index2, e)} />
                                        <label for={"otherRadio" + index + index2}>other</label>
                                    </div>
                                </div>
                                <hr />
                                <div className="p-2">
                                    <label for="price" class="form-label">價錢</label>
                                    <input type="number" min="0.1" step="0.1" pattern="[0-9]*" inputmode="numeric" class="form-control" value={bulkCardSellers[index].bulkCards[index2].price} placeholder="價錢" onChange={e => handleBulkCardsPriceChange(index, index2, e)} />
                                </div>

                                <hr />

                                <div className="p-2">
                                    <label for="quantity" class="form-label">數量</label>
                                    <div className='row'>
                                        <div className='col-md-9'>
                                            <input type="number" min="1" step="1" pattern="[0-9]*" inputmode="numeric" class="form-control" value={bulkCardSellers[index].bulkCards[index2].quantity} placeholder="數量" onChange={e => handleBulkCardsQuantityChange(index, index2, e)} />
                                        </div>
                                    </div>
                                </div>
                                <hr />
                            </div>
                        </div>
                    })}

                    <div className='p-2'>
                        <button className="btn btn-outline-success p-2" style={{ width: "100%" }} onClick={() => addBulkCardsFormFields(index)}>增加卡</button>
                    </div>

                </div>
                <div className="p-2">
                    <label for="paymentMethod" class="form-label">付款方式</label>
                    <div className="switch-field">
                        <input type="radio" id={"cashRadio" + index} name={"paymentMethodRadio" + index} value="cash" onChange={e => handleBulkCardsPaymentMethodChange(index, e)} />
                        <label for={"cashRadio" + index}>Cash</label>
                        <input type="radio" id={"paymeRadio" + index} name={"paymentMethodRadio" + index} value="payme" onChange={e => handleBulkCardsPaymentMethodChange(index, e)} />
                        <label for={"paymeRadio" + index}>Payme</label>
                        <input type="radio" id={"fpsRadio" + index} name={"paymentMethodRadio" + index} value="fps" onChange={e => handleBulkCardsPaymentMethodChange(index, e)} />
                        <label for={"fpsRadio" + index}>FPS</label>
                        {/* <input type="radio" id={"octopusRadio" + index} name={"paymentMethodRadio" + index} value="octopus" onChange={e => handleBulkCardsPaymentMethodChange(index, e)} />
                        <label for={"octopusRadio" + index}>八達通</label>
                        <input type="radio" id={"yedpayRadio" + index} name={"paymentMethodRadio" + index} value="yedpay" onChange={e => handleBulkCardsPaymentMethodChange(index, e)} />
                        <label for={"yedpayRadio" + index}>Yedpay</label> */}
                    </div>
                </div>
                <hr />
            </div>
        </div>
    })

    const calTotal = () => {
        console.log("calTotal")
        let newCashPrice = 0
        let newPaymePrice = 0
        let newFpsPrice = 0
        let newYedpayPrice = 0
        let newOctopusPrice = 0
        for (let i in bulkCardSellers) {
            let newbulkCardSeller = bulkCardSellers[i]
            if (newbulkCardSeller.paymentMethod == "cash") {

                for (let n in newbulkCardSeller.bulkCards) {
                    let bulkCard = newbulkCardSeller.bulkCards[n]
                    newCashPrice += parseFloat(bulkCard.price)
                }
            } else if (newbulkCardSeller.paymentMethod == "payme") {
                for (let n in newbulkCardSeller.bulkCards) {
                    let bulkCard = newbulkCardSeller.bulkCards[n]
                    newPaymePrice += parseFloat(bulkCard.price)
                }
            } else if (newbulkCardSeller.paymentMethod == "fps") {
                for (let n in newbulkCardSeller.bulkCards) {
                    let bulkCard = newbulkCardSeller.bulkCards[n]
                    newFpsPrice += parseFloat(bulkCard.price)
                }
            } else if (newbulkCardSeller.paymentMethod == "yedpay") {
                for (let n in newbulkCardSeller.bulkCards) {
                    let bulkCard = newbulkCardSeller.bulkCards[n]
                    newYedpayPrice += parseFloat(bulkCard.price)
                }
            } else if (newbulkCardSeller.paymentMethod == "octopus") {
                for (let n in newbulkCardSeller.bulkCards) {
                    let bulkCard = newbulkCardSeller.bulkCards[n]
                    newOctopusPrice += parseFloat(bulkCard.price)
                }
            }
        }
        setTotalBulkCardsCashPrice(newCashPrice)
        setTotalBulkCardsPaymePrice(newPaymePrice)
        setTotalBulkCardsFpsPrice(newFpsPrice)
        setTotalBulkCardsYedpayPrice(newYedpayPrice)
        setTotalBulkCardsOctopusPrice(newOctopusPrice)
    }

    const onBulkCardsSubmit = (e) => {
        for (let i in bulkCardSellers) {
            if (bulkCardSellers[i].paymentMethod == "") {
                alert("請選擇付款方式")
                console.log("請選擇付款方式")
                return
            } else if (bulkCardSellers[i].id == "") {
                alert("請選擇賣家")
                console.log("請選擇賣家")
                return
            }
            for (let n in bulkCardSellers[i].bulkCards) {
                if (bulkCardSellers[i].bulkCards[n].quantity < 1) {
                    alert("數量不能少於1")
                    console.log("數量不能少於1")
                    return
                } else if (bulkCardSellers[i].bulkCards[n].productId == "") {
                    alert("請選擇商品類別")
                    console.log("請選擇商品類別")
                    return
                }
                else if (bulkCardSellers[i].bulkCards[n].name == "") {
                    alert("請輸入卡名")
                    console.log("請輸入卡名")
                    return
                }
            }
        }
        let d = orderDateValue;
        var customOrderDate = new Date(orderDateValue);
        if(Intl.DateTimeFormat().resolvedOptions().timeZone=="Europe/London"){
            if (customOrderDate.getDate() != new Date().getDate()) {
                d = new Date(orderDateValue.toISOString().split("T")[0])
                d.setHours(d.getHours(), -480)
            }
        }else if(Intl.DateTimeFormat().resolvedOptions().timeZone=="Asia/Hong_Kong"){
            if (customOrderDate.getDate() != new Date().getDate()) {
                d = new Date(orderDateValue.toISOString().split("T")[0])
                d.setHours(d.getHours(), -480)
                if (customOrderDate.getHours() < 8) {
                    d.setHours(d.getHours(), +1440)
                }
            }
        }
        setUploadStatus(1)
        console.log(bulkCardSellers);
        console.log("send sell bulk card");
        axios.post("/api/orderRecord/newSellBulkCardRecord", { sellRecord: bulkCardSellers, orderdDate: d.toISOString() }, {
            headers: {
                'Authorization': `Basic ${user.token}`
            }
        }).then(res => {
            console.log(res.status)
            if (res.data.message === "success") {
                setUploadStatus(2)
                alert("Upload Success");
                setTimeout(() => {
                    window.location.href = "/admin/newOrderRecord/sell";
                }, "4000")
            } else {
                console.log("fail")
                setUploadStatus(0)
                alert("fail " + res.data.error);
            }
        }).catch(err => {
            if (err.response.data.error == "not enough stock") {
                console.log(err.response.data)
                alert("Error")
            } else {
                console.log(err.response.data)
                alert("Error")

            }
            setUploadStatus(0)
        });

    }

    // for tab changing
    const handleChange = (event, newValue) => {
        setValue(newValue);

        resetSelectProduct();
    };


    const [scanActive, setScanActive] = useState(true)
    const [scanWord, setScanWord] = useState("Stop Scaning")
    const [scanStyle, setScanStyle] = useState("btn-danger")

    const onClickScanButton = () => {
        if (scanActive) {
            setScanActive(false)
            setScanWord("Scan")
            setScanStyle("btn-success")
        } else {
            setScanActive(true)
            setScanWord("Stop Scaning")
            setScanStyle("btn-danger")
        }
    }

    const [relativeQuantity, setRelativeQuantity] = useState(0)
    const onChangeRelativeQuantity = (q, u) =>{
        setRelativeQuantity(q);
        if(u=="包"){
            setQuantity(q)
        }else if(u=="盒"){
            setQuantity(q*products[index].unit.box)
        }else if(u=="箱"){
            setQuantity(q*products[index].unit.box*products[index].unit.carton)
        }else{
            setQuantity(q)
        }
    }

    const onChangeUnit = (u) =>{
        setUnit(u);
        onChangeRelativeQuantity(relativeQuantity, u)
    }



    return (
        <>
            <Breadcrumb />
            <div className="container-fluid" style={{ alignContent: "center", marginTop: "5px" }}>

                {isAlertVisible ?
                    <div className="container" style={{ alignContent: "center" }}>
                        <div className='alert-container' style={{ alignContent: "center" }}>
                            <div style={scanAlertCss} className='alert-inner'>{scanAlertMsg}</div>
                        </div>
                    </div>
                    : null}
                {orderType == "buy" ?
                    <h2>買入 {productName}</h2>
                    :
                    <h2>賣出 {productName}</h2>
                }

                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                        <Tab label="人手入" {...a11yProps(0)} />
                        {orderType == "sell" ?
                            <Tab label="QR Code" {...a11yProps(1)} />

                            : null}
                        {orderType == "sell" ?
                            <Tab label="散卡" {...a11yProps(2)} />

                            : null}

                    </Tabs>
                </Box>
                <TabPanel value={value} index={0}>
                    <div className="card p-2 m-2">
                        <div className="row">

                            <div className="col-md-4">

                                <div className="p-2">
                                    <select className="form-select" aria-label="productType" value={productType} onChange={e => setProductType(e.target.value)}>
                                        <option value="" disabled selected="selected"> 商品類型:</option>
                                        <option value="booster">Booster</option>
                                        <option value="stater">預組</option>
                                        {orderType == "buy" ?
                                            <option value="bulkCard">散卡</option>
                                            : null}
                                        <option value="sleeve">卡套</option>
                                        <option value="box">卡盒</option>
                                        <option value="playmat">Playmat</option>
                                        <option value="giftBox">禮盒</option>
                                        <option value="VJump">VJump</option>
                                        <option value="sundry">雜項</option>
                                        <option value="other">其他</option>
                                    </select>
                                </div>
                            </div>
                            {productType != "" ?
                                <div className="col-md-4">
                                    <div className="p-2">
                                        <select className="form-select" aria-label="productCat" value={productCat} onChange={e => setProductCat(e.target.value)}>
                                            <option value="" disabled selected="selected"> 商品類別</option>
                                            <option value="PKM">PKM</option>
                                            <option value="DGM">DGM</option>
                                            <option value="YGO">YGO</option>
                                            <option value="WS">WS</option>
                                            <option value="SV">SV</option>
                                            <option value="OP">One Piece</option>
                                            <option value="DragonShield">龍盾</option>
                                            <option value="King">King</option>
                                            <option value="RSS">RSS</option>
                                            <option value="KMC">KMC</option>
                                            <option value="Broccoli">Broccoli</option>
                                            <option value="UG">UG</option>
                                            <option value="other">其他</option>
                                        </select>
                                    </div>
                                </div>
                                : null}
                            {productCat != "" ?
                                <div className="col-md-4">
                                    <div className="p-2">
                                        {selectProduct}
                                    </div>
                                </div>
                                : null}

                        </div>

                    </div >
                </TabPanel>
                {orderType == "sell" ?
                    <TabPanel value={value} index={1}>
                        {scanActive ?
                            <QrReader
                                constraints={{
                                    facingMode: "environment",
                                    width: 1000,
                                    height: 1000,
                                    aspectRatio: 1,
                                    resolution: 900
                                }}
                                onResult={(result, error) => {
                                    if (!!result) {
                                        setQrResult(result?.text);
                                    }

                                    if (!!error) {
                                        console.info(error);
                                    }
                                }}
                                style={{ width: '100%' }}
                            />
                            : null}
                        <button className={scanStyle + " btn btn-large m-2"} onClick={e => onClickScanButton()}>{scanWord}</button>
                    </TabPanel>
                    : null}
                {orderType == "sell" ?
                    <TabPanel value={value} index={2}>
                        {showBulkCardSellersFormFields}
                        <div style={{ marginTop: "20px", marginBottom: "20px" }}>
                            <button style={{ width: "100%", marginTop: "12px", marginBottom: "12px" }} className="btn btn-outline-success" onClick={() => addBulkCardSellersFormFields()}>增加賣家</button>
                        </div>
                        <hr />
                        <div className='datePicker'>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    inputFormat="DD/MM/YYYY"
                                    label="訂單日期"
                                    value={orderDateValue}
                                    onChange={(newValue) => {
                                        setOrderDateValue(newValue);
                                    }}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </LocalizationProvider>
                        </div>

                        <button style={{ width: "100%" }} type='submit' className="btn btn-warning btn-large" onClick={e => calTotal(e)}>計數</button>
                        <div className='m-2'>
                            <label style={{ width: "11%", textAlign: "center" }}>
                                Cash ${totalBulkCardsCashPrice}
                            </label>
                            <label style={{ width: "11%", textAlign: "center" }}>
                                |
                            </label>
                            <label style={{ width: "11%", textAlign: "center" }}>
                                Payme ${totalBulkCardsPaymePrice}
                            </label>
                            <label style={{ width: "11%", textAlign: "center" }}>
                                |
                            </label>
                            <label style={{ width: "12%", textAlign: "center" }}>
                                FPS ${totalBulkCardsFpsPrice}
                            </label>
                            <label style={{ width: "11%", textAlign: "center" }}>
                                |
                            </label>
                            <label style={{ width: "11%", textAlign: "center" }}>
                                八達通 ${totalBulkCardsOctopusPrice}
                            </label>
                            <label style={{ width: "11%", textAlign: "center" }}>
                                |
                            </label>
                            <label style={{ width: "11%", textAlign: "center" }}>
                                Yedpay ${totalBulkCardsYedpayPrice}
                            </label>
                        </div>
                        <hr />
                        <br />
                        <br />

                        {uploadStatus == 0 ?
                            <button style={{ width: "33%" }} type='submit' className="btn btn-success btn-large m-2" onClick={e => onBulkCardsSubmit(e)}>Upload</button>
                            : uploadStatus == 1 ?
                                <button style={{ width: "33%" }} type='submit' className="btn btn-secondary btn-large m-2" disabled >Uploading...</button>
                                : uploadStatus == 2 ?
                                    <button style={{ width: "33%" }} type='submit' className="btn btn-primary btn-large m-2" disabled >Upload Success</button>
                                    : null}
                        <br />
                    </TabPanel>
                    : null}
                {productId != null && productId != "" && (value == 0 || value == 1) ?
                    <div className="card p-2 m-2">
                        <div className='card-body'>
                            <div className="p-2">
                                <label for="price" class="form-label">價錢</label>
                                <input type="number" min="0.1" step="0.1" pattern="[0-9]*" inputmode="numeric" class="form-control" value={price} placeholder="價錢" onChange={e => setPrice(e.target.value)} />
                            </div>
                            <hr />
                            {value == 0 && productType=="booster" && products[index].unit ?
                            <div className="p-2">
                                <label for="productCat" class="form-label">單位</label>
                                <div className="switch-field">
                                    <input type="radio" id={"packRadio"} name={"unitRadio"} value="包" onChange={e => onChangeUnit(e.target.value)} />
                                    <label for={"packRadio"}>包</label>
                                    <input type="radio" id={"boxRadio"} name={"unitRadio"} value="盒" onChange={e => onChangeUnit(e.target.value)} />
                                    <label for={"boxRadio"}>盒</label>
                                    <input type="radio" id={"cartonRadio"} name={"unitRadio"} value="箱" onChange={e => onChangeUnit(e.target.value)} />
                                    <label for={"cartonRadio"}>箱</label>
                                </div>
                            </div>: productType=="booster" && !products[index].unit && !unit ? 
                                <>{setUnit("包")}</>
                            : null}
                            <div className="p-2">
                                <label for="quantity" class="form-label">數量</label>
                                <div className='row'>
                                    <div className='col-md-9'>
                                        <input type="number" min="1" step="1" pattern="[0-9]*" inputmode="numeric" class="form-control" value={relativeQuantity} placeholder="數量" onChange={e => onChangeRelativeQuantity(e.target.value, unit)} />
                                    </div>
                                    {productType=="booster" ?
                                        <div className='col-md-3'>
                                            ({unit})
                                        </div>
                                    : null}
                                    
                                </div>

                                <div className='row'>
                                {unit!='包' && unit!=null && unit!=''?
                                        <div className='col'>
                                            ({relativeQuantity}{unit}={quantity}包)
                                        </div>
                                    :null}
                                {value == 0 ?
                                        <div className='col'>
                                            剩餘數量: {products[index].stock}(包)
                                        </div>
                                        : null}
                                </div>
                            </div>
                            {orderType == "sell" ? <>
                                <hr />
                                <div className="switch-field">
                                    <input type="radio" id={"spotRadio" + index} name={"sellTypeRadio" + index} value="inStock" onChange={e => setSellType(e.target.value)} />
                                    <label for={"spotRadio" + index}>現貨</label>
                                    <input type="radio" id={"preOrderRadio" + index} name={"sellTypeRadio" + index} value="preorder" onChange={e => setSellType(e.target.value)} />
                                    <label for={"preOrderRadio" + index}>預訂</label>
                                </div>

                            </>
                                : null}
                            <hr />
                            <div className="switch-field">
                                <input type="radio" id={"fullPaymentRadio" + index} name={"paymentRadio" + index} value="全數" onChange={e => setPaymentStatus(e.target.value)} />
                                <label for={"fullPaymentRadio" + index}>全數</label>
                                <input type="radio" id={"finalPaymentRadio" + index} name={"paymentRadio" + index} value="尾數" onChange={e => setPaymentStatus(e.target.value)} />
                                <label for={"finalPaymentRadio" + index}>尾數</label>
                                <input type="radio" id={"depositeRadio" + index} name={"paymentRadio" + index} value="訂金" onChange={e => setPaymentStatus(e.target.value)} />
                                <label for={"depositeRadio" + index}>訂金</label>
                            </div>
                            <hr />
                            <div className="p-2">
                                <label for="remark" class="form-label">備註</label>
                                <textarea class="form-control" id="remark" rows="3" value={remark} onChange={e => setRemark(e.target.value)}></textarea>
                            </div>
                            <hr />

                            {orderType == "buy" ?
                                <>
                                    <div className="p-2">
                                        {orderType == "buy" ?
                                            <label for="handler" class="form-label">出資人</label>
                                            :
                                            <label for="handler" class="form-label">經手人</label>
                                        }
                                        <select class="form-select" aria-label="handler" value={handler} onChange={e => setHandler(e.target.value)}>
                                            {orderType == "buy" ?
                                                <option disabled value=""> 出資人 </option>
                                                :
                                                <option disabled value=""> 經手人 </option>
                                            }
                                            {managers.map(manager => {
                                                return <option value={manager._id}>{manager.name}</option>
                                            })}
                                        </select>
                                    </div>
                                    <hr />

                                    <div className="p-2">
                                        <label for="seller" class="form-label">賣家</label>
                                        <select class="form-select" aria-label="seller" value={seller} onChange={e => setSeller(e.target.value)}>
                                            <option disabled value=""> 賣家 </option>
                                            {sellers.map(seller => {
                                                return <option value={seller._id}>{seller.name}</option>
                                            })}
                                        </select>
                                    </div>
                                    <hr />
                                </>
                                : null}
                            <div className="p-2">
                                <label for="paymentMethod" class="form-label">付款方式</label>
                                <select class="form-select" aria-label="paymentMethod" value={paymentMethod} onChange={e => setPaymentMethod(e.target.value)}>
                                    <option value="cash"> Cash </option>
                                    <option value="payme"> Payme </option>
                                    <option value="fps"> FPS </option>
                                    <option value="octopus"> 八達通 </option>
                                    <option value="yedpay"> Yedpay </option>
                                    {orderType == "buy" ?
                                        <option value="account"> Account </option>
                                        : null}
                                </select>
                            </div>
                            {orderType == "sell" ? <>
                                <hr />
                                <div className='datePicker'>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker
                                            inputFormat="DD/MM/YYYY"
                                            label="訂單日期"
                                            value={orderDateValue}
                                            onChange={(newValue) => {
                                                setOrderDateValue(newValue);
                                            }}
                                            renderInput={(params) => <TextField {...params} />}
                                        />
                                    </LocalizationProvider>
                                </div>

                            </>
                                : null}
                            <hr />
                            {uploadStatus == 0 ?
                                <button style={{ width: "33%" }} type='submit' className="btn btn-success btn-large m-2" onClick={e => onUpload(e)}>Upload</button>
                                : uploadStatus == 1 ?
                                    <button style={{ width: "33%" }} type='submit' className="btn btn-secondary btn-large m-2" disabled >Uploading...</button>
                                    : uploadStatus == 2 ?
                                        <button style={{ width: "33%" }} type='submit' className="btn btn-primary btn-large m-2" disabled >Upload Success</button>
                                        : null}
                        </div>

                    </div>
                    : null}
            </div>
        </>
    );
}

export default NewOrderRecordScreen;
