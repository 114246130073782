import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const PokemonDetail = ({ pokemon }) => {

    console.log(pokemon);
    const [user, setUser] = useState({});

    useEffect(() => {
        if (localStorage.getItem("user")) {
            setUser(JSON.parse(localStorage.getItem("user")));
        }
    }, [])

    var edit = "";
    var deleted = "";
    if (user.role === "admin" || user.role === "manager") {
        edit = <Link to={{ pathname: `/admin/newPokemon/${pokemon._id}` }} ><a className="btn btn-small btn-warning m-2 " style={{ width: "90px" }}>Edit</a></Link>
    }
    if (user.role === "admin") {
        deleted = < button className="btn btn-small btn-danger m-2 " style={{ width: "90px" }} onClick={/*onDelete(e)*/e=>handleClickOpen(e) }>DELETE</button>

    }

    const onDelete = () => {
        axios.post("/api/card/deleteCard", { id: pokemon._id }, {
            headers: {
                'Authorization': `Basic ${user.token}`
            }
        }).then(res => {
            if (res.data.message === "success") {
                alert("Deleted");
                window.location.href = "/";
            }
        }).catch(err => {
            console.log(err.response.status);
            let message = "fail";
            console.log(err)
            alert(message);
        })
        handleClose();
    }

    // for confirm dialog
    const [open, setOpen] = React.useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    let dialog = <div>
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {"Warning"}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Confirm DELETE card {pokemon.cardName}?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>cancel</Button>
                <Button onClick={onDelete} autoFocus>
                    Confirm
                </Button>
            </DialogActions>
        </Dialog>
    </div>




    //for evolveTo
    var evolveTo = "";
    if (pokemon.evolveTo.length > 0) {
        evolveTo = <div>
            {pokemon.evolveTo.map(pkm => {
                return (<Link to={`/card/cardName/${pkm}/exact`}>{" " + pkm}</Link>)
            })}
        </div>


    }

    //for evolveFrom
    var evolveFrom = "";
    if (pokemon.evolveFrom !== "") {
        evolveFrom = <li className="list-group-item"><p>進化自: <Link to={`/card/cardName/${pokemon.evolveFrom}/exact`}>{" " + pokemon.evolveFrom}</Link></p></li>

    }

    //for resistance
    var resistance = "";
    if (pokemon.resistance.pokemonType !== "") {
        resistance =
            <li className="list-group-item"><label>抵抗力: </label><label className="p-2"></label>{convertEnergy(pokemon.resistance.pokemonType)} {" " + pokemon.resistance.resistPoint}</li>
    }
    //for pkm type
    //var pokemonType = <li className="list-group-item">{convertEnergy(pokemon.pokemonType)}</li>;

    //for weakness
    var weakness = <li className="list-group-item"><label>弱點: </label><label className="p-2"></label>{convertEnergy(pokemon.weakness)}</li>;

    //for retreatCost
    var retreatCost = <li className="list-group-item">
        <label >撤退: </label><label className="p-2"></label>
        {pokemon.retreatCost.map(energy => {
            return convertEnergy(energy);
        })}
    </li>

    // for pokemon ability
    var ability = "";
    var abilityArray = [];
    pokemon.ability.map(ability => {
        abilityArray.push(ability);
    })
    if (abilityArray.length > 0) {
        ability = <li class="list-group-item">
            <label>特性</label>
            {
                abilityArray.map(ability => {
                    return <div>
                        <ul className="list-group list-group-flush">
                            <li className="list-group-item"><p>{ability.abilityName}</p></li>
                            <li className="list-group-item"><p>{ability.abilityDescription}</p></li>
                        </ul>
                        <hr></hr>
                    </div>

                })
            }
        </li>
    }

    //for attack
    var attack = "";
    if (pokemon.attack.length > 0) {
        attack = <li className="list-group-item">
            <label>招式</label>
            {pokemon.attack.map(attack => {
                return <div>
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item"><p>{attack.attackName}</p></li>
                        <li className="list-group-item">
                            {/* {attack.energyNeed.map(energyNeed => {
                                return convertEnergy(energyNeed);
                            })} */}
                            {attack.energyNeed.length <= 0 ? "唔使能量" : attack.energyNeed.map(energyNeed => {
                                return convertEnergy(energyNeed);
                            })}
                        </li>
                        {attack.attackDescription == "" ? <li className="list-group-item"><p>冇效果</p></li> : <li className="list-group-item"><p>{attack.attackDescription}</p></li>}
                        {attack.attackPoint == "" ? <li className="list-group-item"><p>冇攻擊力</p></li> : <li className="list-group-item"><p>{attack.attackPoint}</p></li>}
                    </ul>
                    <hr></hr>
                </div>

            })}
        </li>
    }



    //for classifying energy type
    function convertEnergy(energyType) {
        if (energyType === '雷') {
            return <img className="energy" src={require("../img/energy/lightning.png")} alt={energyType}></img>
        } else if (energyType === '水') {
            return <img className="energy" src={require("../img/energy/water.png")} alt={energyType}></img>
        } else if (energyType === '無') {
            return <img className="energy" src={require("../img/energy/normal.png")} alt={energyType}></img>
        } else if (energyType === '龍') {
            return <img className="energy" src={require("../img/energy/dragon.png")} alt={energyType}></img>
        } else if (energyType === '仙') {
            return <img className="energy" src={require("../img/energy/fairy.png")} alt={energyType}></img>
        } else if (energyType === '惡') {
            return <img className="energy" src={require("../img/energy/darkness.png")} alt={energyType}></img>
        } else if (energyType === '鬥') {
            return <img className="energy" src={require("../img/energy/fighting.png")} alt={energyType}></img>
        } else if (energyType === '火') {
            return <img className="energy" src={require("../img/energy/fire.png")} alt={energyType}></img>
        } else if (energyType === '鋼') {
            return <img className="energy" src={require("../img/energy/metal.png")} alt={energyType}></img>
        } else if (energyType === '草') {
            return <img className="energy" src={require("../img/energy/grass.png")} alt={energyType}></img>
        } else if (energyType === '超') {
            return <img className="energy" src={require("../img/energy/psychic.png")} alt={energyType}></img>
        }
    }

    return (
        <div>
            {dialog}
            <div className="row">
                <div className="col-md-6">
                    <div className="card p-2 m-2">
                        <img src={pokemon.cardImg} className="img-fluid" style={{ height: '400px' }} alt={pokemon.cardName} />
                    </div>
                </div>
                <div className="col-md-6" style={{overflow: "auto"}}>
                    <div className="m-2">
                        <div className="card p-2 m-2">
                            <div className="card-title">
                                <div>
                                    {pokemon.cardName}<label className="p-2"></label>
                                    {convertEnergy(pokemon.pokemonType)}
                                </div>
                            </div>
                            <div className='card-body'>
                                <ul className="list-group">
                                    <li className="list-group-item"><p>{"系列: " + pokemon.cardSeries}</p></li>
                                    <li className="list-group-item"><p>{"HP: " + pokemon.hp}</p></li>
                                    {ability}
                                    {attack}
                                    <li className="list-group-item"><p>{"Stage: " + pokemon.evolutionStage}</p></li>
                                    {evolveFrom}
                                    {evolveTo === "" ? "" :
                                        <li className="list-group-item"> 進化:
                                            {evolveTo}
                                        </li>
                                    }

                                    {weakness}
                                    {resistance}
                                    {retreatCost}
                                    <li className="list-group-item"><p>{"Rare: " + pokemon.cardRare}</p></li>
                                    <li className="list-group-item"><p>{"賽制標記: " + pokemon.cardSet}</p></li>
                                    <li className="list-group-item"><p>{pokemon.stock>0? "有貨" : "冇貨"}</p></li>
                                    <li className="list-group-item"><p>${pokemon.price==null?9999:pokemon.price}</p></li>
                                </ul>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div className="me-auto">
                {edit}
                {deleted}
            </div>
            <p />
        </div>
    );
}

export default PokemonDetail;