import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';


const NewPreorderDetailDialog = ({ req }) => {

    const user = JSON.parse(localStorage.getItem("user"));

    const [isEdit, setIsEdit] = useState(false);

    const [name, setName] = useState("");
    const [shippingAddress, setShippingAddress] = useState("");
    const [phone, setPhone] = useState("");
    const [productType, setProductType] = useState("");
    const [product, setProduct] = useState("");
    const [quantity, setQuantity] = useState(1);
    const [price, setPrice] = useState(0.0);
    const [deposite, setDeposite] = useState(0.0);
    const [finalPayment, setFinalPayment] = useState(0.0);
    const [paymentStatus, setPaymentStatus] = useState("未付款");
    const [orderStatus, setOrderStatus] = useState("");
    const [remark, setRemark] = useState("");

    const [products, setProducts] = useState([]);
    const [productId, setProductId] = useState("");
    const [value, setValue] = React.useState(0);
    const [productCat, setProductCat] = useState("");
    const [selectProduct, setSelectProduct] = useState();
    const [index, setIndex] = useState(-1);
    const [productOptions, setProductOptions] = useState([]);
    const prevIndex = useRef(index);

    const prevProducts = useRef(products);
    const prevProductType = useRef(productType);
    const prevProductCat = useRef(productCat);

    const [init, setInit] = useState(false);




    useEffect(() => {
        // console.log(req)
        if (!init) {
            if (req.mode == "new") {
                setPhone(req.phone)
                if (req.name != null && req.shippingAddress != null) {
                    setName(req.name)
                    setShippingAddress(req.shippingAddress)
                }
                setInit(true)

            } else {
                if (req.id != null) {
                    setIsEdit(true)
                    axios.get('/api/preorder/getPreorderById?id=' + req.id, {
                        headers: {
                            'Authorization': `Basic ${user.token}`
                        }
                    }).then(res => {
                        //setLoading(false);
                        //console.log(res);
                        if (res.data.preorder.length > 0) {
                            res.data.preorder.map((p, index) => {
                                console.log(p)
                                setName(p.name);
                                setShippingAddress(p.shippingAddress);
                                setPhone(p.phone);
                                setProductType(p.productType);
                                setProductCat(p.productCat);
                                setProduct(p.product);
                                setProductId(p.productId);
                                setQuantity(p.quantity);
                                setPrice(p.price);
                                setDeposite(p.deposite);
                                setFinalPayment(p.finalPayment);
                                setPaymentStatus(p.paymentStatus);
                                setOrderStatus(p.orderStatus);
                                setRemark(p.remark)
                            });
                        }
                    }).catch(err => {
                        console.error(err);
                        //setLoading(false);
                        alert("好似有啲問題...");
                    });
                }
                setInit(true)
            }
        }else if(req.mode == "new" && init){
            if (prevProducts.current !== products) {
                console.log("products " + products.length)
                prevProducts.current = products
                selectProductFunc(products)
            } else if (prevIndex.current !== index) {
                console.log("index " + index)
                setProductId(products[index]._id)
                setProduct(products[index].name)
                setPrice(products[index].pccPrice)
                prevIndex.current = index
            } else if (prevProductType.current !== productType) {
                console.log("productType " + productType)
                prevProductType.current = productType
                setProductId("")
                getProduct()
            } else if (prevProductCat.current !== productCat) {
                console.log("productCat " + productCat)
                prevProductCat.current = productCat
                setProductId("")
                getProduct()
            }
        }
    }, [products, productType, productCat, index])

    const setKey = () => {
        let key = ""
        key = "productType=" + productType + "&productCat=" + productCat;
        // console.log(key)
        return key;
    }

    const request = (url, key, mode) => {
        axios.get(url + key, {
            headers: {
                'Authorization': `Basic ${user.token}`
            }
        }).then(res => {
            // setLoading(false);
            console.log(res);
            if (mode == "products" && res.data.products != null) {
                setProducts(res.data.products)
            }

        }).catch(err => {
            console.error(err);
            // setLoading(false);
            alert("好似有啲問題...");
        });
    }

    const getProduct = () => {
        if (productCat != "" && productType != "") {
            request('/api/product/getProduct?', setKey(), "products")
        }
    }

    let setPriceFunc = (value) => {
        setPrice(value)
        setFinalPayment(value - deposite)
    }

    let setDepositeFunc = (value) => {
        setDeposite(value)
        setFinalPayment(price - value)
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
        resetSelectProduct();
    };

    const resetSelectProduct = () => {
        setProductId("")
        setProduct("")
        setPaymentStatus("")
        prevIndex.current = -1
        setIndex(-1)
    }

    const selectProductFunc = (pos) => {
        console.log(pos)
        if (pos == null) {
            setSelectProduct(<div>Please select the type and cat first</div>)
            resetSelectProduct()
        } else if (pos.length == 0) {
            setSelectProduct(<div>No product under this cat and type</div>)
            resetSelectProduct()
        } else {
            let count = -1
            // setSelectProduct(<select className="form-select" aria-label="product" onChange={e => setIndex(e.target.value)}>
            //     <option value={-1} disabled selected="selected">請選擇貨品</option>
            //     {pos.map(product => {
            //         count++
            //         return <option value={count}>{product.name + " - bacth" + product.batch}</option>
            //     })}
            // </select>)
            let options = [];
            for (let i = 0; i < pos.length; i++) {
                options.push({ lable: pos[i].name + " - bacth" + pos[i].batch, id: i })
            }
            setProductOptions(options)
            console.log(productOptions)
            console.log(options)
            // while(productOptions.length<1){

            // }
            refreshSelectProduct(options)

        }
    }

    let refreshSelectProduct = (options) => {
        setSelectProduct(<Autocomplete
            disablePortal
            id="select-product"
            options={options}
            getOptionLabel={(option) => (option.lable)}
            onChange={onProductChange}
            style={{ height: "54px" }}
            renderInput={(params) => <TextField {...params} label="貨" size="small" />}
        />)
    }

    let onProductChange = (event, values) => {
        console.log(values)
        setIndex(values.id)
    }

    let onUpload = (e) => {
        e.preventDefault();

        let canUpload = true;
        if (name == "") {
            canUpload = false;
        }
        if (shippingAddress == "") {
            canUpload = false;
        }
        if (productType == "") {
            canUpload = false;
        }
        if (product == "") {
            canUpload = false;
        }
        if (price == 0.0) {
            canUpload = false;
        }
        if (paymentStatus == "") {
            canUpload = false;
        }
        if (orderStatus == "") {
            canUpload = false;
        }

        if (canUpload) {

            let preorder = {
                name: name,
                shippingAddress: shippingAddress,
                phone: phone,
                productType: productType,
                productCat: productCat,
                product: product,
                productId: productId,
                quantity: quantity,
                price: price,
                deposite: deposite,
                finalPayment: finalPayment,
                paymentStatus: paymentStatus,
                orderStatus: orderStatus,
                remark: remark
            }
            if (isEdit) {
                console.log("send edit");
                axios.post("/api/preorder/newEditPreorder", { preorder: preorder, id: req.id }, {
                    headers: {
                        'Authorization': `Basic ${user.token}`
                    }
                }).then(res => {
                    if (res.data.message === "success") {
                        alert("Edit Success");
                    } else {
                        alert("fail " + res.data.error);
                    }
                });
            } else {
                console.log("send create");
                axios.post("/api/preorder/newNewPreorder", preorder, {
                    headers: {
                        'Authorization': `Basic ${user.token}`
                    }
                }).then(res => {
                    if (res.data.message === "success") {
                        alert("Upload Success");
                    } else {
                        alert("fail " + res.data.error);
                    }
                })
            }

        } else {
            alert("請正確輸入所有資料")
        }

    }

    return (
        <div className="card m-2">
            <div className='card-title m-2'>
                <h2>電話號碼: {phone}</h2>
            </div>

            <div className='card-body'>
                <hr />
                <div className="p-2">
                    <label for="name" class="form-label">收件人名</label>
                    <input type="text" class="form-control" value={name} placeholder="收件人名" onChange={e => setName(e.target.value)} />
                </div>
                <hr />
                <div className="p-2">
                    <label for="shippingAddress" class="form-label">收貨地址</label>
                    <input type="text" class="form-control" value={shippingAddress} placeholder="收貨地址" onChange={e => setShippingAddress(e.target.value)} />
                </div>
                <hr />
                <div>貨</div>
                {req.mode == "new" ?
                <div className="card p-2 m-2">
                    <div className="row">

                        <div className="col-md-4">

                            <div className="p-2">
                                <select className="form-select" aria-label="productType" value={productType} onChange={e => setProductType(e.target.value)}>
                                    <option value="" disabled selected="selected"> 商品類型:</option>
                                    <option value="booster">Booster</option>
                                    <option value="stater">預組</option>
                                    <option value="sleeve">卡套</option>
                                    <option value="box">卡盒</option>
                                    <option value="playmat">Playmat</option>
                                    <option value="giftBox">禮盒</option>
                                    <option value="VJump">VJump</option>
                                    <option value="sundry">雜項</option>
                                    <option value="other">其他</option>
                                </select>
                            </div>
                        </div>
                        {productType != "" ?
                            <div className="col-md-4">
                                <div className="p-2">
                                    <select className="form-select" aria-label="productCat" value={productCat} onChange={e => setProductCat(e.target.value)}>
                                        <option value="" disabled selected="selected"> 商品類別</option>
                                        <option value="PKM">PKM</option>
                                        <option value="DGM">DGM</option>
                                        <option value="YGO">YGO</option>
                                        <option value="WS">WS</option>
                                        <option value="SV">SV</option>
                                        <option value="OP">One Piece</option>
                                        <option value="DragonShield">龍盾</option>
                                        <option value="King">King</option>
                                        <option value="RSS">RSS</option>
                                        <option value="KMC">KMC</option>
                                        <option value="Broccoli">Broccoli</option>
                                        <option value="UG">UG</option>
                                        <option value="other">其他</option>
                                    </select>
                                </div>
                            </div>
                            : null}
                        {productCat != "" ?
                            <div className="col-md-4">
                                <div className="p-2">
                                    {selectProduct}
                                </div>
                            </div>
                            : null}

                    </div>

                </div >
                : 
                <div className="card p-2 m-2">
                    <div className="row">

                        <div className="col-md-4">
                            商品類型:
                            {productType}
                        </div>
                        <div className="col-md-4">
                            商品類別: 
                            {productCat}
                        </div>
                        <div className="col-md-4">
                            貨名: 
                            {product}
                        </div>
                            

                    </div>

                </div >
                }
                <hr />
                <div className="p-2">
                    <label for="quantity" class="form-label">數量</label>
                    <input type="number" min="1" step="1" pattern="[0-9]*" inputmode="numeric" class="form-control" value={quantity} placeholder="數量" onChange={e => setQuantity(e.target.value)} />
                </div>
                <hr />
                <div className="p-2">
                    <label for="price" class="form-label">價錢</label>
                    <input type="number" min="0.1" step="0.1" pattern="[0-9]*" inputmode="numeric" class="form-control" value={price} placeholder="價錢" onChange={e => setPriceFunc(e.target.value)} />
                </div>
                <hr />
                {paymentStatus=="未付款"? 
                <div className="p-2">
                    <label for="deposite" class="form-label">訂金</label>
                    <input type="number" min="0.1" step="0.1" pattern="[0-9]*" inputmode="numeric" class="form-control" value={deposite} placeholder="訂金" onChange={e => setDepositeFunc(e.target.value)} />
                </div>: 
                <div className="p-2">
                    <label for="deposite" class="form-label">訂金</label>
                    <div>{deposite}</div>
                </div>}
                <hr />
                <div className="p-2">
                    <label for="finalPayment" class="form-label">尾數</label>
                    <input type="number" min="0.1" step="0.1" pattern="[0-9]*" inputmode="numeric" class="form-control" value={finalPayment} placeholder="尾數" onChange={e => setFinalPayment(e.target.value)} />
                </div>
                <hr />
                {/* <div className="p-2">
                    <label for="paymentStatus" class="form-label">付款狀態</label>
                    <select class="form-select" aria-label="paymentStatus" value={paymentStatus} onChange={e => setPaymentStatus(e.target.value)}>
                        <option disabled value=""> 付款狀態 </option>
                        <option value="未付款">未付款</option>
                        <option value="已付訂金">已付訂金</option>
                        <option value="已全數付款">已全數付款</option>
                        <option value="要求退款">要求退款</option>
                        <option value="已退款">已退款</option>
                    </select>
                </div>
                <hr /> */}
                <div className="p-2">
                    <label for="orderStatus" class="form-label">訂單狀態</label>
                    <select class="form-select" aria-label="orderStatus" value={orderStatus} onChange={e => setOrderStatus(e.target.value)}>
                        <option disabled value=""> 訂單狀態 </option>
                        <option value="等待付款">等待付款</option>
                        <option value="訂貨中">等候訂貨</option>
                        <option value="訂貨中">訂貨中</option>
                        <option value="已到貨，等侯合拼寄送">已到貨，等侯寄送</option>
                        <option value="寄出">寄出</option>
                        <option value="完成">完成</option>
                        <option value="取消訂單">取消訂單</option>
                    </select>
                </div>
                <hr />
                <div className="p-2">
                    <label for="remark" class="form-label">備註</label>
                    <textarea class="form-control" id="remark" rows="3" value={remark} onChange={e => setRemark(e.target.value)}></textarea>
                </div>
                <hr />

                <button type='submit' className="btn btn-success btn-large m-2" onClick={e => onUpload(e)}>Upload</button>

            </div>

        </div>
    );
}

export default NewPreorderDetailDialog;
