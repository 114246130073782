import React, { useState, useEffect } from 'react';
import { render } from 'react-dom';
import axios from 'axios';
import Breadcrumb from '../components/Breadcrumb';

const ShopActivityScreen = () => {

    const [loading, setLoading] = useState(true);

    const [error, setError] = useState("");

    const [posts, setPosts] = useState([]);

    useEffect(() => {
        axios.get('/api/post/getPost').then(res => {
            setLoading(false);
            //console.log(res);
            setPosts(res.data.post);
        }).catch(err => {
            console.error(err);
            setLoading(false);
            setError("好似有啲問題...");
        });
    }, [])



    return (
        <>
        <Breadcrumb />
        <div>
            <div id="fb-root"></div>
            <script async defer crossorigin="anonymous" src="https://connect.facebook.net/zh_HK/sdk.js#xfbml=1&version=v12.0&appId=375711022863773&autoLogAppEvents=1" nonce="JRi4euX9"></script>
            <div className="container-fluid" >
                {posts.map(post => {
                    return <div className="row" style={{width:"530px",height:(post.height+18)}}>
                        <iframe src={post.url}
                            width="512"
                            height={post.height}
                            style={{ border: "none", overflow: "hidden", paddingLeft: "20px" }}
                            scrolling="no"
                            frameBorder="0"
                            allowFullScreen="true"
                            allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
                            <br />
                    </div>
                })}
            </div>

        </div>
        </>
    );

}

export default ShopActivityScreen;