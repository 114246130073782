import React from 'react';
import axios from 'axios';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { KeyboardCapslockSharp, Receipt } from '@mui/icons-material';
import { height } from '@mui/system';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import ProductDetailDialog from '../components/ProductDetailDialog';
import Breadcrumb from '../components/Breadcrumb';


const ProductScreen = () => {

    const user = JSON.parse(localStorage.getItem("user"));

    if (!user || (user.role.indexOf('admin') == -1 && user.role.indexOf('manager') == -1 && user.role.indexOf('shopkeeper') == -1)) {
        window.location.href = "/admin";
    }

    const [productName, setProductName] = useState("");

    const [productType, setProductType] = useState("all");

    const [productCat, setProductCat] = useState("all");

    const [isActive, setIsActive] = useState("all");

    const [clicked, setClicked] = useState(true);

    const [products, setProducts] = useState([]);

    const [open, setOpen] = React.useState(false);

    const [openDetail, setOpenDetail] = React.useState(false);

    const [selectedPreorder, setSelectedPreorder] = React.useState([]);

    const [productDetail, setProductDetail] = React.useState({});

    const [tableBody, setTableBody] = React.useState();

    const [orderCount, setOrderCount] = React.useState(0);

    const [orderBy, setOrderBy] = React.useState(0);

    

    // let selectedPreorder=[];

    useEffect(() => {
        renderTableBody(products)
    }, [products]);


    const onSearch = () => {
        // match.params.phone=null;
        setClicked(false)
        request(setKey())

    }

    const setKey = () => {
        var key = ""
        if (productName != "") {
            key += "productName=" + productName;
        }

        if (productType != "all") {
            if (key != "") {
                key += "&"
            }
            key += "productType=" + productType;
        }

        if (productCat != "all") {
            if (key != "") {
                key += "&"
            }
            key += "productCat=" + productCat;
        }
        // console.log(key)
        return key;
    }

    const selected = (e) => {
        let array = selectedPreorder
        if (e.target.checked) {
            array.push(e.target.value)
        } else {
            var i = array.indexOf(e.target.value);
            array.splice(i, 1);
        }
        setSelectedPreorder(array);
        console.log(selectedPreorder);

    }

    const request = (key) => {
        axios.get('/api/product/getProduct?' + key, {
            headers: {
                'Authorization': `Basic ${user.token}`
            }
        }).then(res => {
            // setLoading(false);
            console.log(res);
            if (res.data.products != null) {
                setProducts(res.data.products);
            }

        }).catch(err => {
            console.error(err);
            // setLoading(false);
            alert("好似有啲問題...");
        });
    }



    const formatDate = (date) => {
        let formattedDate = new Date(date)
        return formattedDate.toLocaleString()
    }

    const newProductButton = () => {

        if(productName!=""){
            return <button className="btn btn-success" style={{ marginTop: "7px", width: "100%" }} onClick={e => handleClickOpenDetail("new", productName, null, null, null, null, null, null, null, null)} >新貨</button>
        }else{
            return <button className="btn btn-success" style={{ marginTop: "7px", width: "100%" }} onClick={e => handleClickOpenDetail("new", "", null, null, null, null, null, null, null, null)} >新貨</button>
        }
    

        // if (products != null && products.length > 0) {
        //     let product = products[products.length - 1];
        //     //urlParam=`${phone}/${preorder.name}/${preorder.shippingAddress}`
        //     return <button className="btn btn-success" style={{ marginTop: "7px", width: "100%" }} onClick={e => handleClickOpenDetail("new", product.name, null, product.type, product.cat, null, null, null, null)} >新貨</button>
        // } else {
        //     return <button className="btn btn-success" style={{ marginTop: "7px", width: "100%" }} onClick={e => handleClickOpenDetail("new", productName, null, null, null, null, null, null, null)} >新貨</button>
        // }

        //return <Link to={{ pathname: `/admin/newPreorder/${urlParam}` }} ><a className="btn btn-success" style={{marginTop: "10px", width:"100%"}}>新訂單</a></Link>

    }

    const sortProducts = (value) => {
        if (value == orderBy) {
            setOrderCount(orderCount + 1);
        } else {
            setOrderCount(1);
        }
        let sorted = products.sort(function (a, b) {
            if (orderCount % 2 == 1) {
                return a[value] < b[value] ? 1 : -1;
            } else if (orderCount % 2 == 0) {
                return a[value] > b[value] ? 1 : -1;
            }

        });
        console.log(sorted)
        setProducts(sorted);
        setOrderBy(value)
        renderTableBody(products);
    }

    const renderTableBody = (pos) => {
        setTableBody(<tbody>
            {pos.map(product => {
                
                if(product.type=="bulkCard"){
                    return null;
                }
                index++;
                return <tr>
                    <th><input type="checkbox" id="_id" value={product._id} onChange={e => selected(e)} /></th>
                    <th scope="row">{index}</th>
                    <td>{product.name}</td>
                    <td>{product.type}</td>
                    <td>{product.cat}</td>
                    <td>{product.batch}</td>
                    <td>{product.description}</td>
                    <td>${product.suggestedPrice}</td>
                    <td>${product.pccPrice}</td>
                    <td>{product.stock}</td>
                    <td>{product.isActive ? <div>已上架</div> : <div>已下架</div>}</td>
                    <td>{product.createdDate}</td>
                    <td>{product.modifiedDate}</td>
                    {product.unit?
                    <td><button className="btn btn-success" style={{ width: "100%" }} value={product._id} onClick={e => handleClickOpenDetail("edit", product.name, product._id, product.type, product.cat, product.batch, product.description, product.suggestedPrice, product.pccPrice, product.stock, product.unit.carton, product.unit.box, product.isActive, product.activeDate)} >Edit</button> </td>
                    :
                    <td><button className="btn btn-success" style={{ width: "100%" }} value={product._id} onClick={e => handleClickOpenDetail("edit", product.name, product._id, product.type, product.cat, product.batch, product.description, product.suggestedPrice, product.pccPrice, product.stock, null, null, product.isActive, product.activeDate)} >Edit</button> </td>
                    }
                </tr>
            })}
        </tbody>)
    }



    const resetSearch = () => {
        window.location.href = "/admin/product";
    }


    const handleClickOpenDetail = (mode, name, id, type, cat, batch, description, suggestedPrice, pccPrice, stock, carton, box, isActive, activeDate) => {
        setOpenDetail(true);
        let req = {};
        if (mode == "edit") {
            req = { mode, name, id, type, cat, batch, description, suggestedPrice, pccPrice, stock, carton, box, isActive, activeDate }
        } else {
            req = { mode, name, type, cat }
        }
        setProductDetail(req)
    };

    const handleCloseDetail = () => {
        setOpenDetail(false);
        request(setKey())
    };

    let productDetailDialog = <div>
        <Dialog
            open={openDetail}
            onClose={handleCloseDetail}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth
            maxWidth={"md"}
        >
            <DialogTitle id="alert-dialog-title">
            </DialogTitle>
            <DialogContent>
                {console.log(productDetail)}
                <ProductDetailDialog req={productDetail} />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCloseDetail}>關閉</Button>
            </DialogActions>
        </Dialog>
    </div>

    var index = 0;

    return (
        <>
        <Breadcrumb />
        <div className="container-fluid">
            {productDetailDialog}
            <h2>貨</h2>
            <div className="card p-2 m-2">
                <div className="row">
                    <div className="col-md-4">
                        <div className="input-group" >
                            <div className="input-group-prepend" style={{ marginTop: "0px" }}>
                                {/* <span className="input-group-text" id="basic-addon1">@</span> */}
                                <select className="form-select" style={{ marginTop: "10px", height: "38px" }} aria-label="productType" >
                                    <option value="productName"> 貨名 </option>
                                </select>

                            </div>

                            <input className="form-control" style={{ margin: "0px", height: "38px" }} type="text" value={productName} placeholder="貨名" aria-label="Search" onChange={e => setProductName(e.target.value)} onKeyPress={event => {
                                if (event.key === 'Enter') {
                                    onSearch()
                                }
                            }} />
                        </div>
                    </div>
                    <div className="col-md-4">

                        <div className="p-2">
                            <select className="form-select" aria-label="productType" value={productType} onChange={e => setProductType(e.target.value)}>
                                <option value="all"> 商品類型: 全部 </option>
                                <option value="booster">Booster</option>
                                <option value="stater">預組</option>
                                <option value="sleeve">卡套</option>
                                <option value="box">卡盒</option>
                                <option value="playmat">Playmat</option>
                                <option value="giftBox">禮盒</option>
                                <option value="VJump">VJump</option>
                                <option value="sundry">雜項</option>
                                <option value="other">其他</option>
                            </select>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="p-2">
                            <select className="form-select" aria-label="productCat" value={productCat} onChange={e => setProductCat(e.target.value)}>
                                <option value="all"> 商品類別: 全部 </option>
                                <option value="PKM">PKM</option>
                                <option value="DGM">DGM</option>
                                <option value="YGO">YGO</option>
                                <option value="WS">WS</option>
                                <option value="SV">SV</option>
                                <option value="OP">One Piece</option>
                                <option value="DragonShield">龍盾</option>
                                <option value="King">King</option>
                                <option value="RSS">RSS</option>
                                <option value="KMC">KMC</option>
                                <option value="Broccoli">Broccoli</option>
                                <option value="UG">UG</option>
                                <option value="other">其他</option>
                            </select>
                        </div>
                    </div>

                </div>
                <div className="row">
                    <div className="col-md-4">
                        <div className="p-2">
                            <button className="btn btn-warning" style={{ marginTop: "7px", width: "100%" }} onClick={e => resetSearch()}>重設</button>
                            {/* {
                                    productType==='all' && orderStatus==='all' && (phone === '' || name === '')? 
                                        <button className="btn btn-warning" style={{marginTop: "7px", width:"100%"}}  disabled>重設</button> 
                                    :
                                    <button className="btn btn-warning" style={{marginTop: "7px", width:"100%"}}  onClick={e=>resetSearch()}>重設</button> 
                                } */}
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="p-2">
                            <button className="btn btn-primary" style={{ marginTop: "7px", width: "100%" }} onClick={e => onSearch()} >搜尋</button>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="p-2">
                            {newProductButton()}
                        </div>
                    </div>
                </div>
            </div>
            <div className="card p-2 m-2" >
                {products.length > 0 ?
                    <div style={{ display: "flex", overflow: "auto" }}>
                        <table className="table table-hover" >
                            <thead >
                                <tr>
                                    <th></th>
                                    <th scope="col">#</th>
                                    <th scope="col" onClick={() => sortProducts("name", "desc")}>貨名</th>
                                    <th scope="col" >商品類型</th>
                                    <th scope="col" >商品類別</th>
                                    <th scope="col" >Batch</th>
                                    <th scope="col" >描述</th>
                                    <th scope="col" onClick={() => sortProducts("suggestedPrice", "desc")}>官方價</th>
                                    <th scope="col" onClick={() => sortProducts("pccPrice", "desc")}>PCC價</th>
                                    <th scope="col" >存貨</th>
                                    <th scope="col" >上架</th>
                                    <th scope="col" onClick={() => sortProducts("createdDate", "desc")}>建立日期</th>
                                    <th scope="col" onClick={() => sortProducts("modifiedDate", "desc")}>修改日期</th>
                                    <th scope="col"></th>
                                </tr>
                            </thead>
                            {tableBody}
                        </table>
                    </div>



                    : clicked ? "搜尋先" : "冇記錄"


                }
            </div>


        </div>
        </>
    )
}

export default ProductScreen;