import axios from 'axios';
import React from 'react';
import { useState, useEffect } from 'react';
import Card from '../components/Card';
import Pagination from '@mui/material/Pagination';





const CardListScreen = ({ match }) => {
    let condition = {};
    if (match.params.condition == "cardSeries") {
        condition = { condition: { cardSeries: match.params.param } }
    } else if (match.params.condition == "cardType") {
        condition = { condition: { cardType: match.params.param } }
    } else if (match.params.condition == "cardName") {
        if(match.params.matchType == "exact"){
            condition = { condition: { cardName: match.params.param } }
        }else{
            condition = { condition: { cardName: { $regex: '.*' + match.params.param + '.*' } } }
        }
        
    } 
    // console.log(condition);

    const [loading, setLoading] = useState(true);

    const [error, setError] = useState("");

    const [cards, setCards] = useState([]);

    const [ page, setPage ] = useState(0);

    const [ currentPage, setCurrentPage ] = useState(1);

    useEffect(() => {
        axios.post('/api/card/getAllCardByCondition?pageSize=16&currentPage='+currentPage, condition).then(res => {
            setLoading(false);
            //console.log(res);
            setCards(res.data.cards);
            setPage(Math.ceil(res.data.cardCount/16))
        }).catch(err => {
            console.error(err);
            setLoading(false);
            setError("好似有啲問題...");
        });
    }, [currentPage])

    const changePage=(event, value)=>{
        setLoading(true);
        setCurrentPage(value);
    }


    return (
        <div className="container-fluid">

            <div className="row">
                {loading ? (
                    <div className="spinner-border text-primary m-3" role="status">

                    </div>
                ) : error != "" ? (
                    <h1>未有野睇住</h1>
                ) : cards.length === 0 ? (
                    <h1>未有野睇住</h1>
                ) : (
                    <div className="row" style={{ marginTop: "5px" }}>
                        <div style={{alignContent:"center", display: "flex", justifyContent: "center"}}>
                            <Pagination count={page} shape="rounded"  page={currentPage} boundaryCount={2} onChange={changePage}/>
                        </div>
                        {cards.map(card => {

                            return <Card card={card} key={card._id} />
                        })}
                        <div style={{alignContent:"center", display: "flex", justifyContent: "center", marginBottom:"10px"}}>
                            <Pagination count={page} shape="rounded"  page={currentPage} boundaryCount={2} onChange={changePage}/>
                        </div>
                    </div>

                )}
            </div>
        </div>
    );
}

export default CardListScreen;
