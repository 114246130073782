import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { registerUser } from '../actions/UserAction';
import Breadcrumb from '../components/Breadcrumb';

const RegisterScreen = () => {

    const user = JSON.parse(localStorage.getItem("user"));

    if(!user || user.role.indexOf('admin')==-1 ){
        window.location.href="/login";
    }

    const registerUserState = useSelector(state => state.registerUserReducer);

    const { loading, success, error } = registerUserState;

    const dispatch = useDispatch();

    

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPwd, setConfirmPwd] = useState("");
    const [role, setRole] = useState([""]);
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [name, setName] = useState("");
    const [commission, setCommission] = useState("");
    const [commissionText, setCommissionText] = useState("抽佣");
    // const [remark, setRemark] = useState({});
    const [isActive, setIsActive] = useState("");


    function register(e) {
        e.preventDefault();
        const newUser = {
            username: username,
            name:name,
            password: password,
            phone:phone,
            email: email,
            role:role,
            remark:{}
        }
        if(commission!=""){
            newUser.remark={commission:parseInt(commission)}
        }
        
        if (password === confirmPwd) {
            alert("success")
            dispatch(registerUser(newUser, {headers: {
                'Authorization': `Basic ${user.token}` 
              }}));
        } else {
            alert("Password not match")
        }
    }

    let addRoles = () => {
        setRole([...role, ""]);
    }

    let handleChange = (i, e) => {
        if(role[i]=="seller"){
            setCommission("%")
        }
        let newRole = [...role];
        newRole[i] = e.target.value;
        setRole(newRole);
    }

    let removeRoles = (i) => {
        if(role[i]=="seller"){
            setCommission("%")
        }
        let newRole = [...role];
        newRole.splice(i, 1);
        setRole(newRole)
    }

    var showRolesSelect = role.map((element, index) => {
        return <div className="row">
            <div className="col-md-6">
                <select class="form-select" aria-label="specialType" value={role[index]} onChange={e => handleChange(index, e)} key={index}>
                    <option disabled value=""> -- select an option -- </option>
                    <option value="manager">Manager</option>
                    <option value="shopkeeper">Shopkeeper</option>
                    <option value="seller">賣家(寄賣簿)</option>
                    <option value="supplier">Supplier</option>
                </select>
            </div>
            <div className="col-md-6">
                <button className="btn btn-outline-danger" onClick={() => removeRoles(index)}>刪除</button>
            </div>
        </div>
    })

    const handleChangeCommission = (value) => {
        setCommission(value)
        setCommissionText("抽佣"+value+"%")
    }

    return (
        <>
        <Breadcrumb />
        <div>
            <div className="row justify-content-center " style={{ marginTop: "150px" }}>
                <div className="col-md-5 card p-5">
                    <div className="div">
                        <h2 className="text-center">Register</h2>
                        <form onSubmit={register}>
                            <input type="text" placeholder="username" className="form-control" value={username} onChange={(e) => { setUsername(e.target.value) }} required />
                            <input type="text" placeholder="name" className="form-control" value={name} onChange={(e) => { setName(e.target.value) }} required />
                            <input type="password" placeholder="Password" className="form-control" value={password} onChange={(e) => { setPassword(e.target.value) }} required />
                            <input type="password" placeholder="Confirm Password" className="form-control" value={confirmPwd} onChange={(e) => { setConfirmPwd(e.target.value) }} required />
                            <input type="number" pattern="^([9]|[6]|[5])[0-9][0-9][0-9][0-9][0-9][0-9]" max="99999999" placeholder="Phone" className="form-control" value={phone} onChange={(e) => { setPhone(e.target.value) }} required />
                            <input type="email" pattern=".+@.+\..+" placeholder="Email" className="form-control" value={email} onChange={(e) => { setEmail(e.target.value) }} required />
                            {/* <input type="text"  placeholder="Role" className="form-control" value={email} onChange={(e) => { setEmail(e.target.value) }} required /> */}
                            <div className='card p-3'>
                                <div className="row">
                                    <div className="col-md-6">
                                        <label for="specialType" class="form-label">Roles</label>
                                    </div>
                                    <div className="col-md-6">
                                        <a className="btn btn-outline-success" onClick={() => addRoles()}>增加</a>
                                    </div>
                                </div>
                                {showRolesSelect}
                            </div>
                            {role.indexOf("seller")>=0 ?
                                <div className='card p-3'>
                                    <label for="commission" class="form-label">{commissionText}</label>
                                    <input type="text"  placeholder="抽佣" className="form-control" min="0" value={commission} onChange={(e) => { handleChangeCommission(e.target.value) }} required />
                                </div>
                            : null}
                            <button className="btn btn-dark mt-3" type="submit" >Register</button>
                        </form>
                    </div>
                </div>


            </div>

        </div>
        </>
    )
}

export default RegisterScreen;