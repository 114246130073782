import React from 'react';
import axios from 'axios';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { KeyboardCapslockSharp, Receipt } from '@mui/icons-material';
import { height } from '@mui/system';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import ReceiptDialog from '../components/ReceiptDialog';
import NewPreorderDetailDialog from '../components/NewPreorderDetailDialog';
import Breadcrumb from '../components/Breadcrumb';

const NewPreOrderScreen = () => {

    const user = JSON.parse(localStorage.getItem("user"));

    if(!user || (user.role.indexOf('admin') == -1 && user.role.indexOf('manager') == -1 && user.role.indexOf('shopkeeper') == -1)){
        window.location.href="/login";
    }

    const [phone, setPhone]=useState("");

    const [name, setName]=useState("");

    const [include, setInclude]=useState("");

    const [searchBy, setSearchBy]=useState("phone");

    const [productType, setProductType]=useState("all");

    const [orderStatus, setOrderStatus]=useState("all");

    const [clicked, setClicked]=useState(true);

    const [preorders, setPreorders] = useState([]);

    const [open, setOpen] = React.useState(false);

    const [openDetail, setOpenDetail] = React.useState(false);

    const [selectedPreorder, setSelectedPreorder] = React.useState([]);
 
    const [preorderDetail, setPreorderDetail] = React.useState({});

    const [tableBody, setTableBody] = React.useState();

    const [orderCount, setOrderCount] = React.useState(0);

    const [orderBy, setOrderBy] = React.useState(0);

    // let selectedPreorder=[];

    useEffect(() => {
        renderTableBody(preorders)
    }, [preorders]);


    const onSearch=()=>{
        // match.params.phone=null;
        request(setKey())
        
    }

    const setKey=()=>{
        if(phone.length==8){
            setClicked(false);
        }
        var key=""
        if(searchBy=="phone"){
            if(phone!=""){
                key+="phone="+phone;
            }
        }else if(searchBy=="name"){
            if(name!=""){
                key+="name="+name;
            }
        }else if(searchBy=="include"){
            if(include!=""){
                key+="include="+include;
            }
        }
        
        if(productType!="all"){
            if(key!=""){
                key+="&"
            }
            key+="productType="+productType;
        }
        if(orderStatus!="all"){
            if(key!=""){
                key+="&"
            }
            key+="orderStatus="+orderStatus;
        }
        // console.log(key)
        return key;
    }

    const selected=(e)=>{
        let array=selectedPreorder
        if(e.target.checked){
            array.push(e.target.value)
        }else{
            var i=array.indexOf(e.target.value);
            array.splice(i,1);
        }
        setSelectedPreorder(array);
        console.log(selectedPreorder);
        
    }

    const request=(key)=>{
        axios.get('/api/preorder/getPreorder?'+key, {
            headers: {
                'Authorization': `Basic ${user.token}`
            }
        }).then(res => {
            // setLoading(false);
            console.log(res);
            setPreorders(res.data.preorder);
        }).catch(err => {
            console.error(err);
            // setLoading(false);
            alert("好似有啲問題...");
        });
    }

    

    const formatDate = (date) => {
        let formattedDate = new Date(date)
        return formattedDate.toLocaleString()
    }

    const newPreorderButton = () =>{
    
        let urlParam=phone;
        if(preorders.length>0){
            let preorder = preorders[preorders.length-1];
            //urlParam=`${phone}/${preorder.name}/${preorder.shippingAddress}`
            return <button className="btn btn-success" style={{marginTop: "7px", width:"100%"}}  onClick={e=>handleClickOpenDetail("new", phone, null, preorder.name, preorder.shippingAddress)} >新訂單</button>
        }else{
            return <button className="btn btn-success" style={{marginTop: "7px", width:"100%"}}  onClick={e=>handleClickOpenDetail("new", phone, null, null, null)} >新訂單</button>
        }

        //return <Link to={{ pathname: `/admin/newPreorder/${urlParam}` }} ><a className="btn btn-success" style={{marginTop: "10px", width:"100%"}}>新訂單</a></Link>

    }

    const sortPreorders = (value) => {
        if(value == orderBy){
            setOrderCount(orderCount+1);
        }else{
            setOrderCount(1);
        }
        let sorted=preorders.sort(function(a, b) {
            if(orderCount%2==1){
                return a[value] < b[value] ? 1 : -1;
            }else if (orderCount%2==0){
                return a[value] > b[value] ? 1 : -1;
            }
            
        });
        console.log(sorted)
        setPreorders(sorted);
        setOrderBy(value)
        renderTableBody(preorders);
    }

    const renderTableBody = (pos) => {
        setTableBody(<tbody>
        {pos.map(preorder => { 
            index++;
            return <tr>
                <th><input type="checkbox" id="_id" value={preorder._id} onChange={e=>selected(e)}/></th>
                <th scope="row">{index}</th>
                {clicked ? <td>{preorder.phone}</td>: null}
                <td>{preorder.name}</td>
                <td>{preorder.shippingAddress }</td>
                {preorder.productId?
                    <td>{preorder.productCat}</td>
                :
                    <td>{preorder.productType}</td>
                }
                <td>{preorder.product}</td>
                <td>{preorder.quantity}</td>
                <td>${preorder.price}</td>
                <td>${preorder.deposite}</td>
                <td>${preorder.finalPayment}</td>
                {
                    preorder.paymentStatus!="已全數付款"?
                    <td><font color="red">{preorder.paymentStatus}</font></td> 
                    :
                    <td><font color="green">{preorder.paymentStatus}</font></td> 
                }
                {
                    preorder.orderStatus!="完成"?
                    <td><font color="red">{preorder.orderStatus}</font></td> 
                    :
                    <td><font color="green">{preorder.orderStatus}</font></td> 
                }

                <td>{preorder.remark}</td>
                <td>{formatDate(preorder.createdDate)}</td>
                <td>{formatDate(preorder.modifiedDate)}</td>
                { preorder.productId? 
                    <>
                        {preorder.paymentStatus=="未付款"?
                            <td><button className="btn btn-warning" style={{width:"100%"}}  value={preorder._id} onClick={e=>handleDeposit(preorder._id)} >已付訂金</button> </td>
                        : null}
                        {preorder.paymentStatus=="已付訂金"?
                            <td><button className="btn btn-warning" style={{width:"100%"}}  value={preorder._id} onClick={e=>handleFinalPayment(preorder._id)} >已付尾數</button> </td>
                        : null}
                        <td><button className="btn btn-success" style={{width:"100%"}}  value={preorder._id} onClick={e=>handleClickOpenDetail("edit", preorder.phone, preorder._id, preorder.name, preorder.shippingAddress)} >Edit</button> </td>
                    </>
                : 
                    <>
                        <td>請用舊系統</td>
                        <td></td>
                    </>
                }
                
                {/* <td><Link to={{ pathname: `/admin/editPreorder/${preorder._id}` }} ><a className="btn btn-success" style={{width:"100%"}}>Edit</a></Link></td> */}
            </tr>
        })}
    </tbody>)
    }

    const handleDeposit=(id)=>{
        axios.post('/api/preorder/editPreorderDeposite', { id: id }, {
            headers: {
                'Authorization': `Basic ${user.token}`
            }
        }).then(res => {
            if (res.data.message === "success") {
                alert("Edit Success");
                request(setKey())
            } else {
                alert("fail " + res.data.error);
            }
        }).catch(err => {
            console.error(err);
            // setLoading(false);
            alert("好似有啲問題...");
        });
    }

    const handleFinalPayment=(id)=>{
        axios.post('/api/preorder/editPreorderfinalPayment', { id: id }, {
            headers: {
                'Authorization': `Basic ${user.token}`
            }
        }).then(res => {
            if (res.data.message === "success") {
                alert("Edit Success");
                request(setKey())
            } else {
                alert("fail " + res.data.error);
            }
        }).catch(err => {
            console.error(err);
            // setLoading(false);
            alert("好似有啲問題...");
        });
    }



    const resetSearch=()=>{
        window.location.href = "/admin/newPreorder";
    }

    const handleClickOpen = (e) => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    let receiptDialog = <div>
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth={"md"}
        >
            <DialogTitle id="alert-dialog-title">
            </DialogTitle>
            <DialogContent>
                {console.log(selectedPreorder)}
               <ReceiptDialog req={{id:selectedPreorder}} />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>關閉</Button>
            </DialogActions>
        </Dialog>
    </div>

    const handleClickOpenDetail = (mode, phone, id, name, shippingAddress) => {
        setOpenDetail(true);
        let req={};
        if(mode=="edit"){
            req={mode, id}
        }else{
            req={mode, phone, name, shippingAddress}
        }
        setPreorderDetail(req)
    };

    const handleCloseDetail = () => {
        setOpenDetail(false);
        request(setKey())
    };

    let preorderDetailDialog = <div>
        <Dialog
            open={openDetail}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth
            maxWidth={"md"}
        >
            <DialogTitle id="alert-dialog-title">
            </DialogTitle>
            <DialogContent>
                {console.log(preorderDetail)}
            <NewPreorderDetailDialog req={preorderDetail} />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCloseDetail}>關閉</Button>
            </DialogActions>
        </Dialog>
    </div>

    var index=0;

    return (
        <>
        <Breadcrumb />
        <div className="container-fluid">
            {receiptDialog}
            {preorderDetailDialog}
            <h2>訂購</h2>
            <div>**此頁是新版訂購頁面，部份功能不兼容舊訂單。如要使用舊訂單系統，請按此: <a href="/admin/preorder">舊訂單系統</a></div>
            <div className="card p-2 m-2">
                <div className="row">
                    <div className="col-md-4">
                    <div className="input-group" >
                            <div className="input-group-prepend" style={{marginTop: "0px"}}>
                                {/* <span className="input-group-text" id="basic-addon1">@</span> */}
                                <select className="form-select" style={{marginTop: "10px", height:"38px"}} aria-label="productType" value={searchBy} onChange={e => setSearchBy(e.target.value)}>
                                    <option value="phone"> 電話 </option>
                                    <option value="name"> 名 </option>
                                    <option value="include"> 包含 </option>
                                </select>

                            </div>
                            {searchBy=="phone" ? 
                                <input className="form-control" style={{margin: "0px", height:"38px"}}  type="number" pattern="[0-9]*" inputmode="numeric" value={phone} placeholder="電話號碼" aria-label="Search" onChange={e=>setPhone(e.target.value)} onKeyPress={event => {
                                    if (event.key === 'Enter') {
                                      onSearch()
                                    }
                                  }}/>
                                : searchBy == "name" ?
                                <input className="form-control" style={{margin: "0px", height:"38px"}} type="text"  value={name} placeholder="名" aria-label="Search" onChange={e=>setName(e.target.value)} onKeyPress={event => {
                                    if (event.key === 'Enter') {
                                      onSearch()
                                    }
                                }} />
                                : 
                                <input className="form-control" style={{margin: "0px", height:"38px"}} type="text"  value={include} placeholder="內容包含" aria-label="Search" onChange={e=>setInclude(e.target.value)} onKeyPress={event => {
                                    if (event.key === 'Enter') {
                                      onSearch()
                                    }
                                }} />
                            }
                        </div> 
                    </div>
                    <div className="col-md-4">   
                    
                        <div className="p-2">
                            <select className="form-select" aria-label="productType" value={productType} onChange={e => setProductType(e.target.value)}>
                                <option value="all"> 商品類別: 全部 </option>
                                <option value="PKM">PKM</option>
                                <option value="DGM">DGM</option>
                                <option value="YGO">YGO</option>
                                <option value="WS">WS</option>
                                <option value="SV">SV</option>
                                <option value="OP">One Piece</option>
                                <option value="King">King</option>
                                <option value="RSS">RSS</option>
                                <option value="KMC">KMC</option>
                                <option value="Broccoli">Broccoli</option>
                                <option value="UG">UG</option>
                                <option value="鑑卡">鑑卡</option>
                                <option value="other">other</option>
                            </select>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="p-2">
                            <select className="form-select" aria-label="orderStatus" value={orderStatus} onChange={e => setOrderStatus(e.target.value)}>
                                <option value="all"> 訂單狀態: 全部 </option>
                                <option value="等待付款">等待付款</option>
                                <option value="訂貨中">訂貨中</option>
                                <option value="已到貨，等侯合拼寄送">已到貨，等侯合拼寄送</option>
                                <option value="寄出">寄出</option>
                                <option value="完成">完成</option>
                                <option value="取消訂單">取消訂單</option>
                            </select>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-3">
                        <button className="btn btn-warning" style={{marginTop: "7px", width:"100%"}}  onClick={e=>resetSearch()}>重設</button>
                            {/* {
                                productType==='all'  && orderStatus==='all' && (phone === '' || name === '')? 
                                    <button className="btn btn-warning" style={{marginTop: "7px", width:"100%"}}  disabled>重設</button> 
                                :
                                <button className="btn btn-warning" style={{marginTop: "7px", width:"100%"}}  onClick={e=>resetSearch()}>重設</button> 
                            } */}

                        </div>
                        <div className="col-md-3">
                            <button className="btn btn-primary" style={{marginTop: "7px", width:"100%"}}  onClick={e=>onSearch()} >搜尋</button>
                        </div>
                        <div className="col-md-3">
                            {
                                clicked || phone === '' || phone.length!=8 ? 
                                    <button className="btn btn-success" style={{marginTop: "7px", width:"100%"}}  disabled >新訂單</button> 
                                :
                                    newPreorderButton()
                            }

                        </div>
                        <div className="col-md-3">
                            
                            <button className="btn btn-success" style={{marginTop: "7px", width:"100%"}}  onClick={e=>handleClickOpen(e)} >gen receipt</button> 
                              
                        </div>
                    </div>
                </div>
            </div>
            <div className="card p-2 m-2" >
                { preorders.length>0? 
                    <div style={{display:"flex", overflow:"auto"}}>
                    <table className="table table-hover" >
                        <thead >
                            <tr>
                                <th></th>
                                <th scope="col">#</th>
                                {clicked ? <th scope="col">電話</th>: null}
                                <th scope="col" onClick={()=>sortPreorders("name", "desc")}>收件人名</th>
                                <th scope="col" >收貨地址</th>
                                <th scope="col" >商品類別</th>
                                <th scope="col" onClick={()=>sortPreorders("product", "desc")}>貨</th>
                                <th scope="col" >數量</th>
                                <th scope="col" onClick={()=>sortPreorders("price", "desc")}>價錢</th>
                                <th scope="col" >訂金</th>
                                <th scope="col" >尾數</th>
                                <th scope="col" >付款狀態</th>
                                <th scope="col" >訂單狀態</th>
                                <th scope="col" >備註</th>
                                <th scope="col" onClick={()=>sortPreorders("createdDate", "desc")}>建立日期</th>
                                <th scope="col" onClick={()=>sortPreorders("modifiedDate", "desc")}>修改日期</th>
                                <th scope="col"></th>
                                <th scope="col"></th>
                                <th scope="col"></th>
                            </tr>
                        </thead>
                            {tableBody}
                    </table>
                    </div>
                    
                
                
                    : clicked? "搜尋先": "冇記錄"


                }
            </div> 
            
            
        </div>
        </>
    )
}

export default NewPreOrderScreen;